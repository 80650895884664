<template>
    <div :class="$style['rewards-redeem']" class="octagonal after">
        <div ref="redeemAnchor" :class="$style['redeem-anchor']"></div>
        <div :class="$style['redeem-content']" class="octagonal before after">
            <p :class="$style['content-title']">
                <span class="fade-lime">REDEEM AN AFFILIATE CODE</span>
            </p>
            <div :class="$style['content-info']">
                <p :class="$style['info-gems']">
                    <span>You earn</span>
                    <img src="@/assets/img/icons/cryptoCoin.svg" width="17" />
                    <span>
                        <b>
                            10
                            <span class="fade-lime">Gems</span>
                        </b>
                        when you redeem an affiliate code
                    </span>
                </p>
                <p :class="$style['info-meta']">
                    You are currently assigned to{{
                        affiliatesData.data?.referrer?._id !== undefined ? ':' : ' --'
                    }}
                </p>
            </div>
            <div :class="$style['content-stuff']">
                <div
                    v-if="affiliatesData.data?.referrer?._id === undefined"
                    :class="$style['stuff-input']"
                    class="octagonal before hoverable"
                >
                    <input
                        v-model="code"
                        v-on:keydown.enter="redeemButton"
                        v-on:input="clicker = false"
                        placeholder="..."
                    />
                </div>
                <button
                    v-else
                    v-on:click="redeemUserButton(affiliatesData.data.referrer)"
                    :class="$style['stuff-referrer']"
                    class="octagonal before"
                >
                    <p>Assigned to:</p>
                    <AvatarImage
                        v-bind:image="
                            affiliatesData.data.referrer !== null
                                ? affiliatesData.data.referrer.avatar
                                : null
                        "
                        v-bind:rank="affiliatesData.data.referrer.rank"
                        v-bind:xp="affiliatesData.data.referrer.xp"
                    />
                    <p :class="$style['referrer-username']">
                        {{ affiliatesData.data.referrer.username }}
                    </p>
                </button>
            </div>
            <button
                v-if="affiliatesData.data?.referrer?._id === undefined"
                :class="$style['content-claim']"
                class="octagonal before after hoverable"
                v-on:click="redeemButton()"
            >
                <p>Redeem Code</p>
                <img src="@/assets/img/icons/cryptoCoin.svg" width="15" />
                <p :class="$style['value-content']">10</p>
                <Clicker v-if="clicker" />
            </button>
            <div :class="$style['content-claimed']" class="octagonal before after" v-else>
                You have already claimed a code
            </div>
            <div
                v-if="affiliatesData.data?.referrer?._id !== undefined"
                :class="$style['content-referrer']"
            >
                <hr :class="$style['referrer-hr']" />
                <p :class="$style['referrer-title']">Your statistics under this code</p>
                <div :class="$style['referrer-list']">
                    <div
                        v-for="(item, index) in getContentList"
                        :class="$style['list-item']"
                        v-bind:key="index"
                    >
                        <div :class="$style['item-content']" class="octagonal before">
                            <h4>
                                <span :class="{ 'fade-green': [3].includes(index) }">{{
                                    item.title
                                }}</span>
                            </h4>
                            <hr />
                            <p>
                                <img
                                    v-if="item.title.startsWith('EARNED')"
                                    width="13"
                                    src="@/assets/img/icons/cryptoCoin.svg"
                                    alt="icon"
                                />
                                <span
                                    v-else-if="item.title.startsWith('DATE') !== true"
                                    :class="$style.coins"
                                >
                                    <img
                                        width="10"
                                        src="@/assets/img/icons/cryptoCoin.svg"
                                        alt="icon"
                                    />
                                    /
                                    <img
                                        width="10"
                                        src="@/assets/img/icons/robuxCoin.svg"
                                        alt="icon"
                                    />
                                </span>
                                {{ item.text }}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else></div>
            <svg :class="$style['content-dots']" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <pattern
                        id="polka-dots"
                        x="0"
                        y="0"
                        width="15"
                        height="15"
                        patternUnits="userSpaceOnUse"
                    >
                        <circle cx="3" cy="3" r="3" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#polka-dots)" />
            </svg>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';
    import Clicker from '@/components/Clicker';

    export default {
        name: 'Redeem',
        data() {
            return {
                code: null,
                clicker: false
            };
        },
        components: {
            AvatarImage,
            Clicker
        },
        metaInfo: {
            title: 'Redeem - Rollbet.gg'
        },
        methods: {
            ...mapActions([
                'affiliatesGetDataSocket',
                'generalSetUserInfoData',
                'notificationShow',
                'modalsSetData',
                'modalsSetShow'
            ]),
            redeemUserButton(user) {
                if (user === null || user.rank === 'system') {
                    return;
                }

                this.generalSetUserInfoData(user);
                this.modalsSetShow('ChatUser');
            },
            redeemButton() {
                if (this.code === null || this.code.trim() === '') {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered affiliate code is invalid.'
                    });
                    return;
                }

                this.clicker = false;

                this.modalsSetData({
                    typeCaptcha: 'affiliatesClaim',
                    data: { code: this.code }
                });
                this.modalsSetShow('Captcha');
            },
            redeemFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            ...mapGetters(['affiliatesData', 'authUser']),
            getContentList() {
                return [
                    {
                        title: 'DATE ASSIGNED UNDER THE CODE',
                        text: new Date(this.affiliatesData?.data?.referredAt).toLocaleString(
                            'en-US',
                            {
                                hour12: true,
                                year: 'numeric',
                                month: 'numeric',
                                day: 'numeric'
                            }
                        )
                    },
                    {
                        title: 'WAGERED',
                        text: this.redeemFormatValue(
                            (this.affiliatesData?.data?.generated || 0) / 0.005
                        )
                    },
                    {
                        title: 'DEPOSIT',
                        text: this.redeemFormatValue(this.authUser.user?.stats?.deposit || 0)
                    },
                    {
                        title: 'EARNED YOUR REFERRAL',
                        text: this.redeemFormatValue(this.affiliatesData?.data?.generated || 0)
                    }
                ];
            }
        },
        watch: {
            affiliatesData: {
                immediate: true,
                handler() {
                    if (this.affiliatesData?.data?.referrer?._id !== undefined)
                        sessionStorage.removeItem('affiliate-code');
                    this.clicker = false;
                }
            }
        },
        mounted() {
            const sessionCode = sessionStorage.getItem('affiliate-code');
            const redeemAnchor = this.$refs.redeemAnchor;

            if (sessionCode) {
                this.code = sessionStorage.getItem('affiliate-code');
                sessionStorage.removeItem('affiliate-code');
                this.clicker = true;

                if (redeemAnchor) {
                    setTimeout(() => {
                        redeemAnchor.scrollIntoView({ behavior: 'smooth' });
                    }, 1000);
                }
            }
        },
        created() {
            if (this.affiliatesData.loading === false) {
                const data = {
                    sort: 'date-descend',
                    page: this.affiliatesData.page
                };
                this.affiliatesGetDataSocket(data);
            }
        }
    };
</script>

<style module>
    .rewards-redeem {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px;
        width: 100%;
        text-align: center;
    }

    .rewards-redeem::after {
        background: #0d243a;
        font-size: 14px;
        clip-path: var(--octagonal-main) !important;
    }

    .redeem-anchor {
        position: absolute;
        top: calc(50% - 45vh);
    }

    .redeem-content::before {
        background: #162a3d;
        font-size: 12px;
        z-index: 1;
    }

    .redeem-content::after {
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        mask-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.9), black);
        font-size: 12px;
        clip-path: var(--octagonal-main) !important;
    }

    .redeem-content {
        align-items: center;
        padding: 24px 10px 0px 10px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
        z-index: 1;
    }

    .content-title {
        font-size: 28px;
        font-weight: 600;
    }

    .content-info {
        line-height: 1.1;
    }

    .info-gems {
        font-size: 22px;
        font-weight: 500;
        color: white;
    }

    .info-gems img {
        margin: -3px 1px -4px 7px;
    }

    .info-meta {
        font-size: 16px;
        color: #5b84ae;
        font-weight: 500;
    }

    .content-stuff {
        margin: 22px 0;
        width: 100%;
        max-width: 788px;
        color: #5186ae;
    }

    .stuff-referrer {
        display: flex;
        align-items: center;
        gap: 10px;
        height: 57px;
        color: #5186ae;
        padding: 0 20px;
        font-size: 5px;
        width: 100%;
        background: linear-gradient(0deg, #09263f, #092339);
        clip-path: var(--octagonal-main);
    }

    .stuff-referrer img {
        width: 31px;
        height: 31px;
        border-width: 2px;
    }

    .stuff-referrer p {
        font-size: 15px;
        font-weight: 500;
    }

    .stuff-referrer .referrer-username {
        color: white;
        font-weight: 600;
    }

    .stuff-referrer::before {
        font-size: 5px;
        background: #18406c;
    }

    .stuff-input input {
        appearance: none;
        background: none;
        height: 57px;
        font-size: 16px;
        color: #5186ae;
        font-weight: 500;
        padding: 0 20px;
        width: 100%;
    }

    .stuff-input {
        font-size: 5px;
        width: 100%;
        background: linear-gradient(0deg, #09263f, #092339);
        clip-path: var(--octagonal-main);
    }

    .stuff-input::before {
        font-size: 5px;
        color: #18406c;
        background-image: linear-gradient(45deg, currentColor 25%, transparent 25%),
            linear-gradient(-45deg, currentColor 25%, transparent 25%),
            linear-gradient(45deg, transparent 75%, currentColor 75%),
            linear-gradient(-45deg, transparent 75%, currentColor 75%);
        background-size: 5px 5px;
        background-position:
            0 0,
            0 2.5px,
            2.5px -2.5px,
            -2.5px 0px;
    }

    .content-referrer {
        width: 100%;
        max-width: 994px;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        overflow: hidden;
    }

    .referrer-hr {
        border-color: #0f3251;
    }

    .referrer-title {
        font-size: 16px;
        font-weight: 500;
        color: #5b84ae;
        margin: 18px 0;
    }

    .referrer-list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        margin-bottom: 28px;
        gap: 4px;
        flex: 1;
    }

    .list-item {
        background: #0d243a;
        font-size: 10px;
        clip-path: var(--octagonal-main);
        padding: 4px;
        flex: 1;
    }

    .item-content::before {
        background: #162a3d;
    }

    .list-item:last-of-type .item-content::before {
        background: #01d79a;
    }

    .item-content {
        align-items: center;
        padding: 14px;
        display: flex;
        flex-direction: column;
        height: 89px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #091d30, #091b2d, #081b2d);
        font-size: 8px;
        clip-path: var(--octagonal-main);
    }

    .item-content h4 {
        color: #5b84ae;
        font-size: 13px;
    }

    .item-content hr {
        border-color: #1d466b;
        width: 104px;
        mask-image: linear-gradient(90deg, transparent, #1d466b, transparent);
    }

    .item-content p {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 16px;
        font-weight: 500;
        color: white;
    }

    .item-content p .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
    }

    .item-content p .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 2px;
    }

    .item-content p .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -2px;
    }

    .content-claimed,
    .content-claim {
        filter: drop-shadow(0 2px 35px #21b89429) drop-shadow(0 2px 0 #136762);
        width: 100%;
        max-width: 283px;
        height: 46px;
        display: flex;
        margin-bottom: 20px;
        align-items: center;
        justify-content: center;
        gap: 7px;
        font-size: 16px;
        font-weight: 500;
        color: white;
        padding: 7px 14px;
        z-index: 1;
    }

    .content-claim::before,
    .content-claim::after {
        font-size: 5px;
        background: #21b894;
    }

    .content-claimed {
        filter: unset;
    }

    .content-claimed::before {
        font-size: 5px;
        background: #1effc2;
    }

    .content-claimed::after {
        font-size: 5px;
        background: linear-gradient(90deg, #17474f, #1a5057);
    }

    .value-content {
        font-weight: 600;
    }

    .value-content span {
        font-size: 0.71em;
        opacity: 0.9;
    }

    .content-dots {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        max-height: 350px;
        color: #142941;
        z-index: -1;
        mask-image: radial-gradient(50% 50% at center, black, transparent);
    }
</style>
