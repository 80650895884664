var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style['rewards-rakeback'], _vm.$style[_vm.rewardsGetRakebackInfo.rank]]},[_c('div',{staticClass:"octagonal before",class:_vm.$style['rakeback-content']},[_c('p',{class:_vm.$style['content-title']},[_c('span',{class:_vm.userBalanceData.type === 'robuxCoin' ? 'fade-gold' : 'fade-lime'},[_vm._v(" WAGER, INCREASE YOUR TIER AND EARN MORE! ")])]),_c('div',{class:_vm.$style['content-info']},[_c('p',{class:_vm.$style['info-rank']},[_c('span',{class:_vm.$style['rank-text']},[_vm._v("Your current rank is ")]),(_vm.rewardsGetRakebackInfo.rank)?_c('img',{attrs:{"width":"26","src":require(`@/assets/img/ranks/${_vm.rewardsGetRakebackInfo.rank}.png`)}}):_vm._e(),(_vm.rewardsGetRakebackInfo.rank)?_c('span',{class:[
                        _vm.$style['rank-name'],
                        {
                            'fade-gold': _vm.rewardsGetRakebackInfo.rank === 'gold',
                            'fade-ice': _vm.rewardsGetRakebackInfo.rank === 'ice',
                            'fade-fire': _vm.rewardsGetRakebackInfo.rank === 'fire'
                        }
                    ]},[_vm._v(" "+_vm._s(_vm.rewardsGetRakebackInfo.rank[0].toUpperCase() + _vm.rewardsGetRakebackInfo.rank.slice(1).toLowerCase())+" ")]):_c('span',[_vm._v(" N/A ")])]),(_vm.rewardsGetRakebackInfo.rank)?_c('p',{class:_vm.$style['info-meta']},[_vm._v(" You currently earn "),_c('span',{class:_vm.$style['meta-percent']},[_vm._v(" "+_vm._s(_vm.rewardsGetRakebackInfo.percent)+"% ")]),_vm._v(" rakeback on all bets ")]):_c('p',{class:_vm.$style['info-meta']},[_vm._v(" Play more games to acquire a rank and start earning rakebacks on all bets! ")])]),_c('div',{class:_vm.$style['content-rank']},[_c('div',{class:_vm.$style['rank-icon']},[(_vm.rewardsGetRakebackInfo.rank)?_c('img',{attrs:{"width":"26","src":require(`@/assets/img/ranks/${_vm.rewardsGetRakebackInfo.rank}.png`)}}):_vm._e()]),_c('div',{class:_vm.$style['rank-progress']},[_c('div',{class:_vm.$style['progress-bar'],style:({
                        width:
                            Math.min(
                                100,
                                Math.max(
                                    0,
                                    (parseFloat(_vm.rewardsGetProgress.current) /
                                        parseFloat(_vm.rewardsGetProgress.next)) *
                                        100
                                )
                            ).toFixed(2) + '%'
                    })})]),_c('div',{class:_vm.$style['rank-icon']},[_c('img',{attrs:{"width":"26","src":require(`@/assets/img/ranks/${_vm.rewardsGetRakebackInfo.nextRank}.png`)}})])]),_c('div',{class:_vm.$style['content-progress']},[_c('p',{class:_vm.$style['progress-title']},[_vm._v("PROGRESS")]),_c('p',{class:_vm.$style['progress-xp']},[_vm._v(" "+_vm._s(_vm.rewardsGetProgress.current)+"XP / "+_vm._s(_vm.rewardsGetProgress.next)+" XP ")])]),_c('button',{staticClass:"octagonal before after hoverable",class:_vm.$style['content-claim'],on:{"click":function($event){return _vm.rewardsClaimButton()}}},[_c('p',[_vm._v("Claim Rakeback")]),_c('img',{attrs:{"width":"15","src":require(`@/assets/img/icons/${_vm.userBalanceData.type}.svg`)}}),_c('p',{class:_vm.$style['value-content']},[_vm._v(" "+_vm._s(_vm.rewardsGetValue.split('.')[0])),_c('span',[_vm._v("."+_vm._s(_vm.rewardsGetValue.split('.')[1])+" ")])])]),_c('svg',{class:_vm.$style['content-dots'],attrs:{"xmlns":"http://www.w3.org/2000/svg"}},[_c('defs',[_c('pattern',{attrs:{"id":"polka-dots","x":"0","y":"0","width":"15","height":"15","patternUnits":"userSpaceOnUse"}},[_c('circle',{attrs:{"cx":"3","cy":"3","r":"3","fill":"currentColor"}})])]),_c('rect',{attrs:{"width":"100%","height":"100%","fill":"url(#polka-dots)"}})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }