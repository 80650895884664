<template>
    <div :class="$style.profile">
        <ProfileHeader />
        <ProfileStats />
        <transition name="slide-fade" mode="out-in">
            <router-view />
        </transition>
    </div>
</template>

<script>
    import ProfileHeader from '@/components/profile/ProfileHeader';
    import ProfileStats from '@/components/profile/ProfileStats';

    export default {
        name: 'Profile',
        components: {
            ProfileHeader,
            ProfileStats
        },
        metaInfo: {
            title: 'Profile - Rollbet.gg'
        }
    };
</script>

<style module>
    .profile {
        width: 100%;
        max-width: 1345px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        justify-content: center;
        padding: 45px 10px 45px 10px;
    }

    @media screen and (max-width: 1500px) {
        .profile {
            padding-top: 30px;
        }
    }
</style>
