<template>
    <div :class="$style['profile-settings']" class="octagonal before after">
        <div :class="$style['settings-content']" class="octagonal before after">
            <div :class="$style['content-controls']">
                <button :class="$style['controls-item']" class="octagonal before after hoverable">
                    All Settings
                </button>
            </div>
            <hr :class="$style['content-hr']" />
            <div :class="$style['content-table']">
                <div :class="$style['table-head']">
                    <p>Settings</p>
                    <p>Action</p>
                </div>
                <div :class="$style['table-list']">
                    <ProfileSettingsElement name="Hidden Bets">
                        <button
                            v-on:click="userToggleAnonymous"
                            v-bind:class="[
                                $style['button-toggle'],
                                {
                                    [$style.active]: authUser.user.anonymous === true
                                }
                            ]"
                            v-bind:disabled="socketSendLoading !== null"
                        ></button>
                    </ProfileSettingsElement>
                    <ProfileSettingsElement name="SFX Volume">
                        <div :class="$style['input-range']">
                            <div
                                :class="$style['range-fill']"
                                :style="{ width: userVolume * 100 + '%' }"
                            ></div>
                            <input
                                v-model="userVolume"
                                v-on:input="soundSetVolume(userVolume)"
                                type="range"
                                min="0"
                                max="1"
                                step="0.01"
                            />
                        </div>
                    </ProfileSettingsElement>
                    <ProfileSettingsElement name="Email">
                        <div
                            v-if="authUser.user.local?.email !== undefined"
                            :class="$style['element-info']"
                        >
                            <span>{{ authUser.user.local.email }}</span>
                        </div>
                        <button
                            v-if="authUser.user.local?.email === undefined"
                            v-on:click="modalsSetShow('Link')"
                            :class="[$style['button-link'], $style['button-email']]"
                            class="octagonal before after hoverable"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                height="1em"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    d="M48 64C21.5 64 0 85.5 0 112c0 15.1 7.1 29.3 19.2 38.4L236.8 313.6c11.4 8.5 27 8.5 38.4 0L492.8 150.4c12.1-9.1 19.2-23.3 19.2-38.4c0-26.5-21.5-48-48-48H48zM0 176V384c0 35.3 28.7 64 64 64H448c35.3 0 64-28.7 64-64V176L294.4 339.2c-22.8 17.1-54 17.1-76.8 0L0 176z"
                                    fill="currentColor"
                                />
                            </svg>
                            Link
                        </button>
                        <button
                            v-else-if="authUser.user.local.emailVerified === undefined"
                            v-on:click="userVerifyButton()"
                            :class="$style['button-link']"
                            class="octagonal before after hoverable"
                            v-bind:disabled="authSendLoginLoading === true"
                        >
                            Verify
                        </button>
                    </ProfileSettingsElement>
                    <ProfileSettingsElement
                        name="Roblox Account"
                        v-bind:info="authUser.user.roblox === undefined ? 'warning' : 'success'"
                    >
                        <div
                            v-if="authUser.user.roblox !== undefined"
                            :class="$style['element-info']"
                        >
                            <svg
                                width="14"
                                height="14"
                                viewBox="0 0 14 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M7.53548 9.67585L5.39341 11.818C5.39341 11.818 5.39341 11.818 5.39336 11.818C5.39336 11.818 5.39336 11.8181 5.39331 11.8181C4.5075 12.7039 3.0661 12.704 2.18019 11.8181C1.75102 11.3889 1.51472 10.8183 1.51472 10.2115C1.51472 9.60466 1.75102 9.03417 2.18004 8.605C2.18009 8.60495 2.18014 8.60489 2.18019 8.60484L4.32225 6.46273C4.61798 6.16695 4.61798 5.68739 4.3222 5.39167C4.02648 5.09595 3.54692 5.09595 3.25114 5.39167L1.10908 7.53379C1.10893 7.53394 1.10878 7.53414 1.10862 7.53429C0.393726 8.24944 0 9.20023 0 10.2115C0 11.2229 0.393878 12.1739 1.10913 12.8891C1.8474 13.6274 2.81708 13.9965 3.7868 13.9965C4.75652 13.9965 5.72625 13.6274 6.46442 12.8891C6.46447 12.8891 6.46447 12.889 6.46447 12.889L8.60654 10.7469C8.90226 10.4512 8.90226 9.97163 8.60649 9.67585C8.31081 9.38013 7.8313 9.38013 7.53548 9.67585Z"
                                    fill="#5B84AE"
                                />
                                <path
                                    d="M13.9999 3.7851C13.9999 2.77362 13.606 1.82267 12.8907 1.10742C11.4142 -0.369024 9.01183 -0.368974 7.53544 1.10742C7.53538 1.10752 7.53528 1.10758 7.53523 1.10768L5.39322 3.24964C5.09744 3.54537 5.09744 4.02498 5.39322 4.3207C5.54116 4.46864 5.73494 4.54256 5.92877 4.54256C6.12256 4.54256 6.31644 4.46859 6.46428 4.3207L8.60629 2.17873C8.60634 2.17863 8.60644 2.17858 8.60654 2.17848C9.49235 1.29267 10.9338 1.29262 11.8197 2.17848C12.2488 2.60765 12.4852 3.17825 12.4852 3.7851C12.4852 4.39189 12.2489 4.96239 11.8198 5.39156L11.8197 5.39171L9.6776 7.53382C9.38188 7.82955 9.38188 8.30911 9.67765 8.60488C9.82554 8.75277 10.0194 8.82674 10.2132 8.82674C10.407 8.82674 10.6008 8.75277 10.7487 8.60488L12.8908 6.46277C12.8909 6.46262 12.8911 6.46241 12.8912 6.46226C13.6061 5.74711 13.9999 4.79632 13.9999 3.7851Z"
                                    fill="#5B84AE"
                                />
                                <path
                                    d="M4.32242 9.67593C4.4703 9.82382 4.66414 9.89779 4.85792 9.89779C5.05175 9.89779 5.24559 9.82382 5.39347 9.67593L9.67771 5.3917C9.97348 5.09597 9.97348 4.61641 9.67771 4.32064C9.38198 4.02492 8.90242 4.02492 8.60665 4.32064L4.32242 8.60482C4.02664 8.90065 4.02664 9.38021 4.32242 9.67593Z"
                                    fill="#5B84AE"
                                />
                            </svg>
                            <a
                                v-bind:href="
                                    'https://www.roblox.com/users/' + authUser.user.roblox.id
                                "
                                target="_blank"
                            >
                                {{
                                    authUser.user.discord?.id === undefined
                                        ? authUser.user.username
                                        : `ID: ${authUser.user.roblox.id}`
                                }}
                            </a>
                        </div>
                        <button
                            v-if="authUser.user.roblox === undefined"
                            v-on:click="modalsSetShow('LinkRoblox')"
                            :class="[$style['button-link'], $style['button-roblox']]"
                            class="octagonal before after hoverable"
                        >
                            <svg
                                width="13"
                                height="13"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4 0L0 16L15.5 20L20 4L4 0ZM7.5 11L8.5 8L12 9L11 12L7.5 11Z"
                                />
                            </svg>
                            Link
                        </button>
                        <button
                            v-else
                            :class="[$style['button-link'], $style['button-roblox']]"
                            class="octagonal before after hoverable"
                            v-on:click="userSendRobloxUnlink"
                        >
                            <svg
                                width="13"
                                height="13"
                                viewBox="0 0 20 20"
                                fill="currentColor"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M4 0L0 16L15.5 20L20 4L4 0ZM7.5 11L8.5 8L12 9L11 12L7.5 11Z"
                                />
                            </svg>
                            {{ robloxButtonText }}
                        </button>
                    </ProfileSettingsElement>
                    <!--
                    <ProfileSettingsElement name="GOOGLE" v-bind:info="authUser.user.google === undefined ? 'warning' : 'success'">
                        <button class="button-link octagonal before after hoverable button-google" v-bind:disabled="authUser.user.google !== undefined">
                                <svg width="19" height="20" viewBox="0 0 19 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M0 10.0793C0 5.01395 4.12098 0.892975 9.18631 0.892975C11.2321 0.892975 13.1684 1.55115 14.7859 2.79638L12.6512 5.56942C11.651 4.79952 10.4529 4.39252 9.18631 4.39252C6.05063 4.39252 3.49955 6.9436 3.49955 10.0793C3.49955 13.215 6.05063 15.766 9.18631 15.766C11.7118 15.766 13.858 14.1114 14.5977 11.8291H9.18631V8.32951H18.3726V10.0793C18.3726 15.1446 14.2516 19.2656 9.18631 19.2656C4.12098 19.2656 0 15.1446 0 10.0793Z" />
                                </svg>
                                {{ authUser.user.googleId !== undefined ? 'GOOGLE LINKED' : 'LINK GOOGLE' }}
                        </button>
                    </ProfileSettingsElement>
                    -->
                    <ProfileSettingsElement
                        name="Discord Account"
                        v-bind:info="authUser.user.discord === undefined ? 'warning' : 'success'"
                    >
                        <div
                            v-if="authUser.user.discord !== undefined"
                            :class="$style['element-info']"
                        >
                            <span>ID: {{ authUser.user.discord.id }}</span>
                        </div>
                        <button
                            :class="[$style['button-link'], $style['button-discord']]"
                            class="octagonal before after hoverable"
                            v-on:click="userSendUserDiscord"
                        >
                            <svg
                                width="17"
                                height="11"
                                viewBox="0 0 17 11"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M3.74542 8.80695C7.44249 10.1677 10.5887 10.4328 14.154 8.31469C14.1279 8.35166 13.4226 9.33709 11.568 9.84211C11.9598 10.3472 12.4953 10.9261 12.4953 10.9261C13.6839 10.9261 14.8463 10.6058 15.8519 9.98992C16.6356 9.49719 17.0796 8.64725 16.9882 7.76038C16.8314 6.24524 16.4527 4.76708 15.865 3.35054C15.1205 1.50285 13.3312 0.221755 11.2546 0.0369715C11.0717 0.0246477 10.9411 0.0246477 10.8628 0.0246477L10.6538 0.221719C13.0439 0.862264 14.2324 1.86002 14.2585 1.89699C10.5885 0.147812 6.25237 0.123201 2.55619 1.82308C2.55619 1.82308 3.73165 0.726775 6.3307 0.147813L6.17397 0C5.89969 0 5.63847 0.0246475 5.36422 0.0615824C3.4443 0.369531 1.83786 1.61365 1.14564 3.32585C0.544837 4.80402 0.153028 6.35608 0.00937143 7.9328C-0.0689903 8.77042 0.348952 9.59575 1.08034 10.0762C2.04682 10.6797 3.19615 11 4.35855 11C4.35855 11 4.82872 10.421 5.29893 9.9037C3.53573 9.41096 2.81741 8.42553 2.80434 8.38856L3.13616 8.54837C3.33453 8.64396 3.5379 8.73056 3.74542 8.80695ZM5.79523 7.73573C4.94628 7.71108 4.28018 7.0336 4.30632 6.2206C4.33245 5.45689 4.98548 4.84099 5.79523 4.81634C6.64418 4.84099 7.31028 5.51847 7.28414 6.33147C7.24494 7.09522 6.60498 7.71112 5.79523 7.73573ZM11.1239 7.73573C10.275 7.71108 9.6089 7.0336 9.63503 6.2206C9.66117 5.45689 10.3142 4.84099 11.1239 4.81634C11.9729 4.84099 12.639 5.51847 12.6129 6.33147C12.5868 7.09522 11.9337 7.71112 11.1239 7.73573Z"
                                    fill="white"
                                />
                            </svg>
                            {{ discordButtonText }}
                        </button>
                    </ProfileSettingsElement>
                </div>
            </div>
            <hr :class="$style['content-hr']" />
            <div :class="$style['content-pagination']">
                <button :class="$style['button-prev']" class="octagonal before after hoverable">
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.0374 4.52545C9.97962 4.51623 9.92117 4.51198 9.86269 4.51272L3.16249 4.51272L3.37961 4.41338C3.59184 4.31456 3.78491 4.18007 3.95018 4.016L5.65676 2.3372C6.02451 1.99185 6.0863 1.43631 5.80319 1.02089C5.47369 0.578226 4.84179 0.482111 4.39177 0.806251C4.35542 0.832453 4.32086 0.861014 4.28839 0.891748L0.324749 4.79088C-0.0698583 5.17863 -0.0702055 5.80763 0.32396 6.19582L0.324749 6.1966L4.28839 10.0957C4.68331 10.4831 5.32271 10.4823 5.71656 10.0938C5.74755 10.0632 5.77649 10.0307 5.80319 9.99639C6.0863 9.58097 6.02451 9.02543 5.65676 8.68008L3.95523 6.99631C3.80707 6.8504 3.63671 6.72805 3.4503 6.63371L3.14734 6.49959L9.80712 6.49959C10.323 6.51844 10.7756 6.16394 10.8725 5.66511C10.9618 5.12353 10.5879 4.61331 10.0374 4.52545Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
                <div :class="$style['pagination-info']">
                    Page
                    <div :class="$style['info-input']" class="octagonal before after hoverable">
                        <input
                            v-model="pagePrompt"
                            v-bind:style="{ width: pagePrompt?.toString().length * 0.75 + 'em' }"
                        />
                    </div>
                    / 1
                </div>
                <button :class="$style['button-next']" class="octagonal before after hoverable">
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.876938 6.47455C0.93469 6.48377 0.993137 6.48803 1.05161 6.48728L7.75182 6.48728L7.5347 6.58662C7.32247 6.68544 7.12939 6.81993 6.96412 6.984L5.25755 8.6628C4.8898 9.00815 4.828 9.56369 5.11111 9.97911C5.44062 10.4218 6.07251 10.5179 6.52254 10.1937C6.55889 10.1675 6.59345 10.139 6.62592 10.1083L10.5896 6.20912C10.9842 5.82137 10.9845 5.19237 10.5903 4.80418L10.5896 4.8034L6.62592 0.904271C6.231 0.516862 5.59159 0.517731 5.19774 0.906227C5.16675 0.936806 5.13781 0.969341 5.11111 1.00361C4.828 1.41903 4.88979 1.97457 5.25755 2.31992L6.95907 4.00369C7.10724 4.1496 7.27759 4.27195 7.46401 4.36629L7.76697 4.50041L1.10719 4.50041C0.591302 4.48156 0.13869 4.83607 0.0417743 5.33489C-0.0475039 5.87647 0.3264 6.38669 0.876938 6.47455Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ProfileSettingsElement from '@/components/profile/ProfileSettingsElement';

    export default {
        name: 'ProfileSettings',
        components: {
            ProfileSettingsElement
        },
        data() {
            return {
                pagePrompt: 1,
                userVolume: 1,
                userShowEmail: false,
                userShowRoblox: false
            };
        },
        methods: {
            ...mapActions([
                'notificationShow',
                'soundSetVolume',
                'modalsSetShow',
                'userSendUserAnonymousSocket',
                'userSendUserDiscordSocket',
                'userSendUserRobloxSocket',
                'authSendCredentialsRequest'
            ]),
            userToggleAnonymous() {
                const data = { anonymous: !this.authUser.user.anonymous };
                this.userSendUserAnonymousSocket(data);
            },
            userToggleShowEmail() {
                this.userShowEmail = !this.userShowEmail;
            },
            userToggleShowRoblox() {
                this.userShowRoblox = !this.userShowRoblox;
            },
            userVerifyButton() {
                const data = { type: 'verify', email: this.authUser.user.local.email };
                this.authSendCredentialsRequest(data);
            },
            userSendUserDiscord() {
                if (
                    this.authUser.user.discord === undefined ||
                    this.authUser.user.discord.id === undefined
                ) {
                    fetch(
                        `${process.env.VUE_APP_BACKEND_URL}/auth/discord?userId=${this.authUser.user._id}`,
                        {
                            method: 'GET'
                        }
                    )
                        .then((response) => response.json())
                        .then((data) => {
                            if (data.url) {
                                window.location.href = data.url; // Redirect the user to the Discord auth page
                            } else {
                                console.error('No URL found in response:', data);
                            }
                        })
                        .catch((error) => {
                            console.error('Error during fetch:', error);
                        });
                } else {
                    const data = { method: 'unlink' };
                    this.userSendUserDiscordSocket(data);
                }
            },
            userSendRobloxUnlink() {
                const data = { method: 'unlink' };
                this.userSendUserRobloxSocket(data);
            }
        },
        computed: {
            ...mapGetters(['soundVolume', 'authSendLoginLoading', 'socketSendLoading', 'authUser']),

            discordButtonText() {
                return this.authUser.user.discord?.id ? 'Unlink' : 'Link';
            },
            robloxButtonText() {
                return this.authUser.user.roblox?.id ? 'Unlink' : 'Link';
            }
        },
        created() {
            this.userVolume = this.soundVolume;
        }
    };
</script>

<style module>
    .profile-settings {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px;
        width: 100%;
        margin-bottom: 30px;
    }

    .profile-settings::after {
        clip-path: var(--octagonal-main) !important;
        background: #0d243a;
        font-size: 14px;
    }

    .profile-settings .settings-content::before {
        background: #162a3d;
        font-size: 12px;
    }

    .profile-settings .settings-content::after {
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main) !important;
    }

    .profile-settings .settings-content {
        align-items: center;
        padding: 14px 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        justify-content: space-between;
        z-index: 1;
    }

    .profile-settings .content-controls {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        width: 100%;
    }
    .profile-settings .controls-item {
        margin-left: auto;
        color: white;
        font-size: 15px;
        z-index: 1;
        font-weight: 600;
        padding: 8.5px 16.5px;
    }

    .profile-settings .controls-item::after {
        background: linear-gradient(120deg, #143e47, #1a5157);
        font-size: 3px;
    }

    .profile-settings .controls-item::before {
        font-size: 3px;
        background: linear-gradient(0deg, #1effc2, transparent);
    }

    .profile-settings hr {
        border-color: #2b70a42e;
        width: 100%;
    }

    .profile-settings .content-table {
        width: 100%;
    }

    .profile-settings .table-head {
        width: 100%;
        height: 42px;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0 25px;
        background: linear-gradient(45deg, #0b1f33, #0a1c2e);
        border-radius: 8px;
        border: 1px solid #103351;
        font-weight: 600;
        font-size: 15px;
        color: #5b85ae;
        margin-bottom: 14px;
    }

    .profile-settings .table-list {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .button-toggle {
        width: 33px;
        height: 11px;
        position: relative;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .button-toggle:before {
        content: '';
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background: #133a5c;
        font-size: 1px;
        clip-path: var(--octagonal-main);
    }

    .button-toggle:after {
        content: '';
        width: 21px;
        height: 21px;
        position: absolute;
        top: -5.5px;
        left: 0;
        background: #0a2f4b;
        font-size: 2px;
        clip-path: var(--octagonal-main);
        transition: transform 0.3s ease;
        background: #dcdcdc;
        border: 4px solid white;
        box-sizing: border-box;
    }

    .button-toggle.active::before {
        background: linear-gradient(0deg, #00ffc2, #00aa6d);
    }

    .button-toggle.active:after {
        transform: translate(12px, 0);
        background: #dcdcdc;
        border: 4px solid white;
        box-sizing: border-box;
    }

    .input-range {
        position: relative;
        height: 11px;
        flex: 1;
        max-width: 200px;
        width: 100%;
    }

    .input-range .range-fill {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        background: #41c48b;
        font-size: 1px;
        clip-path: var(--octagonal-main);
    }

    .input-range::before {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        height: 100%;
        content: '';
        font-size: 1px;
        clip-path: var(--octagonal-main);
        background: #091621;
    }

    .input-range input {
        width: calc(100% + 3px);
        height: 100%;
        left: -2px;
        top: 0;
        position: absolute;
        font-size: 0px;
        -webkit-appearance: none;
        -moz-apperance: none;
        background-color: transparent;
        filter: drop-shadow(0px 4px 25px rgba(15, 41, 63, 0.35));
    }

    .input-range input::before {
        background: rgba(0, 255, 194, 0.12);
    }

    .input-range input::-webkit-slider-thumb {
        width: 21px;
        height: 21px;
        -webkit-appearance: none;
        appearance: none;
        cursor: pointer;
        background: #0d3355;
        font-size: 2px;
        clip-path: var(--octagonal-after);
    }

    .input-range input::-moz-range-thumb {
        width: 21px;
        height: 21px;
        background: #0d3355;
        cursor: pointer;
        font-size: 2px;
        clip-path: var(--octagonal-after);
    }

    .input-range input::-moz-range-thumb {
        background: #dcdcdc;
        border: 5.5px solid white;
        box-sizing: border-box;
    }

    .button-link.button-roblox svg {
        mask-image: linear-gradient(0deg, rgba(0, 0, 0, 0.3), black);
    }

    .button-link {
        z-index: 1;
        color: white;
        font-size: 14px;
        font-weight: 500;
        min-width: 72px;
        padding: 4px 11px 4px 11px;
        filter: drop-shadow(0 2px 0 #153358);
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
    }

    .button-link::before,
    .button-link::after {
        font-size: 3px;
        background: #274e8a;
    }

    .button-link.button-discord::before,
    .button-link.button-discord::after {
        font-size: 3px;
        background: #5865f2;
    }

    .profile-settings .element-info {
        position: relative;
        display: flex;
        align-items: center;
        font-size: 14px;
        color: #5884ae;
        justify-content: flex-end;
        font-weight: 500;
        gap: 7px;
        flex: 1;
        max-width: fit-content;
    }

    .profile-settings .element-info a,
    .profile-settings .element-info span {
        color: #5884ae;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .profile-settings .content-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profile-settings .content-pagination button {
        width: 38px;
        height: 38px;
        font-size: 3px;
        color: #406992;
        z-index: 1;
    }

    .profile-settings .content-pagination button::before {
        background: #142f4a;
    }

    .profile-settings .content-pagination button::after {
        background: linear-gradient(0deg, #102b43, #102940);
    }

    .profile-settings .pagination-info {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #5e768e;
    }

    .profile-settings .pagination-info .info-input {
        margin-right: -4px;
        font-size: 16px;
        z-index: 1;
    }

    .profile-settings .pagination-info .info-input::before,
    .profile-settings .pagination-info .info-input::after {
        background: #00111f;
        font-size: 2px;
    }

    .profile-settings .pagination-info .info-input input {
        appearance: none;
        background: none;
        padding: 2px 0px 2.5px 0px;
        width: 1.5em;
        min-width: 2em;
        text-align: center;
        max-width: 7.5em;
        color: white;
    }

    @media screen and (max-width: 600px) {
        .profile-settings .settings-content {
            padding: 14px;
        }

        .profile-settings .element-info {
            max-width: unset;
        }
        .profile-settings .element-info span {
            position: absolute;
            left: 0;
            width: 100%;
        }
    }
</style>
