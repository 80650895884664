<template>
    <div :class="$style['profile-transactions-element']">
        <div :class="$style['element-date']">
            <div :class="$style['date-title']">Date</div>
            <div :class="$style['date-content']">
                {{
                    new Date(transaction.createdAt).toLocaleString('en-US', {
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                }}
            </div>
        </div>

        <div :class="$style['element-type']">
            <div :class="$style['type-title']">Type</div>
            <div :class="$style['type-content']">
                <svg
                    v-if="profileGetType === 'Deposit'"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12 0H3C1.35 0 0 1.4 0 3.11111C0 4.43333 0.75 5.52222 1.875 5.98889V3.11111C1.875 2.8 2.025 2.48889 2.175 2.25556C2.325 2.02222 2.7 1.94444 3 1.94444H12C12.3 1.94444 12.6 2.1 12.825 2.25556C13.05 2.41111 13.125 2.8 13.125 3.11111V5.98889C14.25 5.52222 15 4.43333 15 3.11111C15 1.4 13.65 0 12 0ZM12 12.4444V3.11111H3V12.4444C3 13.3 3.675 14 4.5 14H10.5C11.325 14 12 13.3 12 12.4444ZM6.9 7.31111L6.375 7.85556C6.15 8.08889 5.775 8.08889 5.55 7.85556C5.325 7.62222 5.325 7.23333 5.55 7L6.525 5.98889C7.05 5.44444 7.875 5.44444 8.4 5.98889L9.375 7C9.6 7.23333 9.6 7.62222 9.375 7.85556C9.15 8.08889 8.775 8.08889 8.55 7.85556L8.025 7.31111V10.5778C8.025 10.8889 7.8 11.2 7.425 11.2C7.05 11.2 6.825 10.9667 6.825 10.5778V7.31111H6.9Z"
                        fill="url(#paint0_linear_42_2275)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_42_2275"
                            x1="15"
                            y1="0"
                            x2="-2.47149"
                            y2="4.58765"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg
                    v-else-if="profileGetType === 'Withdraw'"
                    width="15"
                    height="14"
                    viewBox="0 0 15 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M3 14L12 14C13.65 14 15 12.6 15 10.8889C15 9.56667 14.25 8.47778 13.125 8.01111L13.125 10.8889C13.125 11.2 12.975 11.5111 12.825 11.7444C12.675 11.9778 12.3 12.0556 12 12.0556L3 12.0556C2.7 12.0556 2.4 11.9 2.175 11.7444C1.95 11.5889 1.875 11.2 1.875 10.8889L1.875 8.01111C0.75 8.47778 -3.87574e-07 9.56667 -2.71982e-07 10.8889C-1.22392e-07 12.6 1.35 14 3 14ZM3 1.55556L3 10.8889L12 10.8889L12 1.55556C12 0.7 11.325 3.21279e-07 10.5 3.93402e-07L4.5 9.17939e-07C3.675 9.90063e-07 3 0.700001 3 1.55556ZM8.1 6.68889L8.625 6.14444C8.85 5.91111 9.225 5.91111 9.45 6.14444C9.675 6.37778 9.675 6.76667 9.45 7L8.475 8.01111C7.95 8.55556 7.125 8.55556 6.6 8.01111L5.625 7C5.4 6.76667 5.4 6.37778 5.625 6.14444C5.85 5.91111 6.225 5.91111 6.45 6.14444L6.975 6.68889L6.975 3.42222C6.975 3.11111 7.2 2.8 7.575 2.8C7.95 2.8 8.175 3.03333 8.175 3.42222L8.175 6.68889L8.1 6.68889Z"
                        fill="#5B84AE"
                    />
                </svg>

                <span
                    :class="{
                        'fade-green': profileGetType === 'Deposit',
                        'fade-gold': ['Currency', 'Leaderboard'].includes(profileGetType)
                    }"
                    >{{ profileGetType }}</span
                >
            </div>
        </div>

        <div :class="$style['element-method']">
            <div :class="$style['method-title']">Method</div>
            <div
                :class="[
                    $style['method-content'],
                    $style[profileGetMethod.split(' ')?.[0].toLowerCase()]
                ]"
            >
                <img
                    v-if="
                        [
                            'credit',
                            'kinguin',
                            'limiteds',
                            'ltc',
                            'sol',
                            'eth',
                            'usdc',
                            'usdt'
                        ].includes(profileGetMethod.split(' ')?.[0].toLowerCase())
                    "
                    v-bind:src="
                        require(
                            '@/assets/img/cashier/' +
                                profileGetMethod.split(' ')?.[0].toLowerCase() +
                                '.png'
                        )
                    "
                    height="16"
                />
                <svg
                    v-if="profileGetMethod.startsWith('Admin')"
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.9104 9.5011C10.6485 10.6767 10.1213 11.7697 9.3767 12.7001C9.16087 12.9698 8.72451 12.8402 8.68166 12.4975L8.49995 11.0443L8.86327 10.3177C8.92606 10.1921 8.83474 10.0443 8.69432 10.0443H7.30558C7.16517 10.0443 7.07384 10.1921 7.13664 10.3177L7.49995 11.0443L7.31839 12.4974C7.27556 12.8401 6.83917 12.9697 6.62334 12.7C5.87876 11.7697 5.35155 10.6767 5.08969 9.50109C5.04562 9.30327 4.85467 9.17507 4.65362 9.20066C1.90707 9.55033 0 10.6542 0 14.0443C0 14.5966 0.447712 15.0443 0.999993 15.0443H14.9999C15.5522 15.0443 15.9999 14.5966 15.9999 14.0443C15.9999 10.6542 14.0928 9.55034 11.3465 9.20067C11.1454 9.17507 10.9545 9.30327 10.9104 9.5011Z"
                        fill="url(#paint0_linear_42_2221)"
                    />
                    <path
                        d="M7.99982 0C6.00174 0 4.38208 1.61966 4.38208 3.61774C4.38208 6.8216 6.00174 9.04434 7.99982 9.04434C9.99789 9.04434 11.6176 6.8216 11.6176 3.61774C11.6176 1.61966 9.99789 0 7.99982 0Z"
                        fill="url(#paint1_linear_42_2221)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_42_2221"
                            x1="15.9999"
                            y1="9.19742"
                            x2="2.25899"
                            y2="18.4127"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                        <linearGradient
                            id="paint1_linear_42_2221"
                            x1="11.6176"
                            y1="0"
                            x2="2.94232"
                            y2="1.70086"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                    </defs>
                </svg>
                <img
                    v-if="profileGetMethod === 'Robux'"
                    width="17"
                    src="@/assets/img/icons/robuxCoin.svg"
                />
                <span :class="{ 'fade-gold': profileGetMethod === 'Robux' }">
                    {{ profileGetFormattedMethod }}
                </span>
            </div>
        </div>

        <div :class="$style['element-transaction']">
            <div :class="$style['transaction-title']">Transaction</div>
            <div v-if="profileGetTransaction !== ''" :class="$style['transaction-content']">
                <svg
                    v-if="profileGetTransaction.startsWith('https')"
                    width="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M7.53548 9.67585L5.39341 11.818C5.39341 11.818 5.39341 11.818 5.39336 11.818C5.39336 11.818 5.39336 11.8181 5.39331 11.8181C4.5075 12.7039 3.0661 12.704 2.18019 11.8181C1.75102 11.3889 1.51472 10.8183 1.51472 10.2115C1.51472 9.60466 1.75102 9.03417 2.18004 8.605C2.18009 8.60495 2.18014 8.60489 2.18019 8.60484L4.32225 6.46273C4.61798 6.16695 4.61798 5.68739 4.3222 5.39167C4.02648 5.09595 3.54692 5.09595 3.25114 5.39167L1.10908 7.53379C1.10893 7.53394 1.10878 7.53414 1.10862 7.53429C0.393726 8.24944 0 9.20023 0 10.2115C0 11.2229 0.393878 12.1739 1.10913 12.8891C1.8474 13.6274 2.81708 13.9965 3.7868 13.9965C4.75652 13.9965 5.72625 13.6274 6.46442 12.8891C6.46447 12.8891 6.46447 12.889 6.46447 12.889L8.60654 10.7469C8.90226 10.4512 8.90226 9.97163 8.60649 9.67585C8.31081 9.38013 7.8313 9.38013 7.53548 9.67585Z"
                        fill="#5B84AE"
                    />
                    <path
                        d="M13.9999 3.7851C13.9999 2.77362 13.606 1.82267 12.8907 1.10742C11.4142 -0.369024 9.01183 -0.368974 7.53544 1.10742C7.53538 1.10752 7.53528 1.10758 7.53523 1.10768L5.39322 3.24964C5.09744 3.54537 5.09744 4.02498 5.39322 4.3207C5.54116 4.46864 5.73494 4.54256 5.92877 4.54256C6.12256 4.54256 6.31644 4.46859 6.46428 4.3207L8.60629 2.17873C8.60634 2.17863 8.60644 2.17858 8.60654 2.17848C9.49235 1.29267 10.9338 1.29262 11.8197 2.17848C12.2488 2.60765 12.4852 3.17825 12.4852 3.7851C12.4852 4.39189 12.2489 4.96239 11.8198 5.39156L11.8197 5.39171L9.6776 7.53382C9.38188 7.82955 9.38188 8.30911 9.67765 8.60488C9.82554 8.75277 10.0194 8.82674 10.2132 8.82674C10.407 8.82674 10.6008 8.75277 10.7487 8.60488L12.8908 6.46277C12.8909 6.46262 12.8911 6.46241 12.8912 6.46226C13.6061 5.74711 13.9999 4.79632 13.9999 3.7851Z"
                        fill="#5B84AE"
                    />
                    <path
                        d="M4.32242 9.67593C4.4703 9.82382 4.66414 9.89779 4.85792 9.89779C5.05175 9.89779 5.24559 9.82382 5.39347 9.67593L9.67771 5.3917C9.97348 5.09597 9.97348 4.61641 9.67771 4.32064C9.38198 4.02492 8.90242 4.02492 8.60665 4.32064L4.32242 8.60482C4.02664 8.90065 4.02664 9.38021 4.32242 9.67593Z"
                        fill="#5B84AE"
                    />
                </svg>
                <a
                    v-if="profileGetTransaction.startsWith('https')"
                    v-bind:href="profileGetTransaction"
                    target="_blank"
                >
                    {{ profileGetTransaction }}
                </a>
                <button v-else v-on:click="profileCopyButton">{{ profileGetTransaction }}</button>
            </div>
            <div v-else :class="$style['transaction-content']">-</div>
        </div>

        <div :class="$style['element-amount']">
            <div :class="$style['amount-title']">Amount</div>
            <div
                :class="[
                    $style['amount-content'],
                    { [$style.failed]: profileGetStatus == 'Failed' }
                ]"
            >
                <img
                    v-if="profileGetCoinType === 'robuxCoin'"
                    src="@/assets/img/icons/robuxCoin.svg"
                    width="17"
                    alt="icon"
                />
                <img
                    v-else-if="profileGetCoinType === 'cryptoCoin'"
                    src="@/assets/img/icons/cryptoCoin.svg"
                    width="14"
                    alt="icon"
                />
                <span
                    v-bind:class="[
                        $style['content-value'],
                        {
                            [$style.positive]: profileGetAmount > 0
                        },
                        profileGetStatus == 'Failed' || profileGetAmount < 1
                            ? ''
                            : profileGetCoinType === 'robuxCoin'
                              ? 'fade-gold'
                              : 'fade-green'
                    ]"
                >
                    {{ profileFormatValue(profileGetAmount).split('.')[0]
                    }}<span
                        v-bind:class="[
                            {
                                [$style.positive]: profileGetAmount > 0
                            },
                            profileGetStatus == 'Failed' || profileGetAmount < 1
                                ? ''
                                : profileGetCoinType === 'robuxCoin'
                                  ? 'fade-gold'
                                  : 'fade-green'
                        ]"
                        >.{{ profileFormatValue(profileGetAmount).split('.')[1] }}</span
                    >
                </span>
            </div>
        </div>

        <div :class="$style['element-status']">
            <div :class="$style['status-title']">Status</div>
            <div :class="$style['status-content']">
                <svg
                    v-if="['Complete', 'Approved'].includes(profileGetStatus)"
                    width="14"
                    height="12"
                    viewBox="0 0 14 12"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M13.8073 2.70814L5.61328 10.9066C5.48267 11.0328 5.31722 11.0981 5.15177 11.0981C4.98197 11.0981 4.81652 11.0328 4.69025 10.9066L0.192661 6.40896C-0.0642202 6.15644 -0.0642202 5.74281 0.192661 5.48593L1.78184 3.8924C2.02566 3.64858 2.46105 3.64858 2.70487 3.8924L5.15177 6.3393L11.2951 0.191572C11.417 0.0696627 11.5825 0 11.7567 0C11.9308 0 12.0963 0.0696627 12.2182 0.191572L13.8073 1.7851C14.0642 2.04199 14.0642 2.45561 13.8073 2.70814Z"
                        fill="black"
                    />
                    <path
                        d="M13.8073 2.70814L5.61328 10.9066C5.48267 11.0328 5.31722 11.0981 5.15177 11.0981C4.98197 11.0981 4.81652 11.0328 4.69025 10.9066L0.192661 6.40896C-0.0642202 6.15644 -0.0642202 5.74281 0.192661 5.48593L1.78184 3.8924C2.02566 3.64858 2.46105 3.64858 2.70487 3.8924L5.15177 6.3393L11.2951 0.191572C11.417 0.0696627 11.5825 0 11.7567 0C11.9308 0 12.0963 0.0696627 12.2182 0.191572L13.8073 1.7851C14.0642 2.04199 14.0642 2.45561 13.8073 2.70814Z"
                        fill="url(#paint0_linear_42_2261)"
                    />
                    <defs>
                        <linearGradient
                            id="paint0_linear_42_2261"
                            x1="14"
                            y1="0"
                            x2="-1.91038"
                            y2="4.91877"
                            gradientUnits="userSpaceOnUse"
                        >
                            <stop stop-color="#00FFC2" />
                            <stop offset="1" stop-color="#00AA6D" />
                        </linearGradient>
                    </defs>
                </svg>
                <svg
                    v-else-if="profileGetStatus === 'Processing'"
                    width="11"
                    height="14"
                    viewBox="0 0 11 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.91668 2.81613V1.58774C10.2637 1.38543 10.5 1.01333 10.5 0.583324V0.291676C10.5 0.130457 10.3695 0 10.2083 0H0.291676C0.130457 0 0 0.130457 0 0.291676V0.583352C0 1.01333 0.236305 1.38543 0.583324 1.58777V2.81613C0.583324 3.97198 1.07609 5.07825 1.93514 5.85129L3.21147 7L1.93514 8.14871C1.07609 8.92175 0.583324 10.028 0.583324 11.1839V12.4122C0.236305 12.6146 0 12.9867 0 13.4167V13.7084C0 13.8695 0.130457 14 0.291676 14H10.2084C10.3696 14 10.5 13.8695 10.5 13.7083V13.4166C10.5 12.9867 10.2637 12.6146 9.9167 12.4122V11.1839C9.9167 10.028 9.42394 8.92175 8.56488 8.14871L7.28853 7L8.56486 5.85129C9.42391 5.07825 9.91668 3.97195 9.91668 2.81613ZM7.78444 4.98425L6.02645 6.56649C5.90341 6.67702 5.83332 6.83452 5.83332 7C5.83332 7.16548 5.90341 7.32301 6.02645 7.43351L7.78444 9.01575C8.39795 9.56804 8.75 10.3584 8.75 11.1839V12.25H8.02069L5.48327 8.86679C5.37332 8.71981 5.12665 8.71981 5.0167 8.86679L2.47931 12.25H1.75V11.1839C1.75 10.3584 2.10205 9.56802 2.71559 9.01573L4.47357 7.43348C4.59662 7.32296 4.6667 7.16546 4.6667 6.99997C4.6667 6.83449 4.59662 6.67696 4.47357 6.56646L2.71559 4.98422C2.10205 4.43196 1.75 3.64156 1.75 2.81613V1.75H8.75V2.81613C8.75 3.64156 8.39795 4.43196 7.78444 4.98425Z"
                        fill="#EFC633"
                    />
                    <path
                        d="M7.26034 4.08332H3.23939C3.12402 4.08332 3.01949 4.15141 2.97278 4.25679C2.92608 4.36248 2.94571 4.48552 3.02348 4.57097L5.05433 6.44459C5.10986 6.49502 5.17994 6.52006 5.25 6.52006C5.32005 6.52006 5.39014 6.49499 5.44567 6.44459L7.47622 4.57097C7.55398 4.48552 7.57364 4.36248 7.52691 4.25679C7.48024 4.15141 7.3757 4.08332 7.26034 4.08332Z"
                        fill="#EFC633"
                    />
                </svg>
                <svg
                    v-else-if="profileGetStatus === 'Failed'"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M9.66198 1.96966L6.63204 4.99993L9.66198 8.03006C10.1127 8.48093 10.1127 9.21127 9.66198 9.66213C9.4368 9.88732 9.14156 10 8.84647 10C8.55089 10 8.25563 9.88749 8.03062 9.66213L5.00001 6.63165L1.96964 9.6621C1.74448 9.88729 1.44922 9.99997 1.15387 9.99997C0.85861 9.99997 0.563548 9.88746 0.338189 9.6621C-0.1125 9.21144 -0.1125 8.48107 0.338189 8.03004L3.36804 4.9999L0.338017 1.96966C-0.112672 1.51896 -0.112672 0.788452 0.338017 0.33776C0.788619 -0.112587 1.5187 -0.112587 1.96947 0.33776L4.99999 3.36804L8.03027 0.33776C8.48113 -0.112587 9.2113 -0.112587 9.66181 0.33776C10.1127 0.788452 10.1127 1.51896 9.66198 1.96966Z"
                        fill="#5B84AE"
                    />
                </svg>

                <span
                    :class="{
                        gold: profileGetStatus === 'Processing',
                        green: ['Complete', 'Approved'].includes(profileGetStatus)
                    }"
                    >{{ profileGetStatus }}</span
                >
            </div>
        </div>
    </div>
</template>

<script>
    import { getLinkFromCryptoTransaction } from '@/utils/cashier';
    import { getStatusUID } from '@/utils/general';
    import { mapActions, mapGetters } from 'vuex';

    export default {
        name: 'ProfileTransactionsElement',
        props: ['transaction'],
        methods: {
            ...mapActions(['notificationShow']),
            profileFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            profileCopyButton() {
                const el = document.createElement('textarea');
                el.value = this.profileGetTransaction;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                this.notificationShow({
                    type: 'success',
                    message: 'Successfully copied to your clipboard.'
                });
            }
        },
        computed: {
            ...mapGetters(['authUser']),
            profileGetMethod() {
                let method =
                    this.transaction.method.charAt(0).toUpperCase() +
                    this.transaction.method.slice(1);

                if (this.transaction.method === 'tip') {
                    method =
                        this.authUser.user._id === this.transaction.sender.user
                            ? 'Tip Sent'
                            : 'Tip Received';
                } else if (this.transaction.type === 'affiliateCodeClaim') {
                    method = 'Affiliate Code';
                } else if (this.transaction.type === 'affiliateEarningClaim') {
                    method = 'Affiliate Earnings';
                } else if (this.transaction.type === 'promoCodeClaim') {
                    method = 'Promo Code';
                } else if (this.transaction.type === 'rakebackClaim') {
                    method = 'Rakeback Earnings';
                } else if (this.transaction.type === 'rainCreate') {
                    method = 'Rain Host';
                } else if (this.transaction.type === 'rainTip') {
                    method = 'Rain Tip';
                } else if (this.transaction.type === 'rainPayout') {
                    method = 'Rain Payout';
                } else if (this.transaction.type === 'leaderboardPayout') {
                    method = 'Leaderboard';
                } else if (this.transaction.type === 'adminAdjust') {
                    method = 'Admin Adjustment';
                } else if (this.transaction.method === 'crypto') {
                    let currency = (
                        this.transaction.data.cryptoCurrency || this.transaction.data.currency
                    )?.toLowerCase();
                    if (['robux', 'cryptocoin'].includes(currency)) currency = 'Crypto';
                    method = currency;
                } else if (this.transaction.method === 'limited') {
                    method = 'Limiteds';
                } else if (this.transaction.method === 'credit') {
                    method = 'Credit Card';
                } else if (this.transaction.method === 'gift') {
                    method = 'Kinguin';
                }

                return method;
            },
            profileGetFormattedMethod() {
                const cryptos = {
                    ltc: 'Litecoin',
                    eth: 'Ethereum',
                    btc: 'Bitcoin',
                    usdt: 'USDT',
                    usdc: 'USDC',
                    sol: 'Solana'
                };

                return cryptos[this.profileGetMethod] || this.profileGetMethod;
            },
            profileGetCoinType() {
                if (this.transaction.method === 'limited' && this.profileGetType === 'Deposit')
                    return 'robuxCoin';
                else if (this.transaction.method === 'limited')
                    return this.transaction?.[this.profileGetType.toLowerCase()]?.coinType;
                else if (this.transaction.method === 'robux')
                    return this.transaction?.[this.profileGetType.toLowerCase()]?.offer?.coinType;

                return this.transaction.coinType;
            },
            profileGetType() {
                let type = null;

                if (this.transaction.method === 'robux' || this.transaction.method === 'limited') {
                    type =
                        this.authUser.user._id === this.transaction.deposit.user
                            ? 'Deposit'
                            : 'Withdraw';
                } else if (
                    this.transaction.method === 'balance' ||
                    this.transaction.method === 'tip'
                ) {
                    type = 'Currency';
                } else {
                    type =
                        this.transaction.type.charAt(0).toUpperCase() +
                        this.transaction.type.slice(1);
                }

                return type;
            },
            profileGetTransaction() {
                return (
                    getLinkFromCryptoTransaction(this.transaction) ||
                    this.transaction.data?.providerId ||
                    ''
                );
            },
            profileGetAmount() {
                let amount = this.transaction.amount;

                if (
                    this.transaction.method === 'tip' &&
                    this.authUser.user._id === this.transaction.sender.user
                ) {
                    amount = -this.transaction.amount;
                }

                return amount || 0;
            },
            profileGetStatus() {
                const value = getStatusUID(this.transaction.state);
                if ([1, 2].includes(value)) return 'Processing';
                else if (value === 0) return 'Failed';
                else if (value === 3) return 'Complete';
                else if (value === 4) return 'Approved';
                return this.transaction.state || '';
            }
        }
    };
</script>

<style module>
    .profile-transactions-element {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        border-radius: 5px;
        background: linear-gradient(270deg, #0c2033, #08243b);
    }

    .profile-transactions-element:nth-child(even) {
        background: unset;
    }

    .profile-transactions-element .element-date,
    .profile-transactions-element .element-type,
    .profile-transactions-element .element-method,
    .profile-transactions-element .element-transaction,
    .profile-transactions-element .element-amount,
    .profile-transactions-element .element-status {
        display: flex;
        flex-direction: column;
    }

    .profile-transactions-element .element-date {
        width: 16%;
    }

    .profile-transactions-element .element-type {
        width: 15%;
    }

    .profile-transactions-element .element-method {
        width: 18%;
    }

    .profile-transactions-element .element-transaction {
        width: 25%;
    }

    .profile-transactions-element .element-amount {
        width: 15%;
    }

    .profile-transactions-element .element-status {
        width: 11%;
    }

    .profile-transactions-element .date-title,
    .profile-transactions-element .type-title,
    .profile-transactions-element .method-title,
    .profile-transactions-element .transaction-title,
    .profile-transactions-element .status-title,
    .profile-transactions-element .amount-title {
        display: none;
        font-size: 14px;
        font-weight: 600;
        color: #8bacc8;
    }

    .profile-transactions-element .date-content,
    .profile-transactions-element .method-content,
    .profile-transactions-element .amount-content,
    .profile-transactions-element .transaction-content,
    .profile-transactions-element .status-content,
    .profile-transactions-element .type-content {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 500;
        color: #5b84ae;
        padding-right: 5px;
        width: 100%;
    }

    .profile-transactions-element .transaction-content > * {
        color: #5b84ae;
    }

    .profile-transactions-element .status-content {
        padding-right: unset;
    }

    .profile-transactions-element .method-content,
    .profile-transactions-element .status-content,
    .profile-transactions-element .type-content {
        font-weight: 600;
    }

    .profile-transactions-element .method-content.admin {
        color: white;
    }

    .profile-transactions-element .method-content.btc {
        color: #f7931a;
    }

    .profile-transactions-element .method-content.eth {
        color: #627eea;
    }

    .profile-transactions-element .method-content.ltc {
        color: #527fc6;
    }

    .profile-transactions-element .method-content.usdt {
        color: #27a17c;
    }

    .profile-transactions-element .method-content.sol {
        color: #d987ff;
    }

    .profile-transactions-element .method-content.usdc {
        color: #2775ca;
    }

    .profile-transactions-element .method-content.credit {
        color: #148eff;
    }

    .profile-transactions-element .method-content.kinguin {
        color: #ffbc00;
    }

    .profile-transactions-element .method-content.limiteds {
        color: #8472f1;
    }

    .profile-transactions-element .status-content {
        width: 100%;
        overflow: hidden;
    }

    .profile-transactions-element .status-content svg {
        margin-left: auto;
    }

    .profile-transactions-element .amount-content img {
        width: 18px;
        height: 18px;
    }

    .profile-transactions-element .amount-content.failed {
        filter: brightness(0.5);
        width: fit-content;
        opacity: 0.8;
    }
    .profile-transactions-element .amount-content.failed .content-value {
        color: white;
    }

    .profile-transactions-element .amount-content.failed::before {
        position: absolute;
        content: '';
        left: 0;
        width: 100%;
        top: 50%;
        height: 1px;
        background: white;
    }

    .profile-transactions-element .content-value {
        word-break: break-all;
        font-weight: 600;
        color: #5b84ae;
        width: 100%;
    }

    .profile-transactions-element .content-value span {
        font-size: 10px;
        font-weight: 600;
        opacity: 0.6;
    }

    .profile-transactions-element .transaction-content button,
    .profile-transactions-element .transaction-content span,
    .profile-transactions-element .transaction-content a,
    .profile-transactions-element .status-content span,
    .profile-transactions-element .method-content span {
        flex: 1;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .profile-transactions-element .status-content span {
        flex: unset;
    }

    .profile-transactions-element .status-content span.gold {
        color: #efc633;
    }

    .profile-transactions-element .status-content span.green {
        color: #00d194;
    }

    @media only screen and (max-width: 725px) {
        .profile-transactions-element {
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .profile-transactions-element .element-date,
        .profile-transactions-element .element-type,
        .profile-transactions-element .element-method,
        .profile-transactions-element .element-transaction,
        .profile-transactions-element .element-status,
        .profile-transactions-element .element-amount {
            width: 33.33%;
        }

        .profile-transactions-element .element-transaction,
        .profile-transactions-element .element-status,
        .profile-transactions-element .element-amount {
            margin-top: 10px;
        }

        .profile-transactions-element .date-title,
        .profile-transactions-element .type-title,
        .profile-transactions-element .method-title,
        .profile-transactions-element .transaction-title,
        .profile-transactions-element .status-title,
        .profile-transactions-element .amount-title {
            display: block;
        }

        .profile-transactions-element .status-content svg {
            margin-left: unset;
        }
    }
</style>
