<template>
    <div :class="$style['profile-affiliates-code']" class="octagonal before after">
        <p :class="$style['code-title']">
            <svg
                width="19"
                height="19"
                viewBox="0 0 19 19"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.31265 0.00135739C1.92906 0.010552 1.54202 0.111514 1.18877 0.315462C0.058368 0.968098 -0.33329 2.43346 0.319347 3.56385C0.971983 4.69425 2.43601 5.08724 3.56642 4.4346C4.69681 3.78196 5.08979 2.31661 4.43715 1.18621C3.98847 0.409056 3.15653 -0.0188722 2.31265 0.00135739Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.31265 0.00135739C1.92906 0.010552 1.54202 0.111514 1.18877 0.315462C0.058368 0.968098 -0.33329 2.43346 0.319347 3.56385C0.971983 4.69425 2.43601 5.08724 3.56642 4.4346C4.69681 3.78196 5.08979 2.31661 4.43715 1.18621C3.98847 0.409056 3.15653 -0.0188722 2.31265 0.00135739Z"
                    fill="url(#paint0_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.625 0C15.3214 0 14.25 1.07136 14.25 2.375C14.25 3.67864 15.3214 4.75 16.625 4.75C17.9286 4.75 19 3.67864 19 2.375C19 1.07136 17.9286 0 16.625 0Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.625 0C15.3214 0 14.25 1.07136 14.25 2.375C14.25 3.67864 15.3214 4.75 16.625 4.75C17.9286 4.75 19 3.67864 19 2.375C19 1.07136 17.9286 0 16.625 0Z"
                    fill="url(#paint1_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.375 14.25C1.07136 14.25 0 15.3214 0 16.625C0 17.9286 1.07136 19 2.375 19C3.67864 19 4.75 17.9286 4.75 16.625C4.75 15.3214 3.67864 14.25 2.375 14.25Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M2.375 14.25C1.07136 14.25 0 15.3214 0 16.625C0 17.9286 1.07136 19 2.375 19C3.67864 19 4.75 17.9286 4.75 16.625C4.75 15.3214 3.67864 14.25 2.375 14.25Z"
                    fill="url(#paint2_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.625 14.25C15.3214 14.25 14.25 15.3214 14.25 16.625C14.25 17.9286 15.3214 19 16.625 19C17.9286 19 19 17.9286 19 16.625C19 15.3214 17.9286 14.25 16.625 14.25Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M16.625 14.25C15.3214 14.25 14.25 15.3214 14.25 16.625C14.25 17.9286 15.3214 19 16.625 19C17.9286 19 19 17.9286 19 16.625C19 15.3214 17.9286 14.25 16.625 14.25Z"
                    fill="url(#paint3_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.50021 3.39295C6.13537 3.39296 3.39307 6.13526 3.39307 9.50009C3.39307 11.4029 4.27021 13.1062 5.64083 14.2276C5.72174 14.342 5.8361 14.4286 5.96819 14.4754C6.96653 15.1866 8.18516 15.6072 9.50021 15.6072C10.8644 15.6072 12.1253 15.1561 13.1436 14.3959C13.1456 14.3945 13.1483 14.3932 13.1503 14.3918C13.2018 14.3615 13.2491 14.3245 13.2908 14.2818C14.7003 13.1617 15.6074 11.4342 15.6074 9.50003C15.6074 6.13519 12.865 3.39296 9.50021 3.39295ZM9.50021 4.7501C12.1316 4.7501 14.2502 6.86871 14.2502 9.50009C14.2502 10.7045 13.8056 11.8003 13.072 12.6358C12.6579 11.8818 12.0126 11.2729 11.2324 10.9049C11.8311 10.4057 12.2145 9.65586 12.2145 8.82152C12.2145 7.33051 10.9912 6.10724 9.50021 6.10724C8.00919 6.10724 6.78592 7.33051 6.78592 8.82152C6.78592 9.65586 7.16935 10.4057 7.76801 10.9049C6.98776 11.2732 6.34354 11.8827 5.92976 12.6372C5.19537 11.8014 4.75021 10.7051 4.75021 9.50009C4.75021 6.86871 6.86882 4.7501 9.50021 4.7501Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M9.50021 3.39295C6.13537 3.39296 3.39307 6.13526 3.39307 9.50009C3.39307 11.4029 4.27021 13.1062 5.64083 14.2276C5.72174 14.342 5.8361 14.4286 5.96819 14.4754C6.96653 15.1866 8.18516 15.6072 9.50021 15.6072C10.8644 15.6072 12.1253 15.1561 13.1436 14.3959C13.1456 14.3945 13.1483 14.3932 13.1503 14.3918C13.2018 14.3615 13.2491 14.3245 13.2908 14.2818C14.7003 13.1617 15.6074 11.4342 15.6074 9.50003C15.6074 6.13519 12.865 3.39296 9.50021 3.39295ZM9.50021 4.7501C12.1316 4.7501 14.2502 6.86871 14.2502 9.50009C14.2502 10.7045 13.8056 11.8003 13.072 12.6358C12.6579 11.8818 12.0126 11.2729 11.2324 10.9049C11.8311 10.4057 12.2145 9.65586 12.2145 8.82152C12.2145 7.33051 10.9912 6.10724 9.50021 6.10724C8.00919 6.10724 6.78592 7.33051 6.78592 8.82152C6.78592 9.65586 7.16935 10.4057 7.76801 10.9049C6.98776 11.2732 6.34354 11.8827 5.92976 12.6372C5.19537 11.8014 4.75021 10.7051 4.75021 9.50009C4.75021 6.86871 6.86882 4.7501 9.50021 4.7501Z"
                    fill="url(#paint4_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3979 2.92371C15.2179 2.92373 15.0453 2.99524 14.9181 3.12251L13.0918 4.94882C12.9645 5.07607 12.8931 5.24865 12.8931 5.42859C12.8931 5.60853 12.9645 5.78111 13.0918 5.90836C13.219 6.03559 13.3916 6.10706 13.5715 6.10706C13.7515 6.10706 13.9241 6.03559 14.0513 5.90836L15.8776 4.08205C16.0049 3.9548 16.0763 3.78222 16.0763 3.60228C16.0763 3.42233 16.0049 3.24976 15.8776 3.12251C15.7504 2.99524 15.5778 2.92373 15.3979 2.92371Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M15.3979 2.92371C15.2179 2.92373 15.0453 2.99524 14.9181 3.12251L13.0918 4.94882C12.9645 5.07607 12.8931 5.24865 12.8931 5.42859C12.8931 5.60853 12.9645 5.78111 13.0918 5.90836C13.219 6.03559 13.3916 6.10706 13.5715 6.10706C13.7515 6.10706 13.9241 6.03559 14.0513 5.90836L15.8776 4.08205C16.0049 3.9548 16.0763 3.78222 16.0763 3.60228C16.0763 3.42233 16.0049 3.24976 15.8776 3.12251C15.7504 2.99524 15.5778 2.92373 15.3979 2.92371Z"
                    fill="url(#paint5_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.42854 12.8928C5.24858 12.8928 5.07601 12.9644 4.94878 13.0916L3.13571 14.9047C3.00848 15.0319 2.93701 15.2045 2.93701 15.3845C2.93701 15.5644 3.00848 15.737 3.13571 15.8642C3.26296 15.9915 3.43554 16.0629 3.61548 16.0629C3.79542 16.0629 3.96801 15.9915 4.09526 15.8642L5.90831 14.0512C6.03553 13.9239 6.10701 13.7513 6.10701 13.5714C6.10701 13.3915 6.03553 13.2189 5.90831 13.0916C5.78107 12.9644 5.6085 12.8928 5.42854 12.8928Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M5.42854 12.8928C5.24858 12.8928 5.07601 12.9644 4.94878 13.0916L3.13571 14.9047C3.00848 15.0319 2.93701 15.2045 2.93701 15.3845C2.93701 15.5644 3.00848 15.737 3.13571 15.8642C3.26296 15.9915 3.43554 16.0629 3.61548 16.0629C3.79542 16.0629 3.96801 15.9915 4.09526 15.8642L5.90831 14.0512C6.03553 13.9239 6.10701 13.7513 6.10701 13.5714C6.10701 13.3915 6.03553 13.2189 5.90831 13.0916C5.78107 12.9644 5.6085 12.8928 5.42854 12.8928Z"
                    fill="url(#paint6_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.5715 12.8928C13.3916 12.8928 13.219 12.9644 13.0918 13.0916C12.9645 13.2189 12.8931 13.3915 12.8931 13.5714C12.8931 13.7513 12.9645 13.9239 13.0918 14.0512L14.9035 15.8629C15.0307 15.9901 15.2033 16.0616 15.3833 16.0616C15.5632 16.0616 15.7358 15.9901 15.863 15.8629C15.9903 15.7357 16.0617 15.5631 16.0617 15.3831C16.0617 15.2032 15.9903 15.0306 15.863 14.9034L14.0513 13.0916C13.9241 12.9644 13.7515 12.8928 13.5715 12.8928Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M13.5715 12.8928C13.3916 12.8928 13.219 12.9644 13.0918 13.0916C12.9645 13.2189 12.8931 13.3915 12.8931 13.5714C12.8931 13.7513 12.9645 13.9239 13.0918 14.0512L14.9035 15.8629C15.0307 15.9901 15.2033 16.0616 15.3833 16.0616C15.5632 16.0616 15.7358 15.9901 15.863 15.8629C15.9903 15.7357 16.0617 15.5631 16.0617 15.3831C16.0617 15.2032 15.9903 15.0306 15.863 14.9034L14.0513 13.0916C13.9241 12.9644 13.7515 12.8928 13.5715 12.8928Z"
                    fill="url(#paint7_linear_42_3507)"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.61939 2.94092C3.43943 2.94095 3.26685 3.01246 3.13961 3.13972C3.01239 3.26697 2.94092 3.43955 2.94092 3.61949C2.94092 3.79943 3.01239 3.97201 3.13961 4.09926L4.94871 5.90834C5.07596 6.03557 5.24852 6.10704 5.42847 6.10704C5.60841 6.10704 5.78099 6.03557 5.90824 5.90834C6.03546 5.78109 6.10694 5.60851 6.10694 5.42857C6.10694 5.24863 6.03546 5.07605 5.90824 4.9488L4.09917 3.13972C3.97193 3.01245 3.79935 2.94094 3.61939 2.94092Z"
                    fill="#5B84AE"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M3.61939 2.94092C3.43943 2.94095 3.26685 3.01246 3.13961 3.13972C3.01239 3.26697 2.94092 3.43955 2.94092 3.61949C2.94092 3.79943 3.01239 3.97201 3.13961 4.09926L4.94871 5.90834C5.07596 6.03557 5.24852 6.10704 5.42847 6.10704C5.60841 6.10704 5.78099 6.03557 5.90824 5.90834C6.03546 5.78109 6.10694 5.60851 6.10694 5.42857C6.10694 5.24863 6.03546 5.07605 5.90824 4.9488L4.09917 3.13972C3.97193 3.01245 3.79935 2.94094 3.61939 2.94092Z"
                    fill="url(#paint8_linear_42_3507)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_42_3507"
                        x1="3.05656"
                        y1="4.30493"
                        x2="3.81476"
                        y2="1.91464"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_42_3507"
                        x1="17.3036"
                        y1="4.30469"
                        x2="18.0618"
                        y2="1.91414"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint2_linear_42_3507"
                        x1="3.05357"
                        y1="18.5547"
                        x2="3.81182"
                        y2="16.1641"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint3_linear_42_3507"
                        x1="17.3036"
                        y1="18.5547"
                        x2="18.0618"
                        y2="16.1641"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint4_linear_42_3507"
                        x1="11.2451"
                        y1="14.4621"
                        x2="13.1949"
                        y2="8.31499"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint5_linear_42_3507"
                        x1="14.9394"
                        y1="5.80862"
                        x2="15.4476"
                        y2="4.20653"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint6_linear_42_3507"
                        x1="4.97486"
                        y1="15.7657"
                        x2="5.48093"
                        y2="14.1703"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint7_linear_42_3507"
                        x1="14.9301"
                        y1="15.7645"
                        x2="15.4359"
                        y2="14.1698"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                    <linearGradient
                        id="paint8_linear_42_3507"
                        x1="4.97622"
                        y1="5.81022"
                        x2="5.48164"
                        y2="4.2168"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#47FF5A" />
                        <stop offset="0.764754" stop-color="#A6FFDF" />
                        <stop offset="1" stop-color="#0AF182" />
                    </linearGradient>
                </defs>
            </svg>
            <span class="fade-lime">Your Referral Code</span>
        </p>
        <hr />
        <div :class="$style['code-group']">
            <div :class="$style['group-code']">
                <div :class="$style['code-item']">
                    <span :class="$style['item-title']" class="fade-green"
                        >Your affiliate code</span
                    >
                    <div :class="[$style['code-input'], { [$style.edit]: affiliatesEdit }]">
                        <input
                            v-model="affiliatesCode"
                            v-on:focus="affiliatesEdit = true"
                            v-on:input="affiliatesEdit = true"
                            v-on:keydown.escape="affiliatesUndoButton"
                            v-on:keydown.enter="affiliatesCodeButton"
                        />
                        <button
                            :class="$style['input-action']"
                            class="octagonal before after hoverable"
                            v-on:click="affiliatesCodeButton"
                        >
                            <span :class="{ 'fade-green': affiliatesEdit }">{{
                                affiliatesEdit ? 'Save' : 'Edit'
                            }}</span>
                        </button>
                        <button
                            v-on:click="affiliatesUndoButton"
                            :class="$style['input-undo']"
                            v-if="affiliatesEdit"
                        >
                            <svg
                                stroke-width="0"
                                viewBox="0 0 512 512"
                                height="12"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M212.333 224.333H12c-6.627 0-12-5.373-12-12V12C0 5.373 5.373 0 12 0h48c6.627 0 12 5.373 12 12v78.112C117.773 39.279 184.26 7.47 258.175 8.007c136.906.994 246.448 111.623 246.157 248.532C504.041 393.258 393.12 504 256.333 504c-64.089 0-122.496-24.313-166.51-64.215-5.099-4.622-5.334-12.554-.467-17.42l33.967-33.967c4.474-4.474 11.662-4.717 16.401-.525C170.76 415.336 211.58 432 256.333 432c97.268 0 176-78.716 176-176 0-97.267-78.716-176-176-176-58.496 0-110.28 28.476-142.274 72.333h98.274c6.627 0 12 5.373 12 12v48c0 6.627-5.373 12-12 12z"
                                    fill="url(#paint0_linear_42_31526)"
                                ></path>
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_42_31526"
                                        x1="14.8748"
                                        y1="0.708374"
                                        x2="-1.16331"
                                        y2="4.10293"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stop-color="#00FFC2" />
                                        <stop offset="1" stop-color="#00AA6D" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
                <div :class="$style['code-item']">
                    <span :class="$style['item-title']" class="fade-green">Your referral link</span>
                    <div :class="$style['code-input']">
                        <input
                            v-bind:value="
                                this.affiliatesData?.data?.code
                                    ? `https://rollbet.gg?a=${this.affiliatesData?.data?.code}`
                                    : '--'
                            "
                            readonly="readonly"
                        />
                        <button :class="$style['input-copy']" v-on:click="affiliatesCopyButton">
                            <svg
                                width="17"
                                height="17"
                                viewBox="0 0 17 17"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <mask
                                    id="mask0_42_3526"
                                    style="mask-type: luminance"
                                    maskUnits="userSpaceOnUse"
                                    x="0"
                                    y="0"
                                    width="17"
                                    height="17"
                                >
                                    <path d="M0 0H17V17H0V0Z" fill="white" />
                                </mask>
                                <g mask="url(#mask0_42_3526)">
                                    <path
                                        d="M10.6248 0.708374H2.83317C2.054 0.708374 1.4165 1.34587 1.4165 2.12504V11.3334C1.4165 11.723 1.73525 12.0417 2.12484 12.0417C2.51442 12.0417 2.83317 11.723 2.83317 11.3334V2.83337C2.83317 2.44379 3.15192 2.12504 3.5415 2.12504H10.6248C11.0144 2.12504 11.3332 1.80629 11.3332 1.41671C11.3332 1.02712 11.0144 0.708374 10.6248 0.708374ZM13.4582 3.54171H5.6665C4.88734 3.54171 4.24984 4.17921 4.24984 4.95837V14.875C4.24984 15.6542 4.88734 16.2917 5.6665 16.2917H13.4582C14.2373 16.2917 14.8748 15.6542 14.8748 14.875V4.95837C14.8748 4.17921 14.2373 3.54171 13.4582 3.54171ZM12.7498 14.875H6.37484C5.98525 14.875 5.6665 14.5563 5.6665 14.1667V5.66671C5.6665 5.27712 5.98525 4.95837 6.37484 4.95837H12.7498C13.1394 4.95837 13.4582 5.27712 13.4582 5.66671V14.1667C13.4582 14.5563 13.1394 14.875 12.7498 14.875Z"
                                        fill="black"
                                    />
                                    <path
                                        d="M10.6248 0.708374H2.83317C2.054 0.708374 1.4165 1.34587 1.4165 2.12504V11.3334C1.4165 11.723 1.73525 12.0417 2.12484 12.0417C2.51442 12.0417 2.83317 11.723 2.83317 11.3334V2.83337C2.83317 2.44379 3.15192 2.12504 3.5415 2.12504H10.6248C11.0144 2.12504 11.3332 1.80629 11.3332 1.41671C11.3332 1.02712 11.0144 0.708374 10.6248 0.708374ZM13.4582 3.54171H5.6665C4.88734 3.54171 4.24984 4.17921 4.24984 4.95837V14.875C4.24984 15.6542 4.88734 16.2917 5.6665 16.2917H13.4582C14.2373 16.2917 14.8748 15.6542 14.8748 14.875V4.95837C14.8748 4.17921 14.2373 3.54171 13.4582 3.54171ZM12.7498 14.875H6.37484C5.98525 14.875 5.6665 14.5563 5.6665 14.1667V5.66671C5.6665 5.27712 5.98525 4.95837 6.37484 4.95837H12.7498C13.1394 4.95837 13.4582 5.27712 13.4582 5.66671V14.1667C13.4582 14.5563 13.1394 14.875 12.7498 14.875Z"
                                        fill="url(#paint0_linear_42_3526)"
                                    />
                                </g>
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_42_3526"
                                        x1="14.8748"
                                        y1="0.708374"
                                        x2="-1.16331"
                                        y2="4.10293"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stop-color="#00FFC2" />
                                        <stop offset="1" stop-color="#00AA6D" />
                                    </linearGradient>
                                </defs>
                            </svg>
                        </button>
                    </div>
                </div>
                <hr />
                <a :class="$style['code-sponsor']" class="octagonal before after hoverable">
                    <img src="@/assets/img/rewards-link-element.png" alt="" height="80" />
                    <p>
                        <!-- <span v-if="rakebackAlerts" class="rewards-alert">
                            {{ rakebackAlerts }}
                        </span> -->
                        WANT A SPONSORSHIP?
                        <span class="fade-lime">APPLY HERE</span>
                    </p>
                </a>
            </div>
            <div :class="$style['group-code']">
                <hr />
                <div :class="$style['code-banner']">
                    <div :class="$style['banner-content']">
                        <canvas ref="bannerCanvas" width="894" height="390"></canvas>
                    </div>
                    <div :class="$style['banner-options']">
                        <button v-on:click="copyToClipboardCanvas">COPY</button>
                        <p>OR</p>
                        <button v-on:click="downloadCanvas">DOWNLOAD BANNER</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import IconShare from '@/components/icons/IconShare';
    import IconCopy from '@/components/icons/IconCopy';
    import ButtonLoading from '@/components/ButtonLoading';

    export default {
        name: 'ProfileAffiliatesCode',
        components: {
            IconShare,
            IconCopy,
            ButtonLoading
        },
        data() {
            return {
                affiliatesCode: '',
                affiliatesEdit: false,
                canvasWidth: 894,
                canvasHeight: 390
            };
        },
        methods: {
            ...mapActions(['notificationShow', 'affiliatesSendCodeSocket']),
            affiliatesUndoButton() {
                this.affiliatesCode = this.affiliatesData?.data?.code;
                this.affiliatesEdit = false;
            },
            affiliatesCopyButton() {
                const el = document.createElement('textarea');
                el.value =
                    'https://rollbet.gg?a=' +
                    (this.affiliatesData.data !== null &&
                    this.affiliatesData.data.code !== undefined
                        ? this.affiliatesData.data.code
                        : '');
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                this.notificationShow({
                    type: 'success',
                    message: 'Successfully copied to your clipboard.'
                });
            },
            affiliatesCodeButton() {
                if (this.affiliatesEdit === false) {
                    this.affiliatesEdit = true;
                    return;
                }

                if (
                    this.affiliatesCode === null ||
                    this.affiliatesCode === undefined ||
                    this.affiliatesCode.trim() === '' ||
                    this.affiliatesCode.trim().length < 2
                ) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Your entered affiliate code is invalid.'
                    });
                    return;
                }

                const data = { code: this.affiliatesCode };
                if (this.affiliatesCode !== this.affiliatesData.data?.code)
                    this.affiliatesSendCodeSocket(data);
                this.affiliatesEdit = false;
            },
            downloadCanvas() {
                const canvas = this.$refs.bannerCanvas;
                if (canvas === undefined) return;

                const dataURL = canvas.toDataURL('image/png'); // Create a data URL from the canvas content
                const link = document.createElement('a'); // Create a temporary link element
                link.href = dataURL; // Set the data URL as the link's href
                link.download = `${this.authUser.user.username.replace(/[\/\\?*:|"<>]/g, '_').trim()}-code.png`; // Set the desired file name
                link.click(); // Trigger the download by programmatically clicking the link
            },
            async copyToClipboardCanvas() {
                const canvas = this.$refs.bannerCanvas;
                if (canvas === undefined) return;

                try {
                    const blob = await new Promise((resolve, reject) => {
                        canvas.toBlob((blob) => {
                            if (blob) {
                                resolve(blob);
                            } else {
                                reject(new Error('Failed to create blob'));
                            }
                        }, 'image/png');
                    });

                    await navigator.clipboard.write([
                        new ClipboardItem({
                            [blob.type]: blob
                        })
                    ]);

                    this.notificationShow({
                        type: 'success',
                        message: 'Successfully copied to your clipboard.'
                    });
                } catch (err) {
                    this.notificationShow({
                        type: 'error',
                        message: 'Failed to copy to your clipboard.'
                    });
                }
            },
            loadImageToCanvas() {
                const canvas = this.$refs.bannerCanvas;
                if (canvas === undefined) return;

                const context = canvas.getContext('2d');
                const img = new Image();
                img.src = require('@/assets/img/affiliates-banner-template.png');
                // img.src = '/chip.png';

                img.onload = () => {
                    // Draw the image
                    context.drawImage(img, 0, 0);

                    // Set text properties
                    let fontSize = 60;

                    context.font = `800 ${fontSize}px Arial`;
                    context.textAlign = 'center';
                    context.textBaseline = 'middle';

                    let textMetrics = context.measureText(this.affiliatesCode);
                    let textWidth = textMetrics.width;
                    const boxPadding = 90;

                    while (
                        typeof textWidth === 'number' &&
                        textWidth > this.canvasWidth - boxPadding * 2
                    ) {
                        fontSize -= 5;
                        context.font = `800 ${fontSize}px Arial`;
                        textMetrics = context.measureText(this.affiliatesCode);
                        textWidth = textMetrics.width;
                    }

                    // Draw the octagon
                    this.drawOctagonal(context, textWidth + boxPadding, fontSize + 70);

                    // Calculate the center of the canvas
                    const x = this.canvasWidth / 2;
                    const y = this.canvasHeight / 2;

                    // Draw the text in the center
                    this.applyGradientToText(context);
                    context.fillText(this.affiliatesCode, x, y + 7);
                };
            },
            drawOctagonal(context, width, height) {
                const rectWidth = width;
                const rectHeight = height;
                const cornerCut = 10; // How much the corners are "cut" at an angle

                const x = (this.canvasWidth - rectWidth) / 2;
                const y = (this.canvasHeight - rectHeight) / 2 + 5;

                // Create the gradient for the fill
                const fillGradient = 'rgba(10, 21, 30, 0.73)';

                // Create the gradient for the border
                const borderGradient = context.createLinearGradient(0, y, 0, y + rectHeight * 2);
                borderGradient.addColorStop(0, 'transparent'); // Start color
                borderGradient.addColorStop(1, '#1EFFC2'); // End color

                // Draw the octagonal-sided rectangle
                context.beginPath();
                context.moveTo(x + cornerCut, y); // Top left
                context.lineTo(x + rectWidth - cornerCut, y); // Top right
                context.lineTo(x + rectWidth, y + cornerCut); // Top right angled cut
                context.lineTo(x + rectWidth, y + rectHeight - cornerCut); // Right side vertical
                context.lineTo(x + rectWidth - cornerCut, y + rectHeight); // Bottom right angled cut
                context.lineTo(x + cornerCut, y + rectHeight); // Bottom left
                context.lineTo(x, y + rectHeight - cornerCut); // Bottom left angled cut
                context.lineTo(x, y + cornerCut); // Left side vertical
                context.closePath();

                // Fill the shape with gradient
                context.fillStyle = fillGradient;
                context.fill();

                // Apply the border with gradient
                context.strokeStyle = borderGradient;
                context.lineWidth = 4;
                context.stroke();
            },
            applyGradientToText(context) {
                // Create a linear gradient from red to green
                const gradient = context.createLinearGradient(0, 0, this.canvasWidth, 0);
                gradient.addColorStop(0, '#00FFC2'); // Start color
                gradient.addColorStop(1, '#00AA6D'); // End color

                // Set the gradient as the fillStyle
                context.fillStyle = gradient;
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'affiliatesData', 'authUser'])
        },
        watch: {
            'affiliatesData.data': {
                immediate: true,
                handler(data, oldData) {
                    if (
                        this.affiliatesData.data !== undefined &&
                        this.affiliatesData.data !== null
                    ) {
                        this.affiliatesCode =
                            this.affiliatesData.data.code !== undefined
                                ? this.affiliatesData.data.code
                                : '';
                    }

                    this.loadImageToCanvas();
                },
                deep: true
            }
        },
        mounted() {
            this.loadImageToCanvas();
        }
    };
</script>

<style module>
    .profile-affiliates-code {
        font-size: 12px;
        display: flex;
        flex-direction: column;
        gap: 18px;
        width: calc(100% - 4px);
        margin: 4px;
        padding: 20px 16px;
    }

    .profile-affiliates-code::before {
        background: #114e5c;
    }

    .profile-affiliates-code::after {
        background: linear-gradient(140deg, #0c2734, #0c2334);
    }

    .profile-affiliates-code .code-group {
        flex-direction: column;
        display: flex;
        gap: 18px;
    }

    .profile-affiliates-code .group-code {
        flex-direction: column;
        display: flex;
        gap: 18px;
    }

    .profile-affiliates-code hr {
        border-color: #0c3950;
    }

    .profile-affiliates-code .code-title {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        font-size: 18px;
        font-weight: 600;
    }

    .profile-affiliates-code .code-item {
        display: flex;
        flex-direction: column;
        gap: 5px;
    }

    .profile-affiliates-code .item-title {
        font-size: 13px;
        font-weight: 500;
    }

    .profile-affiliates-code .code-input {
        width: 100%;
        position: relative;
    }
    .profile-affiliates-code .code-input input {
        appearance: none;
        width: 100%;
        padding: 0px 14px;
        color: white;
        font-weight: 500;
        height: 45px;
        border-radius: 8px;
        background: #123442;
        border: 1px solid #114e5c;
        padding-right: calc(14px + 48px + 5px);
    }

    .profile-affiliates-code .code-item:last-of-type .code-input input {
        padding-right: calc(14px + 5px + 17px);
    }

    .profile-affiliates-code .code-input .input-action,
    .profile-affiliates-code .code-input .input-undo,
    .profile-affiliates-code .code-input .input-copy {
        position: absolute;
        right: 14px;
        top: 50%;
        transform: translateY(-50%);
    }

    .profile-affiliates-code .code-input .input-undo,
    .profile-affiliates-code .code-input .input-copy {
        top: calc(50% + 1px);
    }

    .profile-affiliates-code .code-input .input-undo {
        right: calc(14px + 48px + 7px);
    }

    .profile-affiliates-code .code-input .input-action::before,
    .profile-affiliates-code .code-input .input-action::after {
        font-size: 2px;
        background: #46b796;
    }
    .profile-affiliates-code .code-input .input-action {
        width: 48px;
        filter: drop-shadow(0px 2px 0px #296e66);
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0 7px;
        font-weight: 600;
        z-index: 1;
        color: white;
    }

    .profile-affiliates-code .code-input.edit input {
        background: #0e2a38;
        border: 1px dashed #114e5c;
        padding-right: calc(14px + 48px + 5px + 12px + 5px);
    }

    .profile-affiliates-code .code-input.edit .input-action {
        filter: unset;
    }

    .profile-affiliates-code .code-input.edit .input-action::before {
        background: linear-gradient(0deg, #00ffc2, transparent);
    }
    .profile-affiliates-code .code-input.edit .input-action::after {
        background: linear-gradient(45deg, #143e47, #174950);
    }

    .profile-affiliates-code .code-input input:focus {
        border-color: #33896f;
    }

    .profile-affiliates-code .code-banner {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .profile-affiliates-code .banner-content {
        aspect-ratio: 298 / 130;
        width: 100%;
        position: relative;
        border-radius: 8px;
        overflow: hidden;
    }

    .profile-affiliates-code .banner-content canvas {
        position: absolute;
        left: 0;
        top: 0;
        aspect-ratio: 298 / 130;
        width: 100%;
    }

    .profile-affiliates-code .banner-options {
        align-items: center;
        display: flex;
        justify-content: space-between;
        gap: 7px;
    }

    .profile-affiliates-code .banner-options button,
    .profile-affiliates-code .banner-options p {
        font-size: 14px;
        color: #88b6c7;
        font-weight: 600;
    }

    .profile-affiliates-code .banner-options button:hover {
        filter: brightness(1.2);
    }

    .profile-affiliates-code .banner-options button:focus {
        filter: brightness(1.1);
    }

    .profile-affiliates-code .banner-options button:active {
        filter: brightness(0.8);
    }

    .profile-affiliates-code .banner-options button {
        padding: 5px 10px;
        background: #0c465759;
        line-height: 1;
        min-height: 35px;
        border-radius: 8px;
    }

    .profile-affiliates-code .banner-options button:last-of-type {
        flex: 1;
    }

    .profile-affiliates-code .code-sponsor {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        height: 62px;
        color: white;
        min-width: 155px;
        padding-left: 70px;
        z-index: 1;
    }

    .profile-affiliates-code .code-sponsor p {
        font-size: 15px;
        text-align: center;
        line-height: 15px;
        font-weight: 600;
    }
    .profile-affiliates-code .code-sponsor p span {
        white-space: nowrap;
    }

    .profile-affiliates-code .code-sponsor p::before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 3px;
        background: radial-gradient(30% 100% at 15% 70%, lime, transparent);
        opacity: 0.15;
        clip-path: var(--octagonal-main);
        z-index: -1;
    }

    .profile-affiliates-code .code-sponsor p::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        font-size: 3px;
        background: radial-gradient(40% 100% at 90% 30%, cyan, transparent);
        opacity: 0.15;
        clip-path: var(--octagonal-main);
        z-index: -1;
    }

    .profile-affiliates-code .code-sponsor img {
        position: absolute;
        bottom: 2px;
        clip-path: var(--octagonal-main);
        font-size: 4px;
        left: 2px;
        z-index: 1;
    }

    .profile-affiliates-code .code-sponsor .rewards-alert {
        justify-content: center;
        align-items: center;
        position: absolute;
        padding: 0px 4.5px;
        border-radius: 8px;
        font-weight: 700;
        font-size: 12px;
        color: white;
        display: flex;
        height: 17px;
        right: -14px;
        z-index: 1;
        top: -6px;
    }

    .profile-affiliates-code .code-sponsor::after {
        font-size: 3px;
        background-size: cover;
    }

    .profile-affiliates-code .code-sponsor::before {
        font-size: 3px;
        background: linear-gradient(0deg, #a1ffe959, #5ff4ad59);
        z-index: 1;
    }

    @media screen and (max-width: 1125px) {
        .profile-affiliates-code .code-group {
            flex-direction: row;
        }

        .profile-affiliates-code .group-code {
            flex: 1;
        }

        .profile-affiliates-code .group-code:last-of-type hr {
            display: none;
        }
    }

    @media screen and (max-width: 665px) {
        .profile-affiliates-code {
            padding-bottom: calc(15px + 16px + 62px);
        }

        .profile-affiliates-code .code-sponsor {
            position: absolute;
            bottom: 16px;
            left: 16px;
            width: calc(100% - 32px);
        }
    }

    @media screen and (max-width: 570px) {
        .profile-affiliates-code .group-code hr {
            display: none;
        }
    }

    @media screen and (max-width: 480px) {
        .profile-affiliates-code .code-group {
            flex-direction: column;
        }

        .profile-affiliates-code {
            padding-bottom: 16px;
            margin-bottom: calc(62px + 14px);
        }

        .profile-affiliates-code .code-sponsor {
            bottom: unset;
            top: calc(100% + 14px);
        }
    }
</style>
