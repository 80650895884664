var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[_vm.$style['profile-stats'], _vm.$style[_vm.userGetLevelColor]]},[_c('div',{staticClass:"octagonal before",class:_vm.$style['stats-content']},[_c('div',{class:_vm.$style['content-user']},[_c('AvatarImage',{attrs:{"image":_vm.authUser.user.avatar,"xp":_vm.authUser.user.xp,"rank":_vm.authUser.user.rank}}),_c('div',{class:_vm.$style['user-deets']},[_c('p',{class:_vm.$style['deets-username']},[_vm._v(" "+_vm._s(_vm.authUser.user.username)+" "),_c('BoxLevel',{attrs:{"level":_vm.userGetLevel,"rank":_vm.authUser.user.rank,"color":_vm.userGetLevelColor}})],1),_c('div',{class:_vm.$style['deets-level']},[_c('div',{class:_vm.$style['level-progress']},[_c('div',{class:_vm.$style['progress-bar'],style:({
                                width:
                                    Math.max(
                                        _vm.userGetLevel < 100
                                            ? ((_vm.authUser.user.xp - _vm.userGetLevelXP) /
                                                  (_vm.userGetNextLevelXP - _vm.userGetLevelXP)) *
                                                  100
                                            : 100,
                                        0
                                    ) + '%'
                            })})])]),_c('p',{class:_vm.$style['deets-xp']},[_c('span',[_vm._v(_vm._s(_vm.userGetLevelStats.current.toLocaleString())+" XP")]),_vm._v(" / "+_vm._s(_vm.userGetLevelStats.next)+" XP ")])])],1),_c('div',{class:_vm.$style['content-list']},_vm._l((_vm.getContentList),function(item,index){return _c('div',{key:index,class:_vm.$style['list-item']},[_c('div',{staticClass:"octagonal before",class:[
                        _vm.$style['item-content'],
                        {
                            [_vm.$style.negative]:
                                index === _vm.getContentList.length - 1 && item.text.startsWith('-')
                        }
                    ]},[_c('h4',[_c('span',{class:{ 'fade-green': index === _vm.getContentList.length - 1 }},[_vm._v(_vm._s(item.title))])]),_c('hr'),_c('p',[(item.type !== 'text')?_c('span',{class:_vm.$style.coins},[_c('img',{attrs:{"width":"10","src":require("@/assets/img/icons/cryptoCoin.svg"),"alt":"icon"}}),_vm._v(" / "),_c('img',{attrs:{"width":"10","src":require("@/assets/img/icons/robuxCoin.svg"),"alt":"icon"}})]):_vm._e(),_vm._v(" "+_vm._s(item.text)+" ")])])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }