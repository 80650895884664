<template>
    <div :class="[$style['rewards-rakeback'], $style[rewardsGetRakebackInfo.rank]]">
        <div :class="$style['rakeback-content']" class="octagonal before">
            <p :class="$style['content-title']">
                <span :class="userBalanceData.type === 'robuxCoin' ? 'fade-gold' : 'fade-lime'">
                    WAGER, INCREASE YOUR TIER AND EARN MORE!
                </span>
            </p>
            <div :class="$style['content-info']">
                <p :class="$style['info-rank']">
                    <span :class="$style['rank-text']">Your current rank is </span>
                    <img
                        v-if="rewardsGetRakebackInfo.rank"
                        width="26"
                        v-bind:src="
                            require(`@/assets/img/ranks/${rewardsGetRakebackInfo.rank}.png`)
                        "
                    />
                    <span
                        v-if="rewardsGetRakebackInfo.rank"
                        :class="[
                            $style['rank-name'],
                            {
                                'fade-gold': rewardsGetRakebackInfo.rank === 'gold',
                                'fade-ice': rewardsGetRakebackInfo.rank === 'ice',
                                'fade-fire': rewardsGetRakebackInfo.rank === 'fire'
                            }
                        ]"
                    >
                        {{
                            rewardsGetRakebackInfo.rank[0].toUpperCase() +
                            rewardsGetRakebackInfo.rank.slice(1).toLowerCase()
                        }}
                    </span>
                    <span v-else> N/A </span>
                </p>
                <p v-if="rewardsGetRakebackInfo.rank" :class="$style['info-meta']">
                    You currently earn
                    <span :class="$style['meta-percent']">
                        {{ rewardsGetRakebackInfo.percent }}%
                    </span>
                    rakeback on all bets
                </p>
                <p v-else :class="$style['info-meta']">
                    Play more games to acquire a rank and start earning rakebacks on all bets!
                </p>
            </div>
            <div :class="$style['content-rank']">
                <div :class="$style['rank-icon']">
                    <img
                        v-if="rewardsGetRakebackInfo.rank"
                        width="26"
                        v-bind:src="
                            require(`@/assets/img/ranks/${rewardsGetRakebackInfo.rank}.png`)
                        "
                    />
                </div>
                <div :class="$style['rank-progress']">
                    <div
                        :class="$style['progress-bar']"
                        :style="{
                            width:
                                Math.min(
                                    100,
                                    Math.max(
                                        0,
                                        (parseFloat(rewardsGetProgress.current) /
                                            parseFloat(rewardsGetProgress.next)) *
                                            100
                                    )
                                ).toFixed(2) + '%'
                        }"
                    ></div>
                </div>
                <div :class="$style['rank-icon']">
                    <img
                        width="26"
                        v-bind:src="
                            require(`@/assets/img/ranks/${rewardsGetRakebackInfo.nextRank}.png`)
                        "
                    />
                </div>
            </div>
            <div :class="$style['content-progress']">
                <p :class="$style['progress-title']">PROGRESS</p>
                <p :class="$style['progress-xp']">
                    {{ rewardsGetProgress.current }}XP / {{ rewardsGetProgress.next }} XP
                </p>
            </div>
            <button
                :class="$style['content-claim']"
                class="octagonal before after hoverable"
                v-on:click="rewardsClaimButton()"
            >
                <!-- :class="{ gold: userBalanceData.type === 'robuxCoin' }" -->
                <p>Claim Rakeback</p>
                <img
                    width="15"
                    v-bind:src="require(`@/assets/img/icons/${userBalanceData.type}.svg`)"
                />
                <p :class="$style['value-content']">
                    {{ rewardsGetValue.split('.')[0]
                    }}<span>.{{ rewardsGetValue.split('.')[1] }} </span>
                </p>
            </button>
            <svg :class="$style['content-dots']" xmlns="http://www.w3.org/2000/svg">
                <defs>
                    <pattern
                        id="polka-dots"
                        x="0"
                        y="0"
                        width="15"
                        height="15"
                        patternUnits="userSpaceOnUse"
                    >
                        <circle cx="3" cy="3" r="3" fill="currentColor" />
                    </pattern>
                </defs>
                <rect width="100%" height="100%" fill="url(#polka-dots)" />
            </svg>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import RewardsCode from '@/components/rewards/RewardsCode';
    import RewardsRakeback from '@/components/rewards/RewardsRakeback';
    import RewardsBoxElement from '@/components/rewards/RewardsBoxElement';
    import {
        getRakebackRankFromXP,
        getNextRakebackRankFromXP,
        getXPFromRakebackRank,
        getPercentFromRakebackRank
    } from '@/utils/user';

    export default {
        name: 'Rakeback',
        metaInfo: {
            title: 'Rakeback - Rollbet.gg'
        },
        components: {
            RewardsCode,
            RewardsRakeback,
            RewardsBoxElement
        },
        methods: {
            ...mapActions(['rakebackGetDataSocket', 'rakebackSendClaimSocket']),
            rewardsClaimButton() {
                const data = { type: this.userBalanceData.type };
                this.rakebackSendClaimSocket(data);
            }
        },
        computed: {
            ...mapGetters(['rakebackData', 'authUser', 'userBalanceData']),
            rewardsGetRakebackInfo() {
                const rank = getRakebackRankFromXP(this.authUser.user?.xp);
                const percent = getPercentFromRakebackRank(rank);
                const nextRank = getNextRakebackRankFromXP(this.authUser.user?.xp);
                return { rank, percent, nextRank };
            },
            rewardsGetProgress() {
                const current = getXPFromRakebackRank(this.rewardsGetRakebackInfo.rank);
                const next = getXPFromRakebackRank(
                    getNextRakebackRankFromXP(this.authUser.user?.xp)
                );

                const max = getXPFromRakebackRank('fire');

                return {
                    current: Math.min(
                        max,
                        Math.round(this.authUser.user?.xp / 1000) - current
                    ).toLocaleString(),
                    next: Math.min(max, next - current).toLocaleString() || '--'
                };
            },
            rewardsGetValue() {
                if (this.userBalanceData.type === 'robuxCoin') {
                    return parseFloat(
                        Math.floor(this.authUser.user.rakeback.robuxCoin.available / 10) / 100
                    )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                } else {
                    return parseFloat(
                        Math.floor(this.authUser.user.rakeback.cryptoCoin.available / 10) / 100
                    )
                        .toFixed(2)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                }
            }
        },
        created() {
            if (this.rakebackData.loading === false) {
                const data = {};
                this.rakebackGetDataSocket(data);
            }
        }
    };
</script>

<style module>
    .rewards-rakeback {
        background: #0d243a;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        clip-path: var(--octagonal-main);
        padding: 4px;
        width: 100%;
        text-align: center;
        color: #21b894;
    }

    .rakeback-content::before {
        background: #162a3d;
    }

    .rakeback-content {
        align-items: center;
        mask-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.9), black);
        padding: 24px 10px;
        display: flex;
        flex-direction: column;
        gap: 12px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main);
    }

    .content-title {
        font-size: 28px;
        font-weight: 600;
    }

    .content-info {
        line-height: 1.1;
    }

    .info-rank {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 22px;
        font-weight: 500;
    }

    .rank-text {
        color: white;
    }

    .rank-name {
        font-weight: 600;
    }

    .info-meta {
        font-size: 16px;
        color: #5b84ae;
        font-weight: 500;
    }

    .meta-percent {
        color: white;
    }

    .content-rank {
        max-width: 725px;
        display: flex;
        width: 100%;
        margin: 14px 0;
        align-items: center;
        gap: 7px;
    }

    .rank-icon {
        min-width: 26px;
        width: 26px;
        height: 26px;
    }

    .rank-progress {
        flex: 1;
        padding: 7px 10px;
        background: #09273f;
        font-size: 2px;
        clip-path: var(--octagonal-main);
    }

    .progress-bar {
        height: 10px;
        background: linear-gradient(
            90deg,
            color-mix(in hsl, currentColor 80%, white),
            color-mix(in hsl, currentColor 80%, black)
        );
        font-size: 0.5px;
        clip-path: var(--octagonal-main);
    }

    .content-progress {
        line-height: 1.1;
        font-weight: 500;
    }

    .progress-title {
        font-size: 13px;
        color: #5b84ae;
    }

    .progress-xp {
        font-size: 14px;
        color: white;
    }

    .content-claim {
        filter: drop-shadow(0 2px 35px #21b89429) drop-shadow(0 2px 0 #136762);
        width: 100%;
        max-width: 283px;
        height: 46px;
        display: flex;
        margin-top: 14px;
        margin-bottom: 7px;
        align-items: center;
        justify-content: center;
        gap: 7px;
        font-size: 16px;
        font-weight: 500;
        color: white;
        padding: 7px 14px;
    }

    .content-claim::before,
    .content-claim::after {
        font-size: 5px;
        background: #21b894;
    }

    .content-claim.gold {
        filter: drop-shadow(0 2px 35px #b8a92129) drop-shadow(0 2px 0 #666713);
    }

    .content-claim.gold::before,
    .content-claim.gold::after {
        font-size: 5px;
        background: #a7980db1;
    }

    .value-content {
        font-weight: 600;
    }

    .value-content span {
        font-size: 0.71em;
        opacity: 0.9;
    }

    .content-dots {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        color: #142941;
        z-index: -1;
        mask-image: radial-gradient(50% 50% at center, black, transparent);
    }

    .rewards-rakeback.bronze {
        color: #f09558;
    }

    .rewards-rakeback.silver {
        color: #a2afbd;
    }

    .rewards-rakeback.gold .progress-bar {
        background: linear-gradient(0.2deg, #ffb547 20%, #ffeea6, #f1cc0a 80%);
    }

    .rewards-rakeback.ice .progress-bar {
        background: linear-gradient(90deg, #81c3e8, #fdfeff, #81c3e8);
    }

    .rewards-rakeback.fire .progress-bar {
        background: linear-gradient(90deg, #ff5c00, #ff6746, #ff7a00);
    }
</style>
