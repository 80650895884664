<template>
    <div :class="$style['profile-games-element']">
        <div :class="$style['element-id']">
            <div :class="$style['id-title']">ID</div>
            <button v-on:click="profileCopyButton" :class="$style['id-content']">
                <svg width="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.53548 9.67585L5.39341 11.818C5.39341 11.818 5.39341 11.818 5.39336 11.818C5.39336 11.818 5.39336 11.8181 5.39331 11.8181C4.5075 12.7039 3.0661 12.704 2.18019 11.8181C1.75102 11.3889 1.51472 10.8183 1.51472 10.2115C1.51472 9.60466 1.75102 9.03417 2.18004 8.605C2.18009 8.60495 2.18014 8.60489 2.18019 8.60484L4.32225 6.46273C4.61798 6.16695 4.61798 5.68739 4.3222 5.39167C4.02648 5.09595 3.54692 5.09595 3.25114 5.39167L1.10908 7.53379C1.10893 7.53394 1.10878 7.53414 1.10862 7.53429C0.393726 8.24944 0 9.20023 0 10.2115C0 11.2229 0.393878 12.1739 1.10913 12.8891C1.8474 13.6274 2.81708 13.9965 3.7868 13.9965C4.75652 13.9965 5.72625 13.6274 6.46442 12.8891C6.46447 12.8891 6.46447 12.889 6.46447 12.889L8.60654 10.7469C8.90226 10.4512 8.90226 9.97163 8.60649 9.67585C8.31081 9.38013 7.8313 9.38013 7.53548 9.67585Z"
                        fill="#5B84AE"
                    />
                    <path
                        d="M13.9999 3.7851C13.9999 2.77362 13.606 1.82267 12.8907 1.10742C11.4142 -0.369024 9.01183 -0.368974 7.53544 1.10742C7.53538 1.10752 7.53528 1.10758 7.53523 1.10768L5.39322 3.24964C5.09744 3.54537 5.09744 4.02498 5.39322 4.3207C5.54116 4.46864 5.73494 4.54256 5.92877 4.54256C6.12256 4.54256 6.31644 4.46859 6.46428 4.3207L8.60629 2.17873C8.60634 2.17863 8.60644 2.17858 8.60654 2.17848C9.49235 1.29267 10.9338 1.29262 11.8197 2.17848C12.2488 2.60765 12.4852 3.17825 12.4852 3.7851C12.4852 4.39189 12.2489 4.96239 11.8198 5.39156L11.8197 5.39171L9.6776 7.53382C9.38188 7.82955 9.38188 8.30911 9.67765 8.60488C9.82554 8.75277 10.0194 8.82674 10.2132 8.82674C10.407 8.82674 10.6008 8.75277 10.7487 8.60488L12.8908 6.46277C12.8909 6.46262 12.8911 6.46241 12.8912 6.46226C13.6061 5.74711 13.9999 4.79632 13.9999 3.7851Z"
                        fill="#5B84AE"
                    />
                    <path
                        d="M4.32242 9.67593C4.4703 9.82382 4.66414 9.89779 4.85792 9.89779C5.05175 9.89779 5.24559 9.82382 5.39347 9.67593L9.67771 5.3917C9.97348 5.09597 9.97348 4.61641 9.67771 4.32064C9.38198 4.02492 8.90242 4.02492 8.60665 4.32064L4.32242 8.60482C4.02664 8.90065 4.02664 9.38021 4.32242 9.67593Z"
                        fill="#5B84AE"
                    />
                </svg>
                <span>
                    {{ bet._id }}
                </span>
            </button>
        </div>
        <div :class="$style['element-date']">
            <div :class="$style['date-title']">Date</div>
            <div :class="$style['date-content']">
                {{
                    new Date(bet.createdAt).toLocaleString('en-US', {
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                }}
            </div>
        </div>
        <div :class="$style['element-game']">
            <div :class="$style['game-title']">Game</div>
            <div :class="$style['game-content']">
                <IconCrash v-if="bet.method === 'crash'" />
                <IconRoll v-else-if="bet.method === 'roll'" />
                <IconBlackjack v-else-if="bet.method === 'blackjack'" />
                <IconDuels v-else-if="bet.method === 'duels'" />
                <IconMines v-else-if="bet.method === 'mines'" />
                <IconTowers v-else-if="bet.method === 'towers'" />
                <IconUnbox v-else-if="bet.method === 'unbox'" />
                <IconBattles v-else-if="bet.method === 'battles'" />
                <IconUpgrader v-else-if="bet.method === 'upgrader'" />
                <span>
                    {{
                        { battles: 'Case Battles', duels: 'Dice Duels', unbox: 'Loot Cases' }[
                            bet.method
                        ] || bet.method[0].toUpperCase() + bet.method.slice(1)
                    }}
                </span>
            </div>
        </div>
        <div :class="$style['element-wager']">
            <div :class="$style['wager-title']">Bet Amount</div>
            <div :class="$style['wager-content']">
                <img
                    v-bind:src="require(`@/assets/img/icons/${profileGetCoinType}.svg`)"
                    alt="icon"
                />
                <div
                    v-bind:class="[
                        $style['content-value'],
                        { [$style.positive]: profileGetAmount >= 0 }
                    ]"
                >
                    {{ profileFormatValue(profileGetAmount).split('.')[0]
                    }}<span>.{{ profileFormatValue(profileGetAmount).split('.')[1] }} </span>
                </div>
            </div>
        </div>
        <div :class="$style['element-payout']">
            <div :class="$style['payout-title']">Payout</div>
            <div :class="$style['payout-content']">
                <img
                    v-bind:src="require(`@/assets/img/icons/${profileGetCoinType}.svg`)"
                    alt="icon"
                />
                <span
                    v-bind:class="[
                        $style['content-value'],
                        { [$style.positive]: bet.payout >= 0 },
                        profileGetCoinType === 'robuxCoin' ? 'fade-gold' : 'fade-green'
                    ]"
                >
                    {{ profileFormatValue(bet.payout).split('.')[0]
                    }}<span>.{{ profileFormatValue(bet.payout).split('.')[1] }} </span>
                </span>
            </div>
        </div>
        <div :class="$style['element-profit']">
            <div :class="$style['profit-title']">Profit</div>
            <div :class="$style['profit-content']">
                <img
                    v-bind:src="require(`@/assets/img/icons/${profileGetCoinType}.svg`)"
                    alt="icon"
                />
                <span
                    v-bind:class="[
                        $style['content-value'],
                        { [$style.positive]: bet.payout - profileGetAmount >= 1 },
                        bet.payout - profileGetAmount < 1
                            ? ''
                            : profileGetCoinType === 'robuxCoin'
                              ? 'fade-gold'
                              : 'fade-green'
                    ]"
                >
                    {{
                        (bet.payout - profileGetAmount >= 1 ? '+' : '') +
                        profileFormatValue(bet.payout - profileGetAmount).split('.')[0]
                    }}<span
                        v-bind:class="[
                            { [$style.positive]: bet.payout - profileGetAmount >= 1 },
                            bet.payout - profileGetAmount < 1
                                ? ''
                                : profileGetCoinType === 'robuxCoin'
                                  ? 'fade-gold'
                                  : 'fade-green'
                        ]"
                        >.{{ profileFormatValue(bet.payout - profileGetAmount).split('.')[1] }}
                    </span>
                </span>
            </div>
        </div>
        <div :class="$style['element-fairness']">
            <div :class="$style['fairness-title']">Fairness</div>
            <div :class="$style['fairness-content']">
                <button
                    v-on:click="profileVerifyButton()"
                    v-bind:disabled="(bet.fair || bet.game?.fair) === undefined"
                    :class="$style['content-verify']"
                    class="octagonal before after hoverable"
                >
                    <svg
                        width="13"
                        height="14"
                        viewBox="0 0 13 14"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M5.35296 7.52458C5.35296 7.31342 5.18177 7.14222 4.97061 7.14222H4.83706L3.52758 4.18628C3.27622 4.25238 3.023 4.31029 2.76833 4.36038L4.0007 7.14222H1.3523L2.56798 4.39805C2.27203 4.45117 1.97431 4.49367 1.67531 4.5251L0.515913 7.14222H0.38237C0.171209 7.14222 1.70768e-05 7.31342 1.70768e-05 7.52458C-0.00564175 8.83862 1.39612 9.72058 2.67649 9.69122C3.95661 9.72091 5.359 8.83827 5.35296 7.52458Z"
                            fill="white"
                        />
                        <path
                            d="M12.6176 7.14222H12.484L11.3246 4.5251C11.0256 4.49367 10.7279 4.45117 10.432 4.39805L11.6477 7.14222H8.99928L10.2316 4.36038C9.97696 4.31031 9.72371 4.25238 9.47238 4.18628L8.16287 7.14222H8.02933C7.81816 7.14222 7.64697 7.31342 7.64697 7.52458C7.64697 8.12179 7.93802 8.67704 8.46651 9.0881C9.45214 9.88668 11.1947 9.88673 12.1804 9.08807C12.7088 8.67704 12.9999 8.12179 12.9999 7.52458C12.9999 7.31342 12.8287 7.14222 12.6176 7.14222Z"
                            fill="white"
                        />
                        <path
                            d="M0.382701 3.82848C1.43203 3.82848 2.47677 3.68607 3.48792 3.40524C5.45846 2.85784 7.5416 2.85784 9.51214 3.40524C10.5233 3.68609 11.568 3.82848 12.6173 3.82848C13.051 3.82848 13.1497 3.22059 12.7382 3.08343H12.7382C11.7458 2.75262 10.7789 2.32731 9.86444 1.81927L9.26038 1.48369C8.5297 1.07776 7.71455 0.837971 6.88234 0.78151V0.387381C6.86353 -0.11939 6.13627 -0.119007 6.11764 0.387381V0.78151C5.28541 0.837971 4.47028 1.07776 3.73961 1.48369L3.13549 1.81927C2.22105 2.32731 1.25421 2.75262 0.261775 3.08343H0.26175C-0.149662 3.22059 -0.051015 3.82848 0.382701 3.82848Z"
                            fill="white"
                        />
                        <path
                            d="M8.02912 11.5267H4.9703C4.33679 11.5267 3.82324 12.0403 3.82324 12.6738C3.82324 12.885 3.99443 13.0561 4.2056 13.0561H8.79383C9.00499 13.0561 9.17618 12.885 9.17618 12.6738C9.17618 12.0403 8.66263 11.5267 8.02912 11.5267Z"
                            fill="white"
                        />
                        <path
                            d="M6.11768 10.7618H6.88238V3.76651C6.62776 3.75703 6.3723 3.75703 6.11768 3.76651V10.7618Z"
                            fill="white"
                        />
                    </svg>
                    <span>Verify</span>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapActions } from 'vuex';
    import IconCrash from '@/components/icons/IconCrash';
    import IconRoll from '@/components/icons/IconRoll';
    import IconBlackjack from '@/components/icons/IconBlackjack';
    import IconUpgrader from '@/components/icons/IconUpgrader';
    import IconDuels from '@/components/icons/IconDuels';
    import IconMines from '@/components/icons/IconMines';
    import IconTowers from '@/components/icons/IconTowers';
    import IconUnbox from '@/components/icons/IconUnbox';
    import IconBattles from '@/components/icons/IconBattles';

    export default {
        name: 'ProfileGamesElement',
        components: {
            IconCrash,
            IconRoll,
            IconBlackjack,
            IconUpgrader,
            IconDuels,
            IconMines,
            IconTowers,
            IconUnbox,
            IconBattles,
            IconUpgrader
        },
        props: ['bet'],
        methods: {
            ...mapActions(['modalsSetData', 'modalsSetShow', 'notificationShow']),
            profileFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            profileVerifyButton() {
                this.modalsSetData({
                    game: this.bet.game || this.bet,
                    type: this.bet.method
                });
                this.modalsSetShow('FairGame');
            },
            profileCopyButton() {
                const el = document.createElement('textarea');
                el.value = this.bet._id;
                el.setAttribute('readonly', '');
                el.style.position = 'absolute';
                el.style.left = '-9999px';
                document.body.appendChild(el);
                el.select();
                document.execCommand('copy');
                document.body.removeChild(el);

                this.notificationShow({
                    type: 'success',
                    message: 'Successfully copied to your clipboard.'
                });
            }
        },
        computed: {
            profileGetCoinType() {
                return this.bet.coinType === 'robuxCoin' ? 'robuxCoin' : 'cryptoCoin';
            },
            profileGetAmount() {
                let amount = this.bet.amount;

                if (this.bet.method === 'blackjack') {
                    amount = Math.floor(
                        this.bet.amount.main + this.bet.amount.sideLeft + this.bet.amount.sideRight
                    );
                    if (this.bet.actions.includes('split') === true) {
                        amount = Math.floor(amount + this.bet.amount.main);
                    }
                }

                return amount;
            }
        }
    };
</script>

<style module>
    .profile-games-element {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        border-radius: 5px;
        background: linear-gradient(270deg, #0c2033, #08243b);
    }

    .profile-games-element:nth-child(even) {
        background: unset;
    }

    .profile-games-element .element-id,
    .profile-games-element .element-date,
    .profile-games-element .element-game,
    .profile-games-element .element-wager,
    .profile-games-element .element-payout,
    .profile-games-element .element-profit,
    .profile-games-element .element-fairness {
        display: flex;
        flex-direction: column;
    }

    .profile-games-element .element-id {
        width: 12%;
    }

    .profile-games-element .element-date {
        width: 22%;
    }

    .profile-games-element .element-game {
        width: 16%;
    }

    .profile-games-element .element-wager {
        width: 16%;
    }

    .profile-games-element .element-payout {
        width: 15%;
    }

    .profile-games-element .element-profit {
        width: 12%;
    }

    .profile-games-element .element-fairness {
        width: 7%;
    }

    .profile-games-element .id-title,
    .profile-games-element .date-title,
    .profile-games-element .game-title,
    .profile-games-element .wager-title,
    .profile-games-element .payout-title,
    .profile-games-element .profit-title,
    .profile-games-element .fairness-title {
        display: none;
        font-size: 15px;
        font-weight: 600;
        color: #8bacc8;
    }

    .profile-games-element .fairness-content button {
        width: 68px;
        height: 29px;
    }

    .profile-games-element .id-content,
    .profile-games-element .date-content,
    .profile-games-element .game-content,
    .profile-games-element .wager-content,
    .profile-games-element .payout-content,
    .profile-games-element .profit-content,
    .profile-games-element .fairness-content {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 500;
        color: #5b84ae;
        padding-right: 5px;
        width: 100%;
    }

    .profile-games-element .fairness-content {
        padding-right: unset;
    }

    .profile-games-element .fairness-content .content-verify {
        display: flex;
        align-items: center;
        gap: 5px;
        padding: 0 15px;
        max-width: fit-content;
        width: 100%;
        font-size: 14px;
        font-weight: 500;
        overflow: hidden;
        color: white;
        z-index: 1;
    }

    .profile-games-element .fairness-content .content-verify svg {
        min-width: 13px;
    }

    .profile-games-element .fairness-content .content-verify::before,
    .profile-games-element .fairness-content .content-verify::after {
        background: #274e8a;
        font-size: 2px;
    }

    .profile-games-element .payout-content img,
    .profile-games-element .wager-content img,
    .profile-games-element .profit-content img {
        height: 16px;
    }

    .profile-games-element .content-value {
        font-size: 15px;
        font-weight: 600;
        word-break: break-all;
        color: #5b84ae;
    }

    .profile-games-element .content-value span {
        font-size: 11px;
        font-weight: 500;
        opacity: 0.6;
    }

    .profile-games-element .content-value.positive {
        color: #ffffff;
    }

    .profile-games-element .game-content {
        font-weight: 600;
        color: white;
    }

    .profile-games-element .content-verify span,
    .profile-games-element .game-content span,
    .profile-games-element .id-content span {
        flex: 1;
        text-align: left;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    @media only screen and (max-width: 1000px) {
        .profile-games-element {
            height: auto;
            flex-wrap: wrap;
            align-items: stretch;
            padding: 10px 25px;
        }

        .profile-games-element .element-id,
        .profile-games-element .element-date,
        .profile-games-element .element-game,
        .profile-games-element .element-wager,
        .profile-games-element .element-payout,
        .profile-games-element .element-profit,
        .profile-games-element .element-fairness {
            width: 25%;
        }

        .profile-games-element .element-payout,
        .profile-games-element .element-profit,
        .profile-games-element .element-fairness {
            margin-top: 10px;
        }

        .profile-games-element .id-title,
        .profile-games-element .date-title,
        .profile-games-element .game-title,
        .profile-games-element .wager-title,
        .profile-games-element .payout-title,
        .profile-games-element .profit-title,
        .profile-games-element .fairness-title {
            display: block;
        }
    }

    @media only screen and (max-width: 500px) {
        .profile-games-element .element-id,
        .profile-games-element .element-date,
        .profile-games-element .element-game,
        .profile-games-element .element-wager,
        .profile-games-element .element-payout,
        .profile-games-element .element-profit,
        .profile-games-element .element-fairness {
            margin-top: 10px;
            width: 50%;
        }

        .profile-games-element .element-id {
            margin-top: unset;
            width: 100%;
        }
    }
</style>
