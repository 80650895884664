<template>
    <div :class="$style['profile-affiliates-stats']" class="octagonal">
        <div :class="$style['stats-content']" class="octagonal before after">
            <div :class="$style['content-header']">
                <p :class="$style['header-title']">Your Statistics & Earnings</p>
                <div :class="$style['header-claim']">
                    <div :class="$style['claim-info']" class="octagonal before">
                        <span class="fade-green">Available Earnings:</span>
                        <p>
                            <img src="@/assets/img/icons/cryptoCoin.svg" width="16" />
                            <span>
                                {{
                                    affiliatesFormatValue(
                                        affiliatesData.data?.available || 0
                                    ).split('.')[0]
                                }}<span
                                    >.{{
                                        affiliatesFormatValue(
                                            affiliatesData.data?.available || 0
                                        ).split('.')[1]
                                    }}</span
                                >
                            </span>
                        </p>
                    </div>
                    <button
                        v-on:click="affiliatesClaimButton"
                        :class="$style['claim-button']"
                        class="octagonal before after octagonal hoverable"
                        v-bind:disabled="socketSendLoading === 'AffiliatesClaimEarnings'"
                    >
                        Claim
                    </button>
                </div>
            </div>
            <hr :class="$style['content-hr']" />
            <div :class="$style['content-list']">
                <div
                    v-for="(item, index) in getContentList"
                    :class="$style['list-item']"
                    v-bind:key="index"
                >
                    <div :class="$style['item-content']" class="octagonal before">
                        <h4>
                            <span :class="{ 'fade-green': [0, 3].includes(index) }">{{
                                item.title
                            }}</span>
                        </h4>
                        <hr />
                        <p>
                            <img
                                v-if="item.title === 'TOTAL EARNED'"
                                width="13"
                                src="@/assets/img/icons/cryptoCoin.svg"
                                alt="icon"
                            />
                            <span v-else-if="item.title !== 'REFERRED'" :class="$style.coins">
                                <img
                                    width="10"
                                    src="@/assets/img/icons/cryptoCoin.svg"
                                    alt="icon"
                                />
                                /
                                <img width="10" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                            </span>
                            <svg
                                v-else
                                width="13"
                                height="14"
                                viewBox="0 0 13 14"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M6.03135 0.137817C4.03597 0.137817 2.4126 1.76119 2.4126 3.75657C2.4126 5.75195 4.03597 7.37532 6.03135 7.37532C8.02673 7.37532 9.6501 5.75195 9.6501 3.75657C9.6501 1.76119 8.02673 0.137817 6.03135 0.137817Z"
                                    fill="black"
                                />
                                <path
                                    d="M6.03135 0.137817C4.03597 0.137817 2.4126 1.76119 2.4126 3.75657C2.4126 5.75195 4.03597 7.37532 6.03135 7.37532C8.02673 7.37532 9.6501 5.75195 9.6501 3.75657C9.6501 1.76119 8.02673 0.137817 6.03135 0.137817Z"
                                    fill="url(#paint0_linear_42_3482)"
                                />
                                <path
                                    d="M10.5337 9.73939C9.54294 8.73343 8.22955 8.17944 6.83542 8.17944H5.22708C3.83298 8.17944 2.51956 8.73343 1.52883 9.73939C0.542946 10.7404 0 12.0618 0 13.4601C0 13.6822 0.180026 13.8622 0.402083 13.8622H11.6604C11.8825 13.8622 12.0625 13.6822 12.0625 13.4601C12.0625 12.0618 11.5196 10.7404 10.5337 9.73939Z"
                                    fill="black"
                                />
                                <path
                                    d="M10.5337 9.73939C9.54294 8.73343 8.22955 8.17944 6.83542 8.17944H5.22708C3.83298 8.17944 2.51956 8.73343 1.52883 9.73939C0.542946 10.7404 0 12.0618 0 13.4601C0 13.6822 0.180026 13.8622 0.402083 13.8622H11.6604C11.8825 13.8622 12.0625 13.6822 12.0625 13.4601C12.0625 12.0618 11.5196 10.7404 10.5337 9.73939Z"
                                    fill="url(#paint1_linear_42_3482)"
                                />
                                <defs>
                                    <linearGradient
                                        id="paint0_linear_42_3482"
                                        x1="9.6501"
                                        y1="0.137817"
                                        x2="1.14943"
                                        y2="2.22111"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stop-color="#00FFC2" />
                                        <stop offset="1" stop-color="#00AA6D" />
                                    </linearGradient>
                                    <linearGradient
                                        id="paint1_linear_42_3482"
                                        x1="12.0625"
                                        y1="8.17944"
                                        x2="0.242449"
                                        y2="14.3283"
                                        gradientUnits="userSpaceOnUse"
                                    >
                                        <stop stop-color="#00FFC2" />
                                        <stop offset="1" stop-color="#00AA6D" />
                                    </linearGradient>
                                </defs>
                            </svg>
                            {{ item.text }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import ButtonLoading from '@/components/ButtonLoading';

    export default {
        name: 'ProfileAffiliatesStats',
        components: {
            ButtonLoading
        },
        methods: {
            ...mapActions(['notificationShow', 'affiliatesSendClaimEarningsSocket']),
            affiliatesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            affiliatesClaimButton() {
                if (this.socketSendLoading !== null) {
                    return;
                }

                if (
                    this.affiliatesData.data === null ||
                    this.affiliatesData.data.available < 10000
                ) {
                    this.notificationShow({
                        type: 'error',
                        message: 'You’ll need a minimum of 10 Robux in earnings to claim.'
                    });
                    return;
                }

                const data = {};
                this.affiliatesSendClaimEarningsSocket(data);
            }
        },
        computed: {
            ...mapGetters(['socketSendLoading', 'authUser', 'affiliatesData']),
            getContentList() {
                return [
                    {
                        title: 'DEPOSITED',
                        text: this.affiliatesFormatValue(this.affiliatesData.data?.deposit || 0)
                    },
                    {
                        title: 'WAGERED',
                        text: this.affiliatesFormatValue(
                            (this.affiliatesData.data?.earned || 0) / 0.005
                        )
                    },
                    {
                        title: 'REFERRED',
                        text: this.affiliatesData.data?.referred?.toLocaleString()
                    },
                    {
                        title: 'TOTAL EARNED',
                        text: this.affiliatesFormatValue(this.affiliatesData.data?.earned || 0)
                    }
                ];
            }
        }
    };
</script>

<style module>
    .profile-affiliates-stats {
        background: #0d243a;
        font-size: 14px;
        clip-path: var(--octagonal-main);
        padding: 4px;
        width: 100%;
    }

    .profile-affiliates-stats .stats-content::before {
        background: #162a3d;
    }

    .profile-affiliates-stats .stats-content {
        padding: 14px 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        min-height: 66px;
        width: 100%;
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main);
    }

    .profile-affiliates-stats .content-header {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: 7px;
        margin-bottom: -3px;
    }

    .profile-affiliates-stats .header-title {
        color: white;
        white-space: nowrap;
        font-size: 18px;
        font-weight: 600;
    }

    .profile-affiliates-stats .header-claim {
        display: flex;
        gap: 6px;
    }

    .profile-affiliates-stats .claim-info {
        display: flex;
        align-items: center;
        color: white;
        font-weight: 600;
        min-height: 36px;
        font-size: 3px;
        flex-wrap: wrap;
        background: linear-gradient(45deg, #133c47, #17484f);
        clip-path: var(--octagonal-main);
        gap: 7px;
        padding: 8.5px 14px;
    }

    .profile-affiliates-stats .claim-info::before {
        font-size: 3px;
        background: #2d6b74;
    }

    .profile-affiliates-stats .claim-info img {
        margin: -5px 0px;
    }

    .profile-affiliates-stats .claim-info span {
        white-space: nowrap;
        font-size: 15px;
    }

    .profile-affiliates-stats .claim-info span span {
        font-size: 11px;
        opacity: 0.6;
    }

    .profile-affiliates-stats .claim-info p {
        display: flex;
        font-size: 15px;
        align-items: center;
        gap: 5px;
    }

    .profile-affiliates-stats .claim-button {
        font-weight: 600;
        display: flex;
        align-items: center;
        justify-content: center;
        min-height: 36px;
        filter: drop-shadow(0px 2px 0px #296e67);
        padding: 0px 14px;
        color: white;
        font-size: 14px;
    }

    .profile-affiliates-stats .claim-button::before,
    .profile-affiliates-stats .claim-button::after {
        background: #46b796;
        font-size: 3px;
    }

    .profile-affiliates-stats .content-hr {
        border-color: #0d2f4b !important;
    }

    .profile-affiliates-stats .content-list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 4px;
        flex: 1;
    }

    .profile-affiliates-stats .list-item {
        background: #0d243a;
        font-size: 10px;
        clip-path: var(--octagonal-main);
        padding: 4px;
        flex: 1;
    }

    .profile-affiliates-stats .item-content::before {
        background: #162a3d;
    }

    .profile-affiliates-stats .item-content {
        align-items: center;
        padding: 14px;
        display: flex;
        flex-direction: column;
        height: 89px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #091d30, #091b2d, #081b2d);
        font-size: 8px;
        clip-path: var(--octagonal-main);
    }

    .profile-affiliates-stats .item-content h4 {
        color: #5b84ae;
        font-size: 14px;
    }

    .profile-affiliates-stats .item-content hr {
        border-color: #1d466b;
        width: 104px;
        mask-image: linear-gradient(90deg, transparent, #1d466b, transparent);
    }

    .profile-affiliates-stats .item-content p {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: white;
    }

    .profile-affiliates-stats .item-content p .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
    }

    .profile-affiliates-stats .item-content p .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 2px;
    }

    .profile-affiliates-stats .item-content p .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -2px;
    }
</style>
