<template>
    <div :class="$style['leaderboard-user-element']">
        <div :class="$style['element-rank']">
            <div :class="$style['rank-title']">Rank</div>
            <div :class="$style['rank-content']">#{{ position }}</div>
        </div>

        <button :class="$style['element-user']" v-on:click="leaderboardUserButton(winner.user)">
            <div :class="$style['user-title']">User</div>
            <div :class="$style['user-content']">
                <AvatarImage
                    v-bind:image="
                        winner.user !== undefined && winner.user !== null
                            ? winner.user.avatar
                            : 'anonymous'
                    "
                    v-bind:rank="winner.user ? winner.user.rank : null"
                    v-bind:level="winner.user ? winner.user.level : null"
                />
                <span>{{ leaderboardGetUsername }}</span>
            </div>
        </button>

        <div :class="$style['element-wagered']">
            <div :class="$style['wagered-title']">Wagered</div>
            <div :class="$style['wagered-content']">
                <span :class="$style.coins">
                    <img width="10" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                    /
                    <img width="10" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                </span>
                <span :class="$style['content-value']">
                    {{ leaderboardFormatValue(winner.points).split('.')[0]
                    }}<span>.{{ leaderboardFormatValue(winner.points).split('.')[1] }}</span>
                </span>
            </div>
        </div>

        <div :class="$style['element-prize']">
            <div :class="$style['prize-title']">Prize</div>
            <div :class="$style['prize-content']">
                <img src="@/assets/img/icons/cryptoCoin.svg" width="14" alt="icon" />
                <span v-if="winner.prize === 0" :class="$style['content-value']" class="fade-green">
                    --
                </span>
                <span v-else :class="$style['content-value']" class="fade-green">
                    {{ leaderboardFormatValue(winner.prize).split('.')[0]
                    }}<span class="fade-green"
                        >.{{ leaderboardFormatValue(winner.prize).split('.')[1] }}</span
                    >
                </span>
            </div>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapActions } from 'vuex';

    export default {
        name: 'LeaderboardUserElement',
        components: {
            AvatarImage
        },
        props: ['position', 'winner'],
        methods: {
            ...mapActions(['generalSetUserInfoData', 'modalsSetShow']),
            leaderboardFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            leaderboardUserButton(user) {
                if (user === null || user.rank === 'system') {
                    return;
                }

                this.generalSetUserInfoData(user);
                this.modalsSetShow('ChatUser');
            }
        },
        computed: {
            leaderboardGetUsername() {
                let username = '--';

                if (this.winner.user !== undefined && this.winner.user !== null) {
                    username = this.winner.user.username;
                } else if (this.winner.user === null) {
                    username = 'Anonymous';
                }

                return username;
            }
        }
    };
</script>

<style module>
    .leaderboard-user-element {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        border-radius: 5px;
        background: linear-gradient(270deg, #0c2033, #08243b);
    }

    .leaderboard-user-element:nth-child(even) {
        background: unset;
    }

    .leaderboard-user-element .element-rank,
    .leaderboard-user-element .element-user,
    .leaderboard-user-element .element-wagered,
    .leaderboard-user-element .element-prize {
        display: flex;
        flex-direction: column;
    }

    .leaderboard-user-element .element-rank {
        width: 9%;
    }

    .leaderboard-user-element .element-user {
        width: 51%;
    }

    .leaderboard-user-element .element-wagered {
        width: 20%;
    }

    .leaderboard-user-element .element-prize {
        width: 20%;
    }

    .leaderboard-user-element .rank-title,
    .leaderboard-user-element .user-title,
    .leaderboard-user-element .wagered-title,
    .leaderboard-user-element .prize-title {
        text-align: start;
        display: none;
        font-size: 14px;
        font-weight: 600;
        color: #8bacc8;
    }

    .leaderboard-user-element .rank-content,
    .leaderboard-user-element .user-content,
    .leaderboard-user-element .wagered-content,
    .leaderboard-user-element .prize-content {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 500;
        color: #5b84ae;
        padding-right: 5px;
        width: 100%;
    }

    .leaderboard-user-element .prize-content {
        padding-right: unset;
    }

    .leaderboard-user-element .user-content,
    .leaderboard-user-element .wagered-content,
    .leaderboard-user-element .prize-content {
        font-weight: 600;
    }

    .leaderboard-user-element .user-content {
        text-align: start;
        color: white;
        gap: 7px;
    }

    .leaderboard-user-element .wagered-content {
        color: white;
    }

    .leaderboard-user-element .user-content img {
        min-width: 27px;
        width: 27px;
        height: 27px;
        border-width: 2px;
    }

    .leaderboard-user-element .wagered-content img,
    .leaderboard-user-element .prize-content img {
        width: 18px;
        height: 18px;
    }

    .leaderboard-user-element .wagered-content .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
        font-size: 15px;
        font-weight: 500;
    }

    .leaderboard-user-element .wagered-content .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 0px;
        width: 10px;
    }

    .leaderboard-user-element .wagered-content .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -4px;
        width: 10px;
    }

    .leaderboard-user-element .content-value {
        word-break: break-all;
        font-weight: 600;
        width: 100%;
    }

    .leaderboard-user-element .content-value span {
        font-size: 10px;
        font-weight: 600;
        opacity: 0.6;
    }

    .leaderboard-user-element .user-content span {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .leaderboard-user-element .prize-content {
        margin-left: auto;
        flex: unset;
        width: unset;
    }

    @media screen and (max-width: 800px) {
        .leaderboard-user-element .element-rank {
            width: 9%;
        }

        .leaderboard-user-element .element-user {
            width: 31%;
        }

        .leaderboard-user-element .element-wagered {
            width: 30%;
        }

        .leaderboard-user-element .element-prize {
            width: 30%;
        }
    }

    @media screen and (max-width: 550px) {
        .leaderboard-user-element {
            height: auto;
            flex-direction: row-reverse;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .leaderboard-user-element .element-rank,
        .leaderboard-user-element .element-user,
        .leaderboard-user-element .element-wagered,
        .leaderboard-user-element .element-prize {
            width: 50%;
        }

        .leaderboard-user-element .element-wagered,
        .leaderboard-user-element .element-prize {
            margin-top: 10px;
        }

        .leaderboard-user-element .rank-title,
        .leaderboard-user-element .user-title,
        .leaderboard-user-element .wagered-title,
        .leaderboard-user-element .prize-title {
            display: block;
        }

        .leaderboard-user-element .prize-content {
            margin-left: unset;
            flex: 1;
            width: 100%;
        }
    }
</style>
