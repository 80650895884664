<template>
    <div :class="$style['profile-transactions']">
        <div :class="$style['transactions-content']" class="octagonal before">
            <div :class="$style['content-controls']">
                <div
                    ref="controlFilter"
                    :class="[
                        $style['controls-filter'],
                        { [$style.active]: filterDropdown === true }
                    ]"
                >
                    <button
                        v-on:click="filterDropdown = !filterDropdown"
                        :class="$style['filter-button']"
                        class="octagonal before after hoverable"
                    >
                        Filter by{{ filter === null ? '' : ':' }}
                        <span v-if="filter !== null">{{
                            filter[0].toUpperCase() + filter.slice(1)
                        }}</span>
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                                fill="#5B84AE"
                            />
                        </svg>
                    </button>
                    <div :class="$style['filter-menu']">
                        <div :class="$style['menu-container']">
                            <div :class="$style['menu-content']" class="octagonal before">
                                <button
                                    :class="[
                                        $style['content-item'],
                                        { [$style.active]: filter === null }
                                    ]"
                                    class="octagonal after before hoverable"
                                    v-on:click="profileSetFilter(null)"
                                >
                                    All
                                </button>
                                <button
                                    :class="[
                                        $style['content-item'],
                                        { [$style.active]: filter === 'withdraw' }
                                    ]"
                                    class="octagonal after before hoverable"
                                    v-on:click="profileSetFilter('withdraw')"
                                >
                                    Withdraw
                                </button>
                                <button
                                    :class="[
                                        $style['content-item'],
                                        { [$style.active]: filter === 'deposit' }
                                    ]"
                                    class="octagonal after before hoverable"
                                    v-on:click="profileSetFilter('deposit')"
                                >
                                    Deposit
                                </button>
                            </div>
                        </div>
                    </div>
                </div>

                <div :class="$style['controls-list']">
                    <button
                        v-on:click="profileSetType(null)"
                        :class="[$style['list-item'], { [$style.active]: type === null }]"
                        class="octagonal before after hoverable"
                    >
                        All
                    </button>
                    <button
                        v-on:click="profileSetType('crypto')"
                        :class="[$style['list-item'], { [$style.active]: type === 'crypto' }]"
                        class="octagonal before after hoverable"
                    >
                        Crypto
                    </button>
                    <button
                        v-on:click="profileSetType('roblox')"
                        :class="[$style['list-item'], { [$style.active]: type === 'roblox' }]"
                        class="octagonal before after hoverable"
                    >
                        Roblox
                    </button>
                    <button
                        v-on:click="profileSetType('creditCard')"
                        :class="[$style['list-item'], { [$style.active]: type === 'creditCard' }]"
                        class="octagonal before after hoverable"
                    >
                        Credit Card
                    </button>
                    <button
                        v-on:click="profileSetType('giftCard')"
                        :class="[$style['list-item'], { [$style.active]: type === 'giftCard' }]"
                        class="octagonal before after hoverable"
                    >
                        Gift Cards
                    </button>
                    <button
                        v-on:click="profileSetType('site')"
                        :class="[$style['list-item'], { [$style.active]: type === 'site' }]"
                        class="octagonal before after hoverable"
                    >
                        On-site
                    </button>
                </div>
            </div>
            <hr :class="$style['content-hr']" />
            <div :class="$style['content-head']">
                <div :class="$style['head-date']">Date</div>
                <div :class="$style['head-type']">Type</div>
                <div :class="$style['head-method']">Method</div>
                <div :class="$style['head-transaction']">Transaction</div>
                <div :class="$style['head-amount']">Amount</div>
                <div :class="$style['head-status']">Status</div>
            </div>
            <div :class="$style['content-table']">
                <transition name="fade" mode="out-in">
                    <div
                        v-if="
                            userTransactionsData.transactions === null ||
                            userTransactionsData.loading === true
                        "
                        :class="$style['content-loading']"
                        key="loading"
                    >
                        <LoadingAnimation />
                    </div>
                    <div
                        v-else-if="userTransactionsData.transactions.length > 0"
                        :class="$style['content-list']"
                        key="data"
                    >
                        <ProfileTransactionsElement
                            v-for="transaction in userTransactionsData.transactions"
                            v-bind:key="transaction._id"
                            v-bind:transaction="transaction"
                        />
                    </div>
                    <div v-else :class="$style['content-empty']" key="empty">
                        No transactions found.
                    </div>
                </transition>
            </div>
            <div :class="$style['content-pagination']">
                <button
                    v-on:click="profileSetPage(userTransactionsData.page - 1)"
                    :class="$style['button-prev']"
                    class="octagonal before after hoverable"
                    v-bind:disabled="userTransactionsData.page <= 1"
                >
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.0374 4.52545C9.97962 4.51623 9.92117 4.51198 9.86269 4.51272L3.16249 4.51272L3.37961 4.41338C3.59184 4.31456 3.78491 4.18007 3.95018 4.016L5.65676 2.3372C6.02451 1.99185 6.0863 1.43631 5.80319 1.02089C5.47369 0.578226 4.84179 0.482111 4.39177 0.806251C4.35542 0.832453 4.32086 0.861014 4.28839 0.891748L0.324749 4.79088C-0.0698583 5.17863 -0.0702055 5.80763 0.32396 6.19582L0.324749 6.1966L4.28839 10.0957C4.68331 10.4831 5.32271 10.4823 5.71656 10.0938C5.74755 10.0632 5.77649 10.0307 5.80319 9.99639C6.0863 9.58097 6.02451 9.02543 5.65676 8.68008L3.95523 6.99631C3.80707 6.8504 3.63671 6.72805 3.4503 6.63371L3.14734 6.49959L9.80712 6.49959C10.323 6.51844 10.7756 6.16394 10.8725 5.66511C10.9618 5.12353 10.5879 4.61331 10.0374 4.52545Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
                <div :class="$style['pagination-info']">
                    Page
                    <div :class="$style['info-input']" class="octagonal before after hoverable">
                        <input
                            v-model="pagePrompt"
                            v-bind:style="{ width: pagePrompt?.toString().length * 0.75 + 'em' }"
                            v-on:keydown.enter="profileSetPage(parseInt(pagePrompt), true)"
                        />
                    </div>
                    /
                    {{
                        Math.ceil(userTransactionsData.count / 9) <= 0
                            ? '1'
                            : Math.ceil(userTransactionsData.count / 9)
                    }}
                </div>
                <button
                    v-on:click="profileSetPage(userTransactionsData.page + 1)"
                    :class="$style['button-next']"
                    class="octagonal before after hoverable"
                    v-bind:disabled="
                        userTransactionsData.page >= Math.ceil(userTransactionsData.count / 9)
                    "
                >
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.876938 6.47455C0.93469 6.48377 0.993137 6.48803 1.05161 6.48728L7.75182 6.48728L7.5347 6.58662C7.32247 6.68544 7.12939 6.81993 6.96412 6.984L5.25755 8.6628C4.8898 9.00815 4.828 9.56369 5.11111 9.97911C5.44062 10.4218 6.07251 10.5179 6.52254 10.1937C6.55889 10.1675 6.59345 10.139 6.62592 10.1083L10.5896 6.20912C10.9842 5.82137 10.9845 5.19237 10.5903 4.80418L10.5896 4.8034L6.62592 0.904271C6.231 0.516862 5.59159 0.517731 5.19774 0.906227C5.16675 0.936806 5.13781 0.969341 5.11111 1.00361C4.828 1.41903 4.88979 1.97457 5.25755 2.31992L6.95907 4.00369C7.10724 4.1496 7.27759 4.27195 7.46401 4.36629L7.76697 4.50041L1.10719 4.50041C0.591302 4.48156 0.13869 4.83607 0.0417743 5.33489C-0.0475039 5.87647 0.3264 6.38669 0.876938 6.47455Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import IconLeftGradient from '@/components/icons/IconLeftGradient';
    import IconRightGradient from '@/components/icons/IconRightGradient';
    import ProfileTransactionsElement from '@/components/profile/ProfileTransactionsElement';

    export default {
        name: 'ProfileTransactions',
        data() {
            return {
                type: null,
                filter: null,
                filterDropdown: false,
                sortDropdown: false,
                sort: 'date-descend',
                pagePrompt: 1
            };
        },
        components: {
            LoadingAnimation,
            IconLeftGradient,
            IconRightGradient,
            ProfileTransactionsElement
        },
        methods: {
            ...mapActions(['userSetTransactionsDataPage', 'userGetTransactionsSocket']),
            profileSetFilter(filter) {
                this.filterDropdown = false;
                if (this.filter === filter) return;
                this.filter = filter;
                this.userSetTransactionsDataPage(1);
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userTransactionsData.page
                };
                this.userGetTransactionsSocket(data);
            },
            profileSetType(type) {
                if (this.type === type) return;
                this.type = type;
                this.userSetTransactionsDataPage(1);
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userTransactionsData.page
                };
                this.userGetTransactionsSocket(data);
            },
            profileSetSort(sort) {
                this.sortDropdown = false;
                if (this.sort === sort) return;
                this.sort = sort;
                this.userSetTransactionsDataPage(1);
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userTransactionsData.page
                };
                this.userGetTransactionsSocket(data);
            },
            profileSetPage(page, kickback) {
                if (this.userTransactionsData.page === page) {
                    return;
                }
                if (
                    isNaN(page) ||
                    page < 1 ||
                    page > Math.ceil(this.userTransactionsData.count / 9)
                ) {
                    if (kickback === true) this.pagePrompt = this.userTransactionsData.page;
                    return;
                }

                this.pagePrompt = page;
                this.userSetTransactionsDataPage(page);

                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userTransactionsData.page
                };
                this.userGetTransactionsSocket(data);
            }
        },
        computed: {
            ...mapGetters(['userTransactionsData'])
        },
        created() {
            if (this.userTransactionsData.loading === false) {
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userTransactionsData.page
                };
                this.pagePrompt = this.userTransactionsData.page;
                this.userGetTransactionsSocket(data);
            }

            document.addEventListener('click', (event) => {
                const controlFilter = this.$refs.controlFilter;
                const controlSort = this.$refs.controlSort;

                if (
                    controlFilter &&
                    !controlFilter.contains(event.target) &&
                    this.filterDropdown == true
                ) {
                    this.filterDropdown = false;
                }

                if (
                    controlSort &&
                    !controlSort.contains(event.target) &&
                    this.sortDropdown == true
                ) {
                    this.sortDropdown = false;
                }
            });
        }
    };
</script>

<style module>
    .profile-transactions {
        background: #0d243a;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        clip-path: var(--octagonal-main);
        padding: 4px;
        width: 100%;
        margin-bottom: 30px;
    }

    .profile-transactions .transactions-content::before {
        background: #162a3d;
        font-size: 12px;
    }

    .profile-transactions .transactions-content {
        align-items: center;
        mask-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.9), black);
        padding: 14px 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main);
    }

    .profile-transactions .content-controls {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        width: 100%;
    }

    .profile-transactions .controls-filter,
    .profile-transactions .controls-sort {
        position: relative;
    }

    .profile-transactions .controls-filter {
        margin-right: auto;
    }

    .profile-transactions .controls-filter .filter-menu,
    .profile-transactions .controls-sort .sort-menu {
        position: absolute;
        left: 50%;
        top: calc(100% + 7px);
        min-width: calc(100% + 14px);
        transition: max-height 0.3s ease;
        transform: translateX(-50%);
        max-height: 0vh;
        overflow: auto;
        z-index: 1;
    }

    .profile-transactions .controls-filter.active .filter-menu,
    .profile-transactions .controls-sort.active .sort-menu {
        font-size: 7px;
        clip-path: var(--octagonal-main);
        max-height: 55vh;
    }

    .profile-transactions .controls-filter .menu-container,
    .profile-transactions .controls-sort .menu-container {
        width: 100%;
        height: 100%;
    }

    .profile-transactions .controls-filter .menu-content,
    .profile-transactions .controls-sort .menu-content {
        display: flex;
        flex-direction: column;
        background: #0e293e;
        padding: 7px 7px 8px 7px;
        gap: 7px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .profile-transactions .controls-filter .menu-content::before,
    .profile-transactions .controls-sort .menu-content::before {
        background: #1f3d56;
    }

    .profile-transactions .controls-filter .content-item,
    .profile-transactions .controls-sort .content-item {
        filter: drop-shadow(0px 2px 0px #0f2133);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 10px;
        color: #6a8ba6;
        gap: 5px;
    }

    .profile-transactions .controls-filter .content-item.active,
    .profile-transactions .controls-sort .content-item.active {
        filter: drop-shadow(0px 2px 0px #0f2133) brightness(0.8);
    }

    .profile-transactions .controls-filter .content-item::after,
    .profile-transactions .controls-sort .content-item::after {
        font-size: 3px;
        background: #12283e;
    }

    .profile-transactions .controls-filter .content-item::before,
    .profile-transactions .controls-sort .content-item::before {
        font-size: 3px;
        background: #203e59;
    }

    .profile-transactions .controls-list {
        display: flex;
        align-items: center;
        gap: 5px;
        flex-wrap: wrap;
    }

    .profile-transactions .filter-button,
    .profile-transactions .sort-button,
    .profile-transactions .list-item {
        display: flex;
        gap: 7px;
        align-items: center;
        filter: drop-shadow(0px 2px 0px #00000059);
        color: #5b85ae;
        font-size: 15px;
        font-weight: 600;
        justify-content: center;
        height: 36px;
        padding: 0 20px;
    }

    .profile-transactions .filter-button,
    .profile-transactions .sort-button {
        padding: 0 12px;
        gap: 10px;
    }

    .profile-transactions .filter-button span,
    .profile-transactions .sort-button span {
        margin-left: -2px;
        color: white;
    }

    .profile-transactions .sort-button span svg,
    .profile-transactions .sort-button p svg {
        margin-bottom: -3px;
        margin-right: 1px;
    }

    .profile-transactions .filter-button:before,
    .profile-transactions .filter-button:after,
    .profile-transactions .sort-button:before,
    .profile-transactions .sort-button:after,
    .profile-transactions .list-item:before,
    .profile-transactions .list-item:after {
        background: #16334f;
        font-size: 3px;
    }

    .profile-transactions .list-item.active {
        filter: unset !important;
        color: white;
    }

    .profile-transactions .list-item.active:before {
        background: linear-gradient(0deg, #1effc2, transparent);
    }

    .profile-transactions .list-item.active:after {
        background: #064552;
    }

    .profile-transactions .content-hr {
        border-color: #2b70a42e;
        width: 100%;
    }

    .profile-transactions .content-head {
        width: 100%;
        height: 42px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 25px;
        background: linear-gradient(45deg, #0b1f33, #0a1c2e);
        border-radius: 8px;
        border: 1px solid #103351;
    }

    .profile-transactions .head-type,
    .profile-transactions .head-method,
    .profile-transactions .head-transaction,
    .profile-transactions .head-amount,
    .profile-transactions .head-status,
    .profile-transactions .head-date {
        font-weight: 600;
        font-size: 15px;
        color: #5b85ae;
    }

    .profile-transactions .head-date {
        width: 16%;
    }

    .profile-transactions .head-type {
        width: 15%;
    }

    .profile-transactions .head-method {
        width: 18%;
    }

    .profile-transactions .head-transaction {
        width: 25%;
    }

    .profile-transactions .head-amount {
        width: 15%;
    }

    .profile-transactions .head-status {
        width: 11%;
        text-align: right;
    }

    .profile-transactions .content-table {
        width: 100%;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(24, 72, 109, 0.5);
    }

    .profile-transactions .content-loading {
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-transactions .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .profile-transactions .content-loading.fade-leave-to {
        opacity: 0;
    }

    .profile-transactions .content-list {
        width: 100%;
    }

    .profile-transactions .content-list::-webkit-scrollbar {
        width: 5px;
        height: 0;
    }

    .profile-transactions .content-list::-webkit-scrollbar-track {
        background-color: #062234;
    }

    .profile-transactions .content-list::-webkit-scrollbar-thumb {
        background-color: #1e5164;
    }

    .profile-transactions .content-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .profile-transactions .content-list.fade-enter-active,
    .profile-transactions .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .profile-transactions .content-list.fade-enter-from,
    .profile-transactions .content-empty.fade-enter-from {
        opacity: 0;
    }

    .profile-transactions .content-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profile-transactions .content-pagination button {
        width: 38px;
        height: 38px;
        font-size: 3px;
        color: #406992;
    }

    .profile-transactions .content-pagination button::before {
        background: #142f4a;
    }

    .profile-transactions .content-pagination button::after {
        background: linear-gradient(0deg, #102b43, #102940);
    }

    .profile-transactions .pagination-info {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #5e768e;
    }

    .profile-transactions .pagination-info .info-input {
        margin-right: -4px;
        font-size: 16px;
    }

    .profile-transactions .pagination-info .info-input::before,
    .profile-transactions .pagination-info .info-input::after {
        background: #00111f;
        font-size: 2px;
    }

    .profile-transactions .pagination-info .info-input input {
        appearance: none;
        background: none;
        padding: 2px 0px 2.5px 0px;
        width: 1.5em;
        min-width: 2em;
        text-align: center;
        max-width: 7.5em;
        color: white;
    }

    @media only screen and (max-width: 725px) {
        .profile-transactions .games-head {
            padding-bottom: 0;
        }

        .profile-transactions .content-head {
            display: none;
        }

        .profile-transactions .content-table {
            margin-top: 0;
        }
    }

    @media screen and (max-width: 600px) {
        .profile-transactions .transactions-content {
            padding: 14px;
        }
    }
</style>
