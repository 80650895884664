<template>
    <div v-bind:class="[$style['profile-settings-element'], $style[info]]">
        <div :class="$style['element-name']">
            <svg
                v-if="info === 'success'"
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                height="12"
                width="12"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M173.898 439.404l-166.4-166.4c-9.997-9.997-9.997-26.206 0-36.204l36.203-36.204c9.997-9.998 26.207-9.998 36.204 0L192 312.69 432.095 72.596c9.997-9.997 26.207-9.997 36.204 0l36.203 36.204c9.997 9.997 9.997 26.206 0 36.204l-294.4 294.401c-9.998 9.997-26.207 9.997-36.204-.001z"
                ></path>
            </svg>
            <svg
                v-else-if="info === 'warning'"
                width="11"
                height="11"
                viewBox="0 0 11 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M10.6282 2.1666L7.29526 5.49988L10.6282 8.833C11.124 9.32895 11.124 10.1323 10.6282 10.6283C10.3805 10.876 10.0557 10.9999 9.73114 10.9999C9.406 10.9999 9.08121 10.8762 8.83369 10.6283L5.50003 7.29476L2.16661 10.6282C1.91894 10.8759 1.59415 10.9999 1.26926 10.9999C0.944472 10.9999 0.619903 10.8761 0.372008 10.6282C-0.12375 10.1325 -0.12375 9.32911 0.372008 8.83297L3.70485 5.49985L0.371819 2.1666C-0.12394 1.67085 -0.12394 0.867291 0.371819 0.371534C0.867483 -0.123845 1.67057 -0.123845 2.16642 0.371534L5.49999 3.70481L8.83331 0.371534C9.32926 -0.123845 10.1324 -0.123845 10.628 0.371534C11.124 0.867291 11.124 1.67085 10.6282 2.1666Z"
                    fill="#5B84AE"
                />
            </svg>
            <svg
                v-if="name === 'SFX Volume'"
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 16 16"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12 1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM4 0a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z"
                ></path>
                <path
                    d="M8 4.75a.75.75 0 1 1 0-1.5.75.75 0 0 1 0 1.5M8 6a2 2 0 1 0 0-4 2 2 0 0 0 0 4m0 3a1.5 1.5 0 1 0 0 3 1.5 1.5 0 0 0 0-3m-3.5 1.5a3.5 3.5 0 1 1 7 0 3.5 3.5 0 0 1-7 0"
                ></path>
            </svg>
            <svg
                v-if="name === 'Email'"
                width="18"
                height="13"
                viewBox="0 0 18 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M8.20334 7.8064L0.0261147 1.79975C0.0102881 1.80789 0.0158266 1.80504 0 1.81318V10.9027C0 11.9067 0.805887 12.7207 1.8 12.7207H16.2C17.1941 12.7207 18 11.9067 18 10.9027V1.79141C17.9971 1.78991 17.9981 1.79044 17.9952 1.78894L9.79686 7.80668C9.32177 8.15541 8.67831 8.1553 8.20334 7.8064Z"
                    fill="#5B84AE"
                />
                <path
                    d="M9.797 5.56169L17.0593 0.227845C16.8049 0.0872597 16.5121 0 16.2001 0H1.80012C1.48812 0 1.20012 0.08726 0.940918 0.227845L8.20324 5.56169C8.67832 5.91062 9.32192 5.91062 9.797 5.56169Z"
                    fill="#5B84AE"
                />
            </svg>
            <svg
                v-if="name === 'Hidden Bets'"
                width="19"
                height="16"
                viewBox="0 0 19 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M12.9313 10.2101C11.8998 10.2101 11 10.7837 10.5333 11.6286C10.2699 11.5544 9.91864 11.4915 9.48463 11.4915C9.06469 11.4915 8.72259 11.5505 8.46224 11.6214C7.99446 10.7805 7.09702 10.2101 6.0684 10.2101C4.5588 10.2101 3.33057 11.4383 3.33057 12.9481C3.33057 14.4578 4.5588 15.6859 6.0684 15.6859C7.57814 15.6859 8.80623 14.4578 8.80623 12.9481C8.80623 12.8595 8.80173 12.7721 8.79347 12.6858C8.9638 12.6426 9.19515 12.6049 9.48463 12.6049C9.7918 12.6049 10.033 12.6474 10.2057 12.6937C10.198 12.7776 10.1935 12.8622 10.1935 12.9481C10.1935 14.4578 11.4217 15.686 12.9313 15.686C14.4409 15.686 15.6691 14.4578 15.6691 12.9481C15.6691 11.4385 14.4409 10.2101 12.9313 10.2101Z"
                    fill="#5B84AE"
                />
                <path
                    d="M17.5274 6.692H15.7071C15.6668 6.46659 15.6142 6.1729 15.5554 5.84631H3.44465C3.3858 6.1729 3.33318 6.46659 3.29288 6.692H1.47263C0.660721 6.692 0 7.35258 0 8.16463V8.17725C0 8.9893 0.660721 9.64988 1.47263 9.64988H17.5274C18.3394 9.64988 19 8.9893 19 8.17725V8.16463C19 7.35258 18.3393 6.692 17.5274 6.692Z"
                    fill="#5B84AE"
                />
                <path
                    d="M15.0639 3.16848C14.8213 1.89864 14.5488 1.06803 13.874 0.580681C13.1357 0.0472334 11.9869 0.114349 11.2588 0.733612C11.2134 0.772171 11.1664 0.812759 11.118 0.854652C10.6687 1.24285 10.1096 1.72585 9.49986 1.72585C8.89016 1.72585 8.33105 1.24285 7.88168 0.854652C7.83327 0.812759 7.7863 0.772171 7.74093 0.733612C7.0128 0.114349 5.86415 0.0472334 5.12573 0.580681C4.45095 1.06803 4.17843 1.89864 3.93577 3.16848C3.85677 3.58205 3.75167 4.15275 3.646 4.73302H15.3537C15.248 4.15275 15.1429 3.58205 15.0639 3.16848Z"
                    fill="#5B84AE"
                />
            </svg>
            {{ name }}
        </div>
        <div :class="$style['element-action']">
            <slot></slot>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ProfileSettingsElement',
        props: ['name', 'info']
    };
</script>

<style module>
    .profile-settings-element {
        width: 100%;
        min-height: 42px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 0 25px;
        gap: 14px;
        border-radius: 5px;
        background: #2462950f;
    }

    .profile-settings-element .element-name {
        display: flex;
        align-items: center;
        font-size: 15px;
        font-weight: 600;
        color: #5b84ae;
    }

    .profile-settings-element.element-success {
        color: white;
    }

    .profile-settings-element .element-name svg {
        margin-right: 5px;
    }

    .profile-settings-element.warning .element-name svg {
        fill: #db7d48;
    }
    .profile-settings-element.success .element-name svg {
        fill: #00ffc2;
    }

    .profile-settings-element .element-action {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 14px;
        flex: 1;
    }
</style>
