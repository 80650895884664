<template>
    <div :class="$style['rewards-leaderboard']">
        <div :class="$style['leaderboard-timer']" class="octagonal before after">
            <svg
                width="13"
                height="16"
                viewBox="0 0 13 16"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M7.09999 2.97601V2.151H7.5C7.84374 2.151 8.125 1.86974 8.125 1.526C8.125 1.18226 7.84374 0.901001 7.5 0.901001H5.45628C5.1125 0.901001 4.83128 1.18226 4.83128 1.526C4.83128 1.86974 5.1125 2.151 5.45628 2.151H5.84999V2.97601C4.85001 3.06977 3.91251 3.39474 3.09376 3.90099L2.52502 3.15724C2.31251 2.88224 1.91875 2.83227 1.64375 3.03849C1.37501 3.25101 1.31874 3.64476 1.53126 3.91976L2.09377 4.65726C0.806274 5.84477 0 7.5385 0 9.41975C-6.25e-08 12.9948 2.90627 15.901 6.47499 15.901C10.05 15.901 12.9563 12.9948 12.9563 9.41975C12.9563 6.06351 10.3812 3.28851 7.09999 2.97601ZM6.47499 13.8885C4.00627 13.8885 2.01252 11.8885 2.01252 9.42601C2.01252 7.16977 3.68126 5.30103 5.85625 5.00726V9.42601C5.85625 9.76975 6.13751 10.051 6.48125 10.051H10.8937C10.5938 12.2197 8.73127 13.8885 6.47499 13.8885Z"
                    fill="black"
                />
                <path
                    d="M7.09999 2.97601V2.151H7.5C7.84374 2.151 8.125 1.86974 8.125 1.526C8.125 1.18226 7.84374 0.901001 7.5 0.901001H5.45628C5.1125 0.901001 4.83128 1.18226 4.83128 1.526C4.83128 1.86974 5.1125 2.151 5.45628 2.151H5.84999V2.97601C4.85001 3.06977 3.91251 3.39474 3.09376 3.90099L2.52502 3.15724C2.31251 2.88224 1.91875 2.83227 1.64375 3.03849C1.37501 3.25101 1.31874 3.64476 1.53126 3.91976L2.09377 4.65726C0.806274 5.84477 0 7.5385 0 9.41975C-6.25e-08 12.9948 2.90627 15.901 6.47499 15.901C10.05 15.901 12.9563 12.9948 12.9563 9.41975C12.9563 6.06351 10.3812 3.28851 7.09999 2.97601ZM6.47499 13.8885C4.00627 13.8885 2.01252 11.8885 2.01252 9.42601C2.01252 7.16977 3.68126 5.30103 5.85625 5.00726V9.42601C5.85625 9.76975 6.13751 10.051 6.48125 10.051H10.8937C10.5938 12.2197 8.73127 13.8885 6.47499 13.8885Z"
                    fill="url(#paint0_linear_1210_7160)"
                />
                <defs>
                    <linearGradient
                        id="paint0_linear_1210_7160"
                        x1="22.0257"
                        y1="-1.32122"
                        x2="-2.93242"
                        y2="6.49658"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#00FFC2" />
                        <stop offset="1" stop-color="#00AA6D" />
                    </linearGradient>
                </defs>
            </svg>

            <p :class="$style['timer-value']">
                <span v-if="parseFloat(leaderboardTimer.split(':')[0]) > 0"
                    >{{ parseFloat(leaderboardTimer.split(':')[0]) }}d</span
                >
                <span>
                    {{ leaderboardTimer.split(':')[1] }}:{{ leaderboardTimer.split(':')[2] }}:{{
                        leaderboardTimer.split(':')[3]
                    }}
                </span>
            </p>
        </div>
        <div :class="$style['leaderboard-content']">
            <div :class="$style['content-option']">
                <div
                    class="octagonal before after"
                    :class="[
                        $style['option-item'],
                        { [$style.active]: leaderboardData?.data?.duration < 7 }
                    ]"
                >
                    3 Days
                </div>
                <div
                    class="octagonal before after"
                    :class="[
                        $style['option-item'],
                        { [$style.active]: leaderboardData?.data?.duration === 7 }
                    ]"
                >
                    Weekly
                </div>
                <div
                    class="octagonal before after"
                    :class="[
                        $style['option-item'],
                        { [$style.active]: leaderboardData?.data?.duration > 7 }
                    ]"
                >
                    Monthly
                </div>
            </div>
            <div :class="$style['content-top']">
                <button
                    v-for="(winner, index) of [
                        leaderboardData.data?.winners?.[0] || {},
                        leaderboardData.data?.winners?.[1] || {},
                        leaderboardData.data?.winners?.[2] || {}
                    ]"
                    v-on:click="leaderboardUserButton(winner.user)"
                    v-bind:key="winner?.user?._id"
                    :class="[
                        $style['top-item'],
                        {
                            [$style.gold]: index === 0,
                            [$style.green]: index === 1
                        }
                    ]"
                >
                    <span
                        :class="[
                            $style['item-rank'],
                            {
                                'fade-gold': index === 0,
                                'fade-green': index === 1
                            }
                        ]"
                        >#{{ index + 1 }}</span
                    >
                    <AvatarImage
                        v-bind:image="
                            winner?.user !== undefined && winner?.user !== null
                                ? winner.user.avatar
                                : winner?.user === null
                                  ? 'anonymous'
                                  : 'blank'
                        "
                        v-bind:rank="winner.user ? winner.user.rank : null"
                        v-bind:level="winner.user ? winner.user.level : null"
                    />
                    <p :class="$style['item-value']">
                        <img width="19" src="@/assets/img/icons/cryptoCoin.svg" />
                        <span :class="$style['value-content']">
                            {{ leaderboardFormatValue(winner?.prize || 0).split('.')[0]
                            }}<span
                                >.{{
                                    leaderboardFormatValue(winner?.prize || 0).split('.')[1]
                                }}</span
                            >
                        </span>
                    </p>
                    <div :class="$style['item-halo']">
                        <!-- <Halo v-bind:active="true"/> -->
                        <Halo v-bind:active="false" />
                    </div>
                    <div :class="$style['item-wagered']" class="octagonal after">
                        <span
                            :class="[
                                $style['wagered-title'],
                                {
                                    'fade-gold': index === 0,
                                    'fade-green': index === 1
                                }
                            ]"
                            >{{ rankText }}</span
                        >
                        <div :class="$style['wagered-content']" class="octagonal before">
                            <div :class="$style['content-icon']">
                                <div :class="$style.coins">
                                    <img
                                        width="10"
                                        src="@/assets/img/icons/cryptoCoin.svg"
                                        alt="icon"
                                    />
                                    /
                                    <img
                                        width="10"
                                        src="@/assets/img/icons/robuxCoin.svg"
                                        alt="icon"
                                    />
                                </div>
                            </div>
                            <div
                                :class="[$style['content-value'], $style['value-content']]"
                                class="octagonal before after"
                            >
                                <p :class="$style['value-content']">
                                    {{ leaderboardFormatValue(winner?.points || 0).split('.')[0]
                                    }}<span
                                        >.{{
                                            leaderboardFormatValue(winner?.points || 0).split(
                                                '.'
                                            )[1]
                                        }}</span
                                    >
                                </p>
                            </div>
                        </div>
                    </div>
                </button>
            </div>
        </div>
        <div :class="$style['leaderboard-ranks']">
            <div :class="$style['ranks-content']" class="octagonal before">
                <div :class="$style['content-head']">
                    <div :class="$style['head-pos']">Rank</div>
                    <div :class="$style['head-user']">User</div>
                    <div :class="$style['head-wagered']">
                        {{ rankText[0]?.toUpperCase() + rankText?.slice(1)?.toLowerCase() }}
                    </div>
                    <div :class="$style['head-prize']">Prize</div>
                </div>
                <div :class="$style['content-table']">
                    <div
                        v-if="leaderboardData?.data !== null"
                        :class="$style['content-list']"
                        key="data"
                    >
                        <LeaderboardUserElement
                            v-for="(winner, index) of leaderboardData.data?.winners?.slice(3)"
                            v-bind:key="index"
                            v-bind:position="index + 4"
                            v-bind:winner="winner"
                        />
                    </div>
                    <div v-else :class="$style['content-empty']" key="empty">
                        No on-going leaderboard event found, stay tuned!
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import Halo from '@/components/Halo';
    import AvatarImage from '@/components/AvatarImage';
    import IconTimer from '@/components/icons/IconTimer';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import LeaderboardBanner from '@/components/leaderboard/LeaderboardBanner';
    import LeaderboardUserElement from '@/components/leaderboard/LeaderboardUserElement';

    export default {
        name: 'RewardsLeaderboard',
        metaInfo: {
            title: 'Leaderboard - Rollbet.gg'
        },
        components: {
            IconTimer,
            Halo,
            AvatarImage,
            LoadingAnimation,
            LeaderboardBanner,
            LeaderboardUserElement
        },
        data() {
            return {
                leaderboardTimerInterval: null,
                leaderboardTimer: '00:--:--:--'
            };
        },
        methods: {
            ...mapActions(['leaderboardGetDataSocket', 'generalSetUserInfoData', 'modalsSetShow']),
            leaderboardUserButton(user) {
                if (user === null || user.rank === 'system') {
                    return;
                }

                this.generalSetUserInfoData(user);
                this.modalsSetShow('ChatUser');
            },
            leaderboardFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            leaderboardGetUsername(winner) {
                let username = 'Empty';

                if (winner.user !== undefined && winner.user !== null) {
                    username = winner.user.username;
                } else if (winner.user === null) {
                    username = 'Anonymous';
                }

                return username;
            },
            leaderboardStartTimer() {
                const endingTime =
                    new Date(this.leaderboardData.data?.updatedAt).getTime() +
                    1000 * 60 * 60 * 24 * Number(this.leaderboardData.data?.duration);
                let timeLeft = Math.floor(
                    (endingTime - (new Date().getTime() + this.generalTimeDiff)) / 1000
                );
                timeLeft = timeLeft <= 0 ? 0 : timeLeft;

                let timeDays = Math.floor(timeLeft / (60 * 60 * 24));
                timeDays = timeDays < 10 ? '0' + timeDays : timeDays;
                let timeHours = Math.floor((timeLeft % (60 * 60 * 24)) / (60 * 60));
                timeHours = timeHours < 10 ? '0' + timeHours : timeHours;
                let timeMinutes = Math.floor((timeLeft % (60 * 60)) / 60);
                timeMinutes = timeMinutes < 10 ? '0' + timeMinutes : timeMinutes;
                let timeSeconds = Math.floor(timeLeft % 60);
                timeSeconds = timeSeconds < 10 ? '0' + timeSeconds : timeSeconds;

                this.leaderboardTimer =
                    timeDays.toString() +
                    ':' +
                    timeHours.toString() +
                    ':' +
                    timeMinutes.toString() +
                    ':' +
                    timeSeconds.toString();
            }
        },
        computed: {
            ...mapGetters(['generalTimeDiff', 'leaderboardData']),
            rankText() {
                return this.leaderboardData.data?.type === 'deposit' ? 'DEPOSITED' : 'WAGERED';
            }
        },
        watch: {
            'leaderboardData.data': {
                handler() {
                    if (this.leaderboardData.data !== null) {
                        clearInterval(this.leaderboardTimerInterval);
                        this.leaderboardTimerInterval = setInterval(() => {
                            this.leaderboardStartTimer();
                        }, 1000);
                    }
                },
                deep: true
            }
        },
        created() {
            if (this.leaderboardData.loading === false) {
                const data = {};
                this.leaderboardGetDataSocket(data);
            }
        }
    };
</script>

<style module>
    .leaderboard-timer {
        position: absolute;
        top: calc(105px + 45px);
        left: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 600;
        padding: 7px 14px;
        color: white;
        width: fit-content;
        z-index: 1;
        transform: translate(-50%, -50%);
    }

    .leaderboard-timer::after {
        background: #143e48;
        font-size: 3px;
    }

    .leaderboard-timer::before {
        background: linear-gradient(0deg, #4ce0a7, transparent);
        font-size: 3px;
    }

    @media screen and (max-width: 1500px) {
        .leaderboard-timer {
            top: calc(105px + 30px);
        }
    }

    .rewards-leaderboard {
        width: 100%;
    }

    .leaderboard-content {
        position: relative;
        width: 100%;
    }

    .content-option {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        flex-wrap: wrap;
        gap: 7px;
    }

    .option-item {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 7px;
        filter: drop-shadow(0 2px 0 #0a1a2c);
        height: 36px;
        font-weight: 600;
        font-size: 16px;
        padding: 0 16px;
        color: #5b85ae;
    }

    .option-item::before {
        background: #1a395a;
        font-size: 3px;
    }

    .option-item::after {
        background: #16324e;
        font-size: 3px;
    }

    .option-item.active {
        color: #00e4a7;
    }

    .option-item.active::after {
        background: linear-gradient(45deg, #143d47, #1b5158);
    }

    .option-item.active::before {
        background: linear-gradient(0deg, #4cdfa6, #1a5057);
    }

    .content-top {
        position: relative;
        display: flex;
        height: 335px;
        width: 100%;
        max-width: 750px;
        margin: 0 auto;
        margin-bottom: 35px;
    }

    .content-top .top-item {
        position: absolute;
        left: 50%;
        bottom: 0;
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 10px;
        color: #1e9ee7;
        gap: 1em;
    }

    .content-top .top-item:nth-child(1) {
        transform: translateX(-50%);
        left: calc(50% + 10px);
        bottom: 10%;
        z-index: 1;
    }

    .content-top .top-item:nth-child(2) {
        font-size: 8.3px;
        bottom: 2.5%;
        left: 0;
    }

    .content-top .top-item:nth-child(3) {
        font-size: 6.38px;
        left: unset;
        right: 0;
    }

    .content-top .top-item.gold {
        color: #f1cd0a;
    }

    .content-top .top-item.green {
        color: #0dd4b1;
    }

    .top-item .item-rank {
        font-size: 2.4em;
        font-weight: 600;
        position: absolute;
        z-index: 1;
        right: 0em;
        top: -0.5em;
        transform: rotate(10deg);
    }

    .top-item .item-value {
        display: flex;
        align-items: center;
        gap: 0.3em;
        font-size: 2em;
        color: white;
        font-weight: 600;
    }

    .top-item > img {
        background: unset;
        border-color: currentColor;
        width: 10.8em;
        height: 10.8em;
    }

    .top-item .item-halo {
        width: 100%;
        position: relative;
        background: red;
        margin-top: 3.6em;
        margin-bottom: -1.5em;
        font-size: 2em;
    }

    .top-item .item-wagered {
        margin-top: 7px;
        padding: 3px;
    }

    .top-item .item-wagered::after {
        font-size: 5px;
        background: #0d243a;
        clip-path: var(--octagonal-main) !important;
    }

    .top-item .wagered-content {
        display: flex;
        padding: 4px 7px;
        gap: 4px;
        background: #0a1e30;
        font-size: 3px;
        clip-path: var(--octagonal-main);
    }

    .top-item .wagered-title {
        position: absolute;
        font-weight: 600;
        font-size: 12px;
        z-index: 2;
        left: 50%;
        top: -2px;
        transform: translate(-50%, -50%);
    }

    .top-item .wagered-content .content-icon {
        min-width: 30px;
        width: 30px;
        height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: linear-gradient(0deg, #1d436a, #173555);
        clip-path: var(--octagonal-main);
        font-size: 2px;
    }

    .top-item .wagered-content .content-icon .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
        font-size: 15px;
        font-weight: 500;
    }

    .top-item .wagered-content .content-icon .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 2px;
        width: 10px;
    }

    .top-item .wagered-content .content-icon .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -2px;
        width: 10px;
    }

    .top-item .wagered-content .content-value {
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 14px;
        color: white;
        font-size: 14px;
        height: 30px;
        font-weight: 600;
    }

    .top-item .wagered-content .content-value::before {
        background: #173753;
        font-size: 2px;
    }

    .top-item .wagered-content .content-value::after {
        background: #0a1e30;
        font-size: 2px;
    }

    .top-item .value-content > span {
        font-size: 0.71em;
        opacity: 0.6;
    }

    .leaderboard-ranks {
        background: #0d243a;
        font-size: 14px;
        display: flex;
        flex-direction: column;
        align-items: center;
        clip-path: var(--octagonal-main);
        padding: 4px;
        width: 100%;
    }

    .ranks-content::before {
        background: #162a3d;
    }

    .ranks-content {
        align-items: center;
        mask-image: linear-gradient(-45deg, rgba(0, 0, 0, 0.9), black);
        padding: 14px 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main);
    }

    .content-table {
        width: 100%;
    }

    .content-head {
        width: 100%;
        height: 42px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 25px;
        background: linear-gradient(45deg, #0b1f33, #0a1c2e);
        border-radius: 8px;
        border: 1px solid #0e243b;
    }

    .content-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .head-pos,
    .head-user,
    .head-wagered,
    .head-prize {
        font-weight: 600;
        font-size: 15px;
        color: #5b85ae;
    }

    .head-pos {
        width: 9%;
    }

    .head-user {
        width: 51%;
    }

    .head-wagered {
        width: 20%;
    }

    .head-prize {
        width: 20%;
        text-align: right;
    }

    @media screen and (max-width: 800px) {
        .head-pos {
            width: 9%;
        }

        .head-user {
            width: 31%;
        }

        .head-wagered {
            width: 30%;
        }

        .head-prize {
            width: 30%;
            text-align: right;
        }
    }

    @media screen and (max-width: 650px) {
        .content-option {
            top: -14px;
        }

        .content-top {
            margin-bottom: 20px;
            margin-top: 14px;
        }
    }

    @media screen and (max-width: 550px) {
        .content-option {
            justify-content: center;
        }

        .content-head {
            display: none;
        }
    }
</style>
