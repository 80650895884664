<template>
    <div :class="$style['profile-games']" class="octagonal after">
        <div :class="$style['games-content']" class="octagonal after before">
            <div :class="$style['content-controls']">
                <div
                    ref="controlType"
                    :class="[$style['controls-type'], { [$style.active]: typeDropdown === true }]"
                >
                    <button
                        v-on:click="typeDropdown = !typeDropdown"
                        :class="$style['type-button']"
                        class="octagonal before after hoverable"
                    >
                        <div></div>
                        {{ type === null ? 'All' : '' }}
                        <span v-if="type !== null">
                            {{
                                {
                                    battles: 'Case Battles',
                                    duels: 'Dice Duels',
                                    unbox: 'Loot Cases'
                                }[type] || type[0].toUpperCase() + type.slice(1)
                            }}
                        </span>
                        <svg
                            width="10"
                            height="6"
                            viewBox="0 0 10 6"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                                fill="#5B84AE"
                            />
                        </svg>
                    </button>
                    <div :class="$style['type-menu']">
                        <div :class="$style['menu-container']">
                            <div :class="$style['menu-content']" class="octagonal before">
                                <button
                                    class="octagonal after before hoverable"
                                    :class="[
                                        $style['content-item'],
                                        { [$style.active]: type === null }
                                    ]"
                                    v-on:click="profileSetType(null)"
                                >
                                    All
                                </button>
                                <button
                                    v-for="(game, index) in [
                                        'battles',
                                        'roll',
                                        'blackjack',
                                        'upgrader',
                                        'mines',
                                        'crash',
                                        'duels',
                                        'towers',
                                        'unbox'
                                    ]"
                                    v-bind:key="index"
                                    class="octagonal after before hoverable"
                                    :class="[
                                        $style['content-item'],
                                        { [$style.active]: type === game }
                                    ]"
                                    v-on:click="profileSetType(game)"
                                >
                                    {{
                                        {
                                            battles: 'Case Battles',
                                            duels: 'Dice Duels',
                                            unbox: 'Loot Cases'
                                        }[game] || game[0].toUpperCase() + game.slice(1)
                                    }}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div :class="$style['controls-wrapper']">
                    <div
                        ref="controlFilter"
                        :class="[
                            $style['controls-filter'],
                            { [$style.active]: filterDropdown === true }
                        ]"
                    >
                        <button
                            v-on:click="filterDropdown = !filterDropdown"
                            :class="$style['filter-button']"
                            class="octagonal before after hoverable"
                        >
                            Filter by{{ filter === null ? '' : ':' }}
                            <span v-if="filter !== null">{{
                                filter[0].toUpperCase() + filter.slice(1)
                            }}</span>
                            <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                                    fill="#5B84AE"
                                />
                            </svg>
                        </button>
                        <div :class="$style['filter-menu']">
                            <div :class="$style['menu-container']">
                                <div :class="$style['menu-content']" class="octagonal before">
                                    <button
                                        class="octagonal after before hoverable"
                                        :class="[
                                            $style['content-item'],
                                            { [$style.active]: filter === null }
                                        ]"
                                        v-on:click="profileSetFilter(null)"
                                    >
                                        None
                                    </button>
                                    <button
                                        class="octagonal after before hoverable"
                                        :class="[
                                            $style['content-item'],
                                            { [$style.active]: filter === 'gems' }
                                        ]"
                                        v-on:click="profileSetFilter('gems')"
                                    >
                                        Gems
                                    </button>
                                    <button
                                        class="octagonal after before hoverable"
                                        :class="[
                                            $style['content-item'],
                                            { [$style.active]: filter === 'robux' }
                                        ]"
                                        v-on:click="profileSetFilter('robux')"
                                    >
                                        Robux
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div
                        ref="controlSort"
                        :class="[
                            $style['controls-sort'],
                            { [$style.active]: sortDropdown === true }
                        ]"
                    >
                        <button
                            v-on:click="sortDropdown = !sortDropdown"
                            :class="$style['sort-button']"
                            class="octagonal before after hoverable"
                        >
                            <div></div>
                            <p v-if="sort === 'date-descend'">
                                <svg
                                    stroke="currentColor"
                                    fill="currentColor"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M330.17 258.91a79 79 0 0 0-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zM304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zM107.31 36.69a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31z"
                                    ></path>
                                </svg>
                                Date
                            </p>
                            <span v-else>
                                <svg
                                    v-if="sort.split('-')[1] === 'ascend'"
                                    stroke="#E34343"
                                    fill="#E34343"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zm26.15 162.91a79 79 0 0 0-55 54.17c-14.25 51.05 21.21 97.77 68.85 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zm-176-4h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352z"
                                    ></path>
                                </svg>
                                <svg
                                    v-else
                                    stroke="#01DA9C"
                                    fill="#01DA9C"
                                    stroke-width="0"
                                    viewBox="0 0 448 512"
                                    height="1em"
                                    width="1em"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M330.17 258.91a79 79 0 0 0-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zM304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zM107.31 36.69a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31z"
                                    ></path>
                                </svg>
                                {{
                                    sort.split('-')[0][0].toUpperCase() +
                                    sort.split('-')[0].slice(1)
                                }}
                            </span>
                            <svg
                                width="10"
                                height="6"
                                viewBox="0 0 10 6"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M9.51762 1.66411e-06L0.482355 8.43377e-08C0.0547937 9.58044e-09 -0.163021 0.565329 0.143533 0.900993L4.66116 5.84762C4.84671 6.05079 5.15326 6.05079 5.33889 5.84762L9.85652 0.900995C10.163 0.565331 9.94518 1.73887e-06 9.51762 1.66411e-06Z"
                                    fill="#5B84AE"
                                />
                            </svg>
                        </button>
                        <div :class="$style['sort-menu']">
                            <div :class="$style['menu-container']">
                                <div :class="$style['menu-content']" class="octagonal before">
                                    <button
                                        v-for="(item, index) in [
                                            'date-descend',
                                            'date-ascend',
                                            'payout-descend',
                                            'payout-ascend'
                                        ]"
                                        v-bind:key="item"
                                        class="octagonal after before hoverable"
                                        :class="[
                                            $style['content-item'],
                                            { [$style.active]: sort === item }
                                        ]"
                                        v-on:click="profileSetSort(item)"
                                    >
                                        <svg
                                            v-if="item.split('-')[1] === 'ascend'"
                                            stroke="#E34343"
                                            fill="#E34343"
                                            stroke-width="0"
                                            viewBox="0 0 448 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zm26.15 162.91a79 79 0 0 0-55 54.17c-14.25 51.05 21.21 97.77 68.85 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zm-176-4h-48V48a16 16 0 0 0-16-16H80a16 16 0 0 0-16 16v304H16c-14.19 0-21.36 17.24-11.29 27.31l80 96a16 16 0 0 0 22.62 0l80-96C197.35 369.26 190.22 352 176 352z"
                                            ></path>
                                        </svg>
                                        <svg
                                            v-else
                                            stroke="#01DA9C"
                                            fill="#01DA9C"
                                            stroke-width="0"
                                            viewBox="0 0 448 512"
                                            height="1em"
                                            width="1em"
                                            xmlns="http://www.w3.org/2000/svg"
                                        >
                                            <path
                                                d="M330.17 258.91a79 79 0 0 0-55 54.17c-14.27 51.05 21.19 97.77 68.83 102.53a84.07 84.07 0 0 1-20.85 12.91c-7.57 3.4-10.8 12.47-8.18 20.34l9.9 20c2.87 8.63 12.53 13.49 20.9 9.91 58-24.76 86.25-61.61 86.25-132V336c-.02-51.21-48.4-91.34-101.85-77.09zM352 356a20 20 0 1 1 20-20 20 20 0 0 1-20 20zM304 96h16v64h-16a16 16 0 0 0-16 16v32a16 16 0 0 0 16 16h96a16 16 0 0 0 16-16v-32a16 16 0 0 0-16-16h-16V48a16 16 0 0 0-16-16h-48a16 16 0 0 0-14.29 8.83l-16 32A16 16 0 0 0 304 96zM107.31 36.69a16 16 0 0 0-22.62 0l-80 96C-5.35 142.74 1.78 160 16 160h48v304a16 16 0 0 0 16 16h32a16 16 0 0 0 16-16V160h48c14.21 0 21.38-17.24 11.31-27.31z"
                                            ></path>
                                        </svg>
                                        {{
                                            item.split('-')[0][0].toUpperCase() +
                                            item.split('-')[0].slice(1)
                                        }}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr :class="$style['content-hr']" />
            <div :class="$style['content-head']">
                <div :class="$style['head-id']">ID</div>
                <div :class="$style['head-date']">Date</div>
                <div :class="$style['head-game']">Game</div>
                <div :class="$style['head-wager']">Bet Amount</div>
                <div :class="$style['head-payout']">Payout</div>
                <div :class="$style['head-profit']">Profit</div>
                <div :class="$style['head-fairness']">Fairness</div>
            </div>
            <div :class="$style['content-table']">
                <transition name="fade" mode="out-in">
                    <div
                        v-if="userBetsData.bets === null || userBetsData.loading === true"
                        :class="$style['content-loading']"
                        key="loading"
                    >
                        <LoadingAnimation />
                    </div>
                    <div
                        v-else-if="userBetsData.bets.length > 0"
                        :class="$style['content-list']"
                        key="data"
                    >
                        <ProfileGamesElement
                            v-for="bet in userBetsData.bets"
                            v-bind:key="bet._id"
                            v-bind:bet="bet"
                        />
                    </div>
                    <div v-else :class="$style['content-empty']" key="empty">No bets found.</div>
                </transition>
            </div>
            <div :class="$style['content-pagination']">
                <button
                    v-on:click="profileSetPage(userBetsData.page - 1)"
                    :class="$style['button-prev']"
                    class="octagonal before after hoverable"
                    v-bind:disabled="userBetsData.page <= 1"
                >
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M10.0374 4.52545C9.97962 4.51623 9.92117 4.51198 9.86269 4.51272L3.16249 4.51272L3.37961 4.41338C3.59184 4.31456 3.78491 4.18007 3.95018 4.016L5.65676 2.3372C6.02451 1.99185 6.0863 1.43631 5.80319 1.02089C5.47369 0.578226 4.84179 0.482111 4.39177 0.806251C4.35542 0.832453 4.32086 0.861014 4.28839 0.891748L0.324749 4.79088C-0.0698583 5.17863 -0.0702055 5.80763 0.32396 6.19582L0.324749 6.1966L4.28839 10.0957C4.68331 10.4831 5.32271 10.4823 5.71656 10.0938C5.74755 10.0632 5.77649 10.0307 5.80319 9.99639C6.0863 9.58097 6.02451 9.02543 5.65676 8.68008L3.95523 6.99631C3.80707 6.8504 3.63671 6.72805 3.4503 6.63371L3.14734 6.49959L9.80712 6.49959C10.323 6.51844 10.7756 6.16394 10.8725 5.66511C10.9618 5.12353 10.5879 4.61331 10.0374 4.52545Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
                <div :class="$style['pagination-info']">
                    Page
                    <div :class="$style['info-input']" class="octagonal before after hoverable">
                        <input
                            v-model="pagePrompt"
                            v-bind:style="{ width: pagePrompt?.toString().length * 0.75 + 'em' }"
                            v-on:keydown.enter="profileSetPage(parseInt(pagePrompt), true)"
                        />
                    </div>
                    /
                    {{
                        Math.ceil(userBetsData.count / 9) <= 0
                            ? '1'
                            : Math.ceil(userBetsData.count / 9)
                    }}
                </div>
                <button
                    v-on:click="profileSetPage(userBetsData.page + 1)"
                    :class="$style['button-next']"
                    class="octagonal before after hoverable"
                    v-bind:disabled="userBetsData.page >= Math.ceil(userBetsData.count / 9)"
                >
                    <svg
                        width="11"
                        height="11"
                        viewBox="0 0 11 11"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                    >
                        <path
                            d="M0.876938 6.47455C0.93469 6.48377 0.993137 6.48803 1.05161 6.48728L7.75182 6.48728L7.5347 6.58662C7.32247 6.68544 7.12939 6.81993 6.96412 6.984L5.25755 8.6628C4.8898 9.00815 4.828 9.56369 5.11111 9.97911C5.44062 10.4218 6.07251 10.5179 6.52254 10.1937C6.55889 10.1675 6.59345 10.139 6.62592 10.1083L10.5896 6.20912C10.9842 5.82137 10.9845 5.19237 10.5903 4.80418L10.5896 4.8034L6.62592 0.904271C6.231 0.516862 5.59159 0.517731 5.19774 0.906227C5.16675 0.936806 5.13781 0.969341 5.11111 1.00361C4.828 1.41903 4.88979 1.97457 5.25755 2.31992L6.95907 4.00369C7.10724 4.1496 7.27759 4.27195 7.46401 4.36629L7.76697 4.50041L1.10719 4.50041C0.591302 4.48156 0.13869 4.83607 0.0417743 5.33489C-0.0475039 5.87647 0.3264 6.38669 0.876938 6.47455Z"
                            fill="#406992"
                        />
                    </svg>
                </button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters, mapActions } from 'vuex';
    import LoadingAnimation from '@/components/LoadingAnimation';
    import IconLeftGradient from '@/components/icons/IconLeftGradient';
    import IconRightGradient from '@/components/icons/IconRightGradient';
    import ProfileGamesElement from '@/components/profile/ProfileGamesElement';

    export default {
        name: 'ProfileGames',
        components: {
            LoadingAnimation,
            IconLeftGradient,
            IconRightGradient,
            ProfileGamesElement
        },
        data() {
            return {
                filterDropdown: false,
                filter: null,
                typeDropdown: false,
                type: null,
                sortDropdown: false,
                sort: 'date-descend',
                pagePrompt: 1
            };
        },
        methods: {
            ...mapActions(['userSetBetsDataPage', 'userGetBetsSocket']),
            profileSetFilter(filter) {
                this.filterDropdown = false;
                if (this.filter === filter) return;
                this.filter = filter;
                this.userSetBetsDataPage(1);
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userBetsData.page
                };
                this.userGetBetsSocket(data);
            },
            profileSetType(type) {
                this.typeDropdown = false;
                if (this.type === type) return;
                this.type = type;
                this.userSetBetsDataPage(1);
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userBetsData.page
                };
                this.userGetBetsSocket(data);
            },
            profileSetSort(sort) {
                this.sortDropdown = false;
                if (this.sort === sort) return;
                this.sort = sort;
                this.userSetBetsDataPage(1);
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userBetsData.page
                };
                this.userGetBetsSocket(data);
            },
            profileSetPage(page, kickback) {
                if (this.userBetsData.page === page) {
                    return;
                }
                if (isNaN(page) || page < 1 || page > Math.ceil(this.userBetsData.count / 9)) {
                    if (kickback === true) this.pagePrompt = this.userBetsData.page;
                    return;
                }

                this.pagePrompt = page;
                this.userSetBetsDataPage(page);

                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userBetsData.page
                };
                this.userGetBetsSocket(data);
            }
        },
        computed: {
            ...mapGetters(['userBetsData'])
        },
        created() {
            if (this.userBetsData.loading === false) {
                const data = {
                    type: this.type,
                    sort: this.sort,
                    filter: this.filter,
                    page: this.userBetsData.page
                };
                this.pagePrompt = this.userBetsData.page;
                this.userGetBetsSocket(data);
            }

            document.addEventListener('click', (event) => {
                const controlFilter = this.$refs.controlFilter;
                const controlType = this.$refs.controlType;
                const controlSort = this.$refs.controlSort;

                if (
                    controlFilter &&
                    !controlFilter.contains(event.target) &&
                    this.filterDropdown == true
                ) {
                    this.filterDropdown = false;
                }

                if (
                    controlType &&
                    !controlType.contains(event.target) &&
                    this.typeDropdown == true
                ) {
                    this.typeDropdown = false;
                }

                if (
                    controlSort &&
                    !controlSort.contains(event.target) &&
                    this.sortDropdown == true
                ) {
                    this.sortDropdown = false;
                }
            });
        }
    };
</script>

<style module>
    .profile-games {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 4px;
        width: 100%;
        margin-bottom: 30px;
    }

    .profile-games::after {
        clip-path: var(--octagonal-main) !important;
        background: #0d243a;
        font-size: 14px;
    }

    .profile-games .games-content::before {
        background: #162a3d;
        font-size: 12px;
    }

    .profile-games .games-content::after {
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main) !important;
    }

    .profile-games .games-content {
        align-items: center;
        padding: 14px 20px;
        display: flex;
        flex-direction: column;
        gap: 14px;
        width: 100%;
        justify-content: space-between;
        z-index: 1;
    }

    .profile-games .content-controls {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
        width: 100%;
    }

    .profile-games .controls-wrapper {
        display: flex;
        align-items: center;
        gap: 12px;
        flex-wrap: wrap;
    }

    .profile-games .controls-filter,
    .profile-games .controls-sort,
    .profile-games .controls-type {
        position: relative;
    }

    .profile-games .controls-type {
        margin-right: auto;
    }

    .profile-games .controls-filter .filter-button,
    .profile-games .controls-sort .sort-button,
    .profile-games .controls-type .type-button {
        display: flex;
        gap: 7px;
        align-items: center;
        filter: drop-shadow(0px 2px 0px #00000059);
        color: #5b85ae;
        font-size: 15px;
        font-weight: 600;
        justify-content: center;
        height: 36px;
        padding: 0 20px;
    }

    .profile-games .controls-filter .filter-button,
    .profile-games .controls-sort .sort-button,
    .profile-games .controls-type .type-button {
        justify-content: space-between;
        min-width: 90px;
        padding: 0 12px;
        gap: 10px;
    }

    .profile-games .controls-filter .filter-button span,
    .profile-games .controls-sort .sort-button span,
    .profile-games .controls-type .type-button span {
        margin-left: -2px;
        color: white;
    }

    .profile-games .controls-sort .sort-button span svg,
    .profile-games .controls-sort .sort-button p svg {
        margin-bottom: -3px;
        margin-right: 1px;
    }

    .profile-games .controls-filter .filter-button:before,
    .profile-games .controls-filter .filter-button:after,
    .profile-games .controls-sort .sort-button:before,
    .profile-games .controls-sort .sort-button:after,
    .profile-games .controls-type .type-button:before,
    .profile-games .controls-type .type-button:after {
        background: #16334f;
        font-size: 3px;
    }

    .profile-games .controls-filter .filter-menu,
    .profile-games .controls-sort .sort-menu,
    .profile-games .controls-type .type-menu {
        position: absolute;
        left: 50%;
        top: calc(100% + 7px);
        min-width: calc(100% + 14px);
        transition: max-height 0.3s ease;
        transform: translateX(-50%);
        max-height: 0vh;
        z-index: 2;
        overflow: auto;
    }

    .profile-games .controls-type .type-menu {
        left: 0;
        transform: unset;
    }

    .profile-games .controls-filter.active .filter-menu,
    .profile-games .controls-sort.active .sort-menu,
    .profile-games .controls-type.active .type-menu {
        font-size: 7px;
        clip-path: var(--octagonal-main);
        max-height: 55vh;
    }

    .profile-games .controls-filter .menu-container,
    .profile-games .controls-sort .menu-container,
    .profile-games .controls-type .menu-container {
        width: 100%;
        height: 100%;
    }

    .profile-games .controls-filter .menu-content,
    .profile-games .controls-sort .menu-content,
    .profile-games .controls-type .menu-content {
        display: flex;
        flex-direction: column;
        background: #0e293e;
        padding: 7px 7px 8px 7px;
        gap: 7px;
        font-size: 7px;
        clip-path: var(--octagonal-main);
    }

    .profile-games .controls-filter .menu-content::before,
    .profile-games .controls-sort .menu-content::before,
    .profile-games .controls-type .menu-content::before {
        background: #1f3d56;
    }

    .profile-games .controls-filter .content-item,
    .profile-games .controls-sort .content-item,
    .profile-games .controls-type .content-item {
        filter: drop-shadow(0px 2px 0px #0f2133);
        display: flex;
        align-items: center;
        justify-content: center;
        height: 34px;
        white-space: nowrap;
        font-size: 14px;
        font-weight: 600;
        padding: 0px 10px;
        color: #6a8ba6;
        gap: 5px;
    }

    .profile-games .controls-filter .content-item.active,
    .profile-games .controls-sort .content-item.active,
    .profile-games .controls-type .content-item.active {
        filter: drop-shadow(0px 2px 0px #0f2133) brightness(0.8);
    }

    .profile-games .controls-filter .content-item::after,
    .profile-games .controls-sort .content-item::after,
    .profile-games .controls-type .content-item::after {
        font-size: 3px;
        background: #12283e;
    }

    .profile-games .controls-filter .content-item::before,
    .profile-games .controls-sort .content-item::before,
    .profile-games .controls-type .content-item::before {
        font-size: 3px;
        background: #203e59;
    }

    .profile-games .content-hr {
        border-color: #2b70a42e;
        width: 100%;
    }

    .profile-games .content-head {
        width: 100%;
        height: 42px;
        position: relative;
        display: flex;
        align-items: center;
        padding: 0 25px;
        background: linear-gradient(45deg, #0b1f33, #0a1c2e);
        border-radius: 8px;
        border: 1px solid #103351;
    }

    .profile-games .head-id,
    .profile-games .head-date,
    .profile-games .head-game,
    .profile-games .head-wager,
    .profile-games .head-payout,
    .profile-games .head-profit,
    .profile-games .head-fairness {
        font-weight: 600;
        font-size: 15px;
        color: #5b85ae;
    }

    .profile-games .head-id {
        width: 12%;
    }

    .profile-games .head-date {
        width: 22%;
    }

    .profile-games .head-game {
        width: 16%;
    }

    .profile-games .head-wager {
        width: 16%;
    }

    .profile-games .head-payout {
        width: 15%;
    }

    .profile-games .head-profit {
        width: 12%;
    }

    .profile-games .head-fairness {
        text-align: right;
        width: 7%;
    }

    .profile-games .content-table {
        width: 100%;
        padding-bottom: 18px;
        border-bottom: 1px solid rgba(24, 72, 109, 0.5);
    }

    .profile-games .content-loading {
        width: 100%;
        height: 330px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .profile-games .content-loading.fade-leave-active {
        transition: opacity 0.5s;
    }

    .profile-games .content-loading.fade-leave-to {
        opacity: 0;
    }

    .profile-games .content-list {
        width: 100%;
    }

    .profile-games .content-empty {
        width: 100%;
        height: 192px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 600;
        color: #5e768e;
    }

    .profile-games .content-list.fade-enter-active,
    .profile-games .content-empty.fade-enter-active {
        transition: opacity 0.5s;
    }

    .profile-games .content-list.fade-enter-from,
    .profile-games .content-empty.fade-enter-from {
        opacity: 0;
    }

    .profile-games .content-pagination {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .profile-games .content-pagination button {
        width: 38px;
        height: 38px;
        font-size: 3px;
        color: #406992;
    }

    .profile-games .content-pagination button::before {
        background: #142f4a;
    }

    .profile-games .content-pagination button::after {
        background: linear-gradient(0deg, #102b43, #102940);
    }

    .profile-games .pagination-info {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: #5e768e;
    }

    .profile-games .pagination-info .info-input {
        margin-right: -4px;
        font-size: 16px;
        z-index: 1;
    }

    .profile-games .pagination-info .info-input::before,
    .profile-games .pagination-info .info-input::after {
        background: #00111f;
        font-size: 2px;
    }

    .profile-games .pagination-info .info-input input {
        appearance: none;
        background: none;
        padding: 2px 0px 2.5px 0px;
        width: 1.5em;
        min-width: 2em;
        text-align: center;
        max-width: 7.5em;
        color: white;
    }

    @media only screen and (max-width: 1000px) {
        .profile-games .games-head {
            padding-bottom: 0;
        }

        .profile-games .content-head {
            display: none;
        }

        .profile-games .content-table {
            margin-top: 0;
        }
    }

    @media screen and (max-width: 600px) {
        .profile-games .games-content {
            padding: 14px;
        }
    }
</style>
