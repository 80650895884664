<template>
    <div :class="[$style['profile-stats'], $style[userGetLevelColor]]">
        <div :class="$style['stats-content']" class="octagonal before">
            <div :class="$style['content-user']">
                <AvatarImage
                    v-bind:image="authUser.user.avatar"
                    v-bind:xp="authUser.user.xp"
                    v-bind:rank="authUser.user.rank"
                />
                <div :class="$style['user-deets']">
                    <p :class="$style['deets-username']">
                        {{ authUser.user.username }}
                        <BoxLevel
                            v-bind:level="userGetLevel"
                            v-bind:rank="authUser.user.rank"
                            v-bind:color="userGetLevelColor"
                        />
                    </p>
                    <div :class="$style['deets-level']">
                        <div :class="$style['level-progress']">
                            <div
                                :class="$style['progress-bar']"
                                v-bind:style="{
                                    width:
                                        Math.max(
                                            userGetLevel < 100
                                                ? ((authUser.user.xp - userGetLevelXP) /
                                                      (userGetNextLevelXP - userGetLevelXP)) *
                                                      100
                                                : 100,
                                            0
                                        ) + '%'
                                }"
                            ></div>
                        </div>
                    </div>
                    <p :class="$style['deets-xp']">
                        <span>{{ userGetLevelStats.current.toLocaleString() }} XP</span>
                        / {{ userGetLevelStats.next }} XP
                    </p>
                </div>
            </div>
            <div :class="$style['content-list']">
                <div
                    v-for="(item, index) in getContentList"
                    :class="$style['list-item']"
                    v-bind:key="index"
                >
                    <div
                        class="octagonal before"
                        :class="[
                            $style['item-content'],
                            {
                                [$style.negative]:
                                    index === getContentList.length - 1 && item.text.startsWith('-')
                            }
                        ]"
                    >
                        <h4>
                            <span :class="{ 'fade-green': index === getContentList.length - 1 }">{{
                                item.title
                            }}</span>
                        </h4>
                        <hr />
                        <p>
                            <span v-if="item.type !== 'text'" :class="$style.coins">
                                <img
                                    width="10"
                                    src="@/assets/img/icons/cryptoCoin.svg"
                                    alt="icon"
                                />
                                /
                                <img width="10" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                            </span>
                            {{ item.text }}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapGetters } from 'vuex';
    import AvatarImage from '@/components/AvatarImage';
    import BoxLevel from '@/components/BoxLevel';

    export default {
        name: 'ProfileStats',
        components: {
            AvatarImage,
            BoxLevel
        },
        methods: {
            profileFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            }
        },
        computed: {
            ...mapGetters(['authUser']),
            getContentList() {
                return [
                    {
                        title: 'MEMBER SINCE',
                        text: new Date(this.authUser.user.createdAt).toLocaleString('en-US', {
                            hour12: true,
                            year: 'numeric',
                            month: 'numeric',
                            day: 'numeric'
                        }),
                        type: 'text'
                    },
                    {
                        title: 'DEPOSITED',
                        text: this.profileFormatValue(this.authUser.user.stats.deposit)
                    },
                    {
                        title: 'WITHDRAWN',
                        text: this.profileFormatValue(this.authUser.user.stats.withdraw)
                    },
                    {
                        title: 'TOTAL WAGERED',
                        text: this.profileFormatValue(this.authUser.user.stats.bet)
                    },
                    {
                        title: 'NET PROFIT',
                        text: this.profileFormatValue(
                            this.authUser.user.stats.withdraw - this.authUser.user.stats.deposit
                        )
                    }
                ];
            },
            userGetLevel() {
                let level = Math.floor(Math.pow(this.authUser.user.xp / 1000 / 100, 1 / 3));
                return Math.min(level, 100);
            },
            userGetLevelXP() {
                return Math.floor(1000 * 100 * Math.pow(this.userGetLevel, 3)) + 1;
            },
            userGetNextLevelXP() {
                return Math.floor(1000 * 100 * Math.pow(this.userGetLevel + 1, 3)) + 1;
            },
            userGetLevelStats() {
                let current = Math.max(
                    0,
                    Math.round((this.authUser.user.xp - this.userGetLevelXP) / 1000)
                );
                let next = Math.round((this.userGetNextLevelXP - this.userGetLevelXP) / 1000);

                switch (true) {
                    case current.toString().length > 6:
                        current /= 1000000;
                        current += 'm';
                        break;
                }
                switch (true) {
                    case next.toString().length > 6:
                        next /= 1000000;
                        next += 'm';
                        break;
                    case next.toString().length > 3:
                        next /= 1000;
                        next += 'k';
                        break;
                    default:
                        break;
                }
                return { current, next };
            },
            userGetLevelColor() {
                let color = '';

                if (this.userGetLevel >= 2 && this.userGetLevel < 26) {
                    color = 'blue';
                } else if (this.userGetLevel >= 26 && this.userGetLevel < 51) {
                    color = 'sky';
                } else if (this.userGetLevel >= 51 && this.userGetLevel < 76) {
                    color = 'yellow';
                } else if (this.userGetLevel >= 76 && this.userGetLevel <= 90) {
                    color = 'purple';
                } else if (this.userGetLevel > 90) {
                    color = 'gold';
                }

                return color;
            }
        }
    };
</script>

<style module>
    .profile-stats {
        background: #0d243a;
        font-size: 14px;
        clip-path: var(--octagonal-main);
        padding: 4px;
        width: 100%;
        margin-bottom: 23px;
    }

    .profile-stats .stats-content::before {
        background: #162a3d;
    }

    .profile-stats .stats-content {
        align-items: center;
        padding: 14px 20px;
        display: flex;
        flex-wrap: wrap;
        gap: 20px;
        min-height: 66px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #0b2034, #091d30, #091b2d, #081b2d);
        font-size: 12px;
        clip-path: var(--octagonal-main);
    }

    .profile-stats .content-user {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    .profile-stats .content-user > img {
        width: 77px;
    }

    .profile-stats .user-deets {
        display: flex;
        flex: 1;
        flex-direction: column;
        min-width: 144px;
        gap: 9px;
    }

    .profile-stats .deets-username {
        color: white;
        font-size: 18px;
        align-items: center;
        font-weight: 500;
        gap: 7px;
        display: flex;
    }

    .profile-stats .deets-level {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0px 6px;
        background: #0d2e49;
        margin-bottom: -3px;
        width: 100%;
        height: 14px;
        border-radius: 9px;
    }

    .profile-stats .level-progress {
        height: 5px;
        width: 100%;
    }

    .profile-stats .progress-bar {
        border-radius: 5px;
        height: 100%;
    }

    .profile-stats .progress-bar {
        background: linear-gradient(90deg, #9e9e9e 0%, #9e9e9e 100%);
    }

    .profile-stats.blue .progress-bar {
        background: linear-gradient(90deg, #a4d0ff 0%, #559ee4 100%);
    }

    .profile-stats.sky .progress-bar {
        background: linear-gradient(90deg, #e5ffa4 0%, #01bcf9 100%);
    }

    .profile-stats.yellow .progress-bar {
        background: linear-gradient(90deg, #ffe5a4 0%, #f9c201 100%);
    }

    .profile-stats.purple .progress-bar {
        background: linear-gradient(90deg, #ffa4a4 0%, #6562ff 100%);
    }

    .profile-stats.gold .progress-bar {
        background: linear-gradient(90deg, #ffe5a4 0%, #ffbe18 100%);
    }

    .profile-stats .deets-xp {
        font-size: 12px;
        color: #5b84ae;
        font-weight: 500;
    }

    .profile-stats .deets-xp span {
        color: white;
    }

    .profile-stats .content-list {
        display: flex;
        flex-wrap: wrap;
        align-items: stretch;
        gap: 4px;
        flex: 1;
        max-width: 966px;
    }

    .profile-stats .list-item {
        background: #0d243a;
        font-size: 10px;
        clip-path: var(--octagonal-main);
        padding: 4px;
        flex: 1;
    }

    .profile-stats .item-content::before {
        background: #162a3d;
    }

    .profile-stats .list-item:last-of-type .item-content::before {
        background: #01d79a;
    }

    .profile-stats .list-item:last-of-type .item-content.negative::before {
        background: #c94f4f;
    }

    .profile-stats .item-content {
        align-items: center;
        padding: 14px;
        display: flex;
        flex-direction: column;
        height: 89px;
        width: 100%;
        justify-content: space-between;
        background: linear-gradient(90deg, #091d30, #091b2d, #081b2d);
        font-size: 8px;
        clip-path: var(--octagonal-main);
    }

    .profile-stats .item-content h4 {
        color: #5b84ae;
        font-size: 14px;
    }

    .profile-stats .item-content hr {
        border-color: #1d466b;
        width: 104px;
        mask-image: linear-gradient(90deg, transparent, #1d466b, transparent);
    }

    .profile-stats .item-content p {
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        font-weight: 500;
        color: white;
    }

    .profile-stats .item-content p .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
    }

    .profile-stats .item-content p .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 2px;
    }

    .profile-stats .item-content p .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -2px;
    }

    @media screen and (max-width: 770px) {
        .profile-stats .content-user {
            width: 100%;
        }

        .profile-stats .stats-content {
            flex-direction: column;
        }
    }

    @media screen and (max-width: 600px) {
        .profile-stats .stats-content {
            padding: 14px;
        }
    }
</style>
