<template>
    <div class="clicker">
        <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 256 256"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M220.49,207.8,207.8,220.49a12,12,0,0,1-17,0l-56.57-56.57L115,214.08l-.13.33A15.84,15.84,0,0,1,100.26,224l-.78,0a15.82,15.82,0,0,1-14.41-11L32.8,52.92A15.95,15.95,0,0,1,52.92,32.8L213,85.07a16,16,0,0,1,1.41,29.8l-.33.13-50.16,19.27,56.57,56.56A12,12,0,0,1,220.49,207.8Z"
            ></path>
        </svg>
        <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 256 256"
            height="2em"
            width="2em"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M213.66,201,201,213.66a8,8,0,0,1-11.31,0l-51.31-51.31a8,8,0,0,0-13,2.46l-17.82,46.41a8,8,0,0,1-14.85-.71L40.41,50.44a8,8,0,0,1,10-10L210.51,92.68a8,8,0,0,1,.71,14.85l-46.41,17.82a8,8,0,0,0-2.46,13l51.31,51.31A8,8,0,0,1,213.66,201Z"
                opacity="0.2"
            ></path>
            <path
                d="M88,24V16a8,8,0,0,1,16,0v8a8,8,0,0,1-16,0ZM16,104h8a8,8,0,0,0,0-16H16a8,8,0,0,0,0,16ZM124.42,39.16a8,8,0,0,0,10.74-3.58l8-16a8,8,0,0,0-14.31-7.16l-8,16A8,8,0,0,0,124.42,39.16Zm-96,81.69-16,8a8,8,0,0,0,7.16,14.31l16-8a8,8,0,1,0-7.16-14.31ZM219.31,184a16,16,0,0,1,0,22.63l-12.68,12.68a16,16,0,0,1-22.63,0L132.7,168,115,214.09c0,.1-.08.21-.13.32a15.83,15.83,0,0,1-14.6,9.59l-.79,0a15.83,15.83,0,0,1-14.41-11L32.8,52.92A16,16,0,0,1,52.92,32.8L213,85.07a16,16,0,0,1,1.41,29.8l-.32.13L168,132.69ZM208,195.31,156.69,144h0a16,16,0,0,1,4.93-26l.32-.14,45.95-17.64L48,48l52.2,159.86,17.65-46c0-.11.08-.22.13-.33a16,16,0,0,1,11.69-9.34,16.72,16.72,0,0,1,3-.28,16,16,0,0,1,11.3,4.69L195.31,208Z"
            ></path>
        </svg>
    </div>
</template>

<script>
    export default {
        name: 'Clicker'
    };
</script>

<style scoped>
    .clicker {
        position: absolute;
        width: 2em;
        height: 2em;
        top: 50%;
        left: 80%;
        animation: clicker 5s ease infinite;
        z-index: 5;
    }

    .clicker svg:first-child {
        animation: clicker-svg 5s infinite;
    }

    .clicker svg:last-child {
        font-size: 0em;
        animation: clicker-svg_01 5s infinite;
    }

    @keyframes clicker {
        0% {
            transform: translate(10vw, 10vh);
            opacity: 0;
        }
        30% {
            transform: translate(0vw, 0vh);
            opacity: 1;
        }
        50% {
            transform: translate(0vw, 0vh);
            opacity: 1;
        }
        65% {
            transform: translate(0vw, 0vh);
            opacity: 0;
        }
        100% {
            transform: translate(0vw, 0vh);
            opacity: 0;
        }
    }

    @keyframes clicker-svg {
        0% {
            font-size: 1em;
        }
        33% {
            font-size: 1em;
        }
        33.01% {
            font-size: 0em;
        }
        40% {
            font-size: 0em;
        }
        40.01% {
            font-size: 1em;
        }
        100% {
            font-size: 1em;
        }
    }

    @keyframes clicker-svg_01 {
        0% {
            font-size: 0em;
        }
        33% {
            font-size: 0em;
        }
        33.01% {
            font-size: 1em;
        }
        40% {
            font-size: 1em;
        }
        40.01% {
            font-size: 0em;
        }
        100% {
            font-size: 0em;
        }
    }
</style>
