<template>
    <div :class="$style['leaderboard-banner']">
        <img src="@/assets/img/leaderboard-banner-elem.png" />
        <img src="@/assets/img/leaderboard-banner-elem_01.png" />
        <div :class="$style['banner-content']"></div>
    </div>
</template>

<script>
    export default {
        name: 'LeaderboardBanner'
    };
</script>

<style module>
    .leaderboard-banner {
        position: relative;
        height: 210px;
        width: 100%;
    }

    .leaderboard-banner .banner-content {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: 8px;
        overflow: hidden;
    }

    .leaderboard-banner .banner-content::before {
        animation: background-move 6s ease alternate infinite;
        background-image: url('~@/assets/img/leaderboard-banner-bg.png');
        opacity: 0.8;
        background-position: center;
        background-size: cover;
        position: absolute;
        left: 0;
        top: 0;
        width: calc(100% + 50px);
        height: 100%;
        content: '';
    }

    .leaderboard-banner > img {
        position: absolute;
        z-index: 1;
    }

    .leaderboard-banner img:first-of-type {
        left: -7%;
        top: -5%;
        width: 184px;
    }

    .leaderboard-banner img:last-of-type {
        right: -6%;
        top: -8%;
        width: 227px;
    }

    @keyframes background-move {
        0% {
            left: 0;
        }
        100% {
            left: -50px;
        }
    }
</style>
