<template>
    <div :class="$style['profile-affiliates-element']">
        <button :class="$style['element-user']" v-on:click="affiliatesUserButton(referred.user)">
            <div :class="$style['user-title']">Affiliated User</div>
            <div :class="$style['user-content']">
                <AvatarImage
                    v-bind:image="referred.user !== null ? referred.user.avatar : null"
                    v-bind:rank="referred.rank"
                    v-bind:level="referred.level"
                />
                <span>{{ referred.user.username }}</span>
            </div>
        </button>

        <div :class="$style['element-date']">
            <div :class="$style['date-title']">Date Referred</div>
            <div :class="$style['date-content']">
                {{
                    new Date(referred.affiliates.referredAt).toLocaleString('en-US', {
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric',
                        hour: '2-digit',
                        minute: '2-digit'
                    })
                }}
            </div>
        </div>

        <div :class="$style['element-wagered']">
            <div :class="$style['wagered-title']">Total Wagered</div>
            <div :class="$style['wagered-content']">
                <span :class="$style.coins">
                    <img width="10" src="@/assets/img/icons/cryptoCoin.svg" alt="icon" />
                    /
                    <img width="10" src="@/assets/img/icons/robuxCoin.svg" alt="icon" />
                </span>
                <span :class="$style['content-value']">
                    {{
                        affiliatesFormatValue((referred.affiliates.generated || 0) / 0.005).split(
                            '.'
                        )[0]
                    }}<span
                        >.{{
                            affiliatesFormatValue(
                                (referred.affiliates.generated || 0) / 0.005
                            ).split('.')[1]
                        }}</span
                    >
                </span>
            </div>
        </div>

        <div :class="$style['element-commission']">
            <div :class="$style['commission-title']">Commission</div>
            <div :class="$style['commission-content']">
                <img src="@/assets/img/icons/cryptoCoin.svg" width="14" alt="icon" />
                <span :class="$style['content-value']" class="fade-green">
                    {{ affiliatesFormatValue(referred.affiliates.generated || 0).split('.')[0]
                    }}<span class="fade-green"
                        >.{{
                            affiliatesFormatValue(referred.affiliates.generated || 0).split('.')[1]
                        }}</span
                    >
                </span>
            </div>
        </div>

        <div :class="$style['element-online']">
            <div :class="$style['online-title']">Last Seen</div>
            <div :class="$style['online-content']">
                <span>{{
                    new Date(referred.user.updatedAt).toLocaleString('en-US', {
                        hour12: true,
                        year: 'numeric',
                        month: 'numeric',
                        day: 'numeric'
                    })
                }}</span>
            </div>
        </div>
    </div>
</template>

<script>
    import AvatarImage from '@/components/AvatarImage';
    import { mapActions } from 'vuex';

    export default {
        name: 'ProfileAffiliatesElement',
        components: {
            AvatarImage
        },
        props: ['referred'],
        methods: {
            ...mapActions(['generalSetUserInfoData', 'modalsSetShow']),
            affiliatesUserButton(user) {
                if (user === null || user.rank === 'system') {
                    return;
                }

                this.generalSetUserInfoData(user);
                this.modalsSetShow('ChatUser');
            },
            affiliatesFormatValue(value) {
                return parseFloat(Math.floor(value / 10) / 100)
                    .toFixed(2)
                    .toString()
                    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
            },
            affiliatesGetRank(user) {
                let rank = user.rakeback;

                if (user.rank !== 'user') {
                    rank = user.rank;
                }

                return rank;
            },
            affiliatesGetLevelColor(user) {
                let color = '';

                if (user.level >= 2 && user.level < 26) {
                    color = 'blue';
                } else if (user.level >= 26 && user.level < 51) {
                    color = 'sky';
                } else if (user.level >= 51 && user.level < 76) {
                    color = 'yellow';
                } else if (user.level >= 76 && user.level <= 90) {
                    color = 'purple';
                } else if (user.level > 90) {
                    color = 'gold';
                }

                return color;
            }
        }
    };
</script>

<style module>
    .profile-affiliates-element {
        width: 100%;
        height: 42px;
        display: flex;
        align-items: center;
        padding: 0 25px;
        border-radius: 5px;
        background: linear-gradient(270deg, #0c2033, #08243b);
    }

    .profile-affiliates-element:nth-child(even) {
        background: unset;
    }

    .profile-affiliates-element .element-user,
    .profile-affiliates-element .element-date,
    .profile-affiliates-element .element-wagered,
    .profile-affiliates-element .element-commission,
    .profile-affiliates-element .element-online {
        display: flex;
        flex-direction: column;
    }

    .profile-affiliates-element .element-user {
        width: 18%;
    }

    .profile-affiliates-element .element-date {
        width: 26%;
    }

    .profile-affiliates-element .element-wagered {
        width: 26%;
    }

    .profile-affiliates-element .element-commission {
        width: 18%;
    }

    .profile-affiliates-element .element-online {
        width: 12%;
    }

    .profile-affiliates-element .user-title,
    .profile-affiliates-element .date-title,
    .profile-affiliates-element .wagered-title,
    .profile-affiliates-element .commission-title,
    .profile-affiliates-element .online-title {
        display: none;
        font-size: 14px;
        font-weight: 600;
        color: #8bacc8;
    }

    .profile-affiliates-element .user-content,
    .profile-affiliates-element .date-content,
    .profile-affiliates-element .wagered-content,
    .profile-affiliates-element .commission-content,
    .profile-affiliates-element .online-content {
        position: relative;
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 15px;
        line-height: 1.2;
        font-weight: 500;
        color: #5b84ae;
        padding-right: 5px;
        width: 100%;
    }

    .profile-affiliates-element .online-content {
        padding-right: unset;
    }

    .profile-affiliates-element .user-content,
    .profile-affiliates-element .wagered-content,
    .profile-affiliates-element .commission-content {
        font-weight: 600;
    }

    .profile-affiliates-element .user-content {
        text-align: start;
        color: white;
        gap: 7px;
    }

    .profile-affiliates-element .wagered-content {
        color: white;
    }

    .profile-affiliates-element .user-content img {
        min-width: 31px;
        width: 31px;
        height: 31px;
        border-width: 2px;
    }

    .profile-affiliates-element .wagered-content img,
    .profile-affiliates-element .commission-content img {
        width: 18px;
        height: 18px;
    }

    .profile-affiliates-element .wagered-content .coins {
        color: #5b84ae;
        position: relative;
        margin: 0 10px;
        font-size: 15px;
        font-weight: 500;
    }

    .profile-affiliates-element .wagered-content .coins img:first-of-type {
        position: absolute;
        left: -9px;
        top: 0px;
        width: 10px;
    }

    .profile-affiliates-element .wagered-content .coins img:last-of-type {
        position: absolute;
        right: -10px;
        bottom: -4px;
        width: 10px;
    }

    .profile-affiliates-element .content-value {
        word-break: break-all;
        font-weight: 600;
        width: 100%;
    }

    .profile-affiliates-element .content-value span {
        font-size: 10px;
        font-weight: 600;
        opacity: 0.6;
    }

    .profile-affiliates-element .online-content span,
    .profile-affiliates-element .user-content span {
        flex: 1;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .profile-affiliates-element .online-content span {
        margin-left: auto;
        flex: unset;
    }

    @media only screen and (max-width: 775px) {
        .profile-affiliates-element {
            height: auto;
            flex-direction: row;
            flex-wrap: wrap;
            align-items: flex-start;
            padding: 10px 20px;
        }

        .profile-affiliates-element .element-date {
            width: 66.66%;
        }

        .profile-affiliates-element .element-user,
        .profile-affiliates-element .element-wagered,
        .profile-affiliates-element .element-commission,
        .profile-affiliates-element .element-online {
            width: 33.33%;
        }

        .profile-affiliates-element .element-wagered,
        .profile-affiliates-element .element-commission,
        .profile-affiliates-element .element-online {
            margin-top: 10px;
        }

        .profile-affiliates-element .user-title,
        .profile-affiliates-element .date-title,
        .profile-affiliates-element .wagered-title,
        .profile-affiliates-element .commission-title,
        .profile-affiliates-element .online-title {
            display: block;
        }

        .profile-affiliates-element .online-content span {
            margin-left: unset;
        }
    }
</style>
